import React, { useEffect } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import Checkbox from "../../components/form/checkbox/Checkbox"
import Input from "../../components/form/input/Input"
import Select from "../../components/form/select/Select"
import { IconAdd, IconAnexar, IconConfigs } from "../../components/layout/Svgs"
import api from "../../services/api"
import { Container } from "../../styles/layout"
import { IPerfil } from "../../types/Perfil"
import { IUser } from "../../types/User"
import Message from "../../services/message"
import Paginate from "../../components/paginate/Paginate"
import { Divider } from './styles'
import { useAuth } from "../../hooks/Auth"

const Listar: React.FC = (props) => {
    const { user } = useAuth();
    const [users, setUsers] = useState<IUser[]>([])
    const [perfis, setPerfis] = useState<IPerfil[]>([])
    const [filter, setFilter] = useState<any>({
        Search: "",
        PerfilId: "",
        Ativo: "",
        Page: 1
    })

    const [paginate, setPaginate] = useState<any>({
        total: 0,
        lastPage: 1,
        perPage: 10,
        currentPage: 1,
    })

    const filterFunc = user.funcionalidades.filter((obj) => obj.Tela === "frmUsuario").map((row) => row.Nome)

    useEffect(() => {

        api.get("/usuarios").then((response) => {
            setUsers([...response.data.result])
            setPaginate({...response.data.pagination})
        })

        api.get("/perfis").then((response) => {
            setPerfis([...response.data.result])
        })

    }, [props])

    const onChangeInput = (key:string, value:string|number) => {
        filter[key] = value
        setFilter({...filter})
    }
    
    const handleFilter = async (e:React.FormEvent) => {
        e.preventDefault()
        onChangeInput("Page", 1)
        await getUsers()
    }

    const onClickReset = async () => {
        await getUsers()
    }

    const onClickPage = async (page:number) => {
        onChangeInput("Page", page)
        await getUsers()
    }

    const getUsers = async () => {
        let response = await api.get(`/usuarios?Search=${filter.Search}&PerfilId=${filter.PerfilId}&Ativo=${filter.Ativo}&Page=${filter.Page}`)
        setUsers([...response.data.result])
        setPaginate({...response.data.pagination})
    }

    const onClickReenviarLink = async (user:IUser) => {
        try {
            if( await Message.confirm({text:"Confirmar reenvio do link de cadastro de senha?"}) ){

                let response = await api.post("/auth/recover-password/step1", {
                    Email: user.Email
                })
                if( response.data.error === true ) throw response.data.message
                // Message.success(String(response.data.message))

            }
        } catch(message) {
            Message.error(String(message))
        }
    }

    const onClickAlterarStatus = async (user:IUser) => {
        try {
            let response = await api.put(`/usuarios/${user.Id}`, {
                Nome: user.Nome,
                Email: user.Email,
                PerfilId: user.PerfilId,
                Ativo: !user.Ativo,
            })
            if( response.data.error === true ) throw response.data.message
            window.location.reload()
        } catch(message) {
            Message.error(String(message))
        }
    }

    return (
        <Container>
            <h1>Usuários</h1>

            <form className="filter" onSubmit={handleFilter}>
                <Input theme="alt" placeholder="Pesquise aqui..." label="E-mail ou Nome do Usuário" name="Search" value={filter.Search} onChange={onChangeInput} onClickReset={onClickReset} />

                <Select theme="alt" label="Tipo de Usuário" name="PerfilId" value={filter.PerfilId} onChange={onChangeInput} onClickReset={onClickReset}>
                    <option value="">Selecione aqui</option>
                    {perfis.map((perfil, key) => (
                        <option value={perfil.Id} key={key}>{perfil.Nome}</option>
                    ))}
                </Select>

                <Select theme="alt" label="Status" name="Ativo" value={filter.Ativo} onChange={onChangeInput} onClickReset={onClickReset}>
                    <option value="">Selecione aqui</option>
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                </Select>
                
                <Divider />
                <button type="submit" className="btn btn-secondary">Filtrar</button>
                {filterFunc.includes("INCLUIR USUARIO") && (
                    <Link 
                        to={{
                            pathname: "/usuarios/novo",
                            state: { id: 'novo' }
                        }}  
                        className="btn btn-featured">
                            <IconAdd />
                            <span>Incluir</span>
                    </Link>
                )}
            </form>

            <hr />

            <table className="default">
                <thead>
                    <tr>
                        <th>Nome do Usuário</th>
                        <th>E-mail</th>
                        <th>Tipo</th>
                        {filterFunc.includes("ATIVO") && (
                            <th>Ativo</th>
                        )}
                        {filterFunc.includes("REENVIAR LINK") && (
                            <th className="sm">Reenviar<br/>Link Senha</th>
                        )}
                        {filterFunc.includes("EDITAR USUARIO") && (
                            <th className="sm">Editar</th>
                        )}
                    </tr>
                </thead>

                <tbody>
                    {users.map((user, index) => (
                    <tr key={index}>
                        <td>{user.Nome}</td>
                        <td>{user.Email}</td>
                        <td>{user.Perfil}</td>
                        {filterFunc.includes("ATIVO") && (
                            <td>
                                <Checkbox name="ativo" value={user.Ativo} onClick={() => onClickAlterarStatus(user)} />
                            </td>
                        )}
                        {filterFunc.includes("REENVIAR LINK") && (
                            <td>
                                <button type="button" className="btn btn-link-secondary" title="Reenviar link" onClick={() => onClickReenviarLink(user)}><IconAnexar /></button>
                            </td>
                        )}
                        {filterFunc.includes("EDITAR USUARIO") && (
                            <td>
                                <Link to={{
                                    pathname: `/usuarios/${user.Id}`,
                                    state: { id: user.Id }
                                }} 
                                className="btn btn-link" title="Editar"><IconConfigs /></Link>
                            </td>
                        )}
                    </tr>
                    ))}
                </tbody>
            </table>

            <Paginate 
                total={paginate.total} 
                lastPage={paginate.lastPage} 
                perPage={paginate.perPage} 
                currentPage={filter.Page} 
                onClickPage={onClickPage}
            />

        </Container>
    )
}

export default Listar