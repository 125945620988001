import React, { useEffect, useState } from "react"
import Checkbox from "../../../components/form/checkbox/Checkbox"
import { ContainerModal } from "../styles"
import { useAtendimento } from "../../../contexts/AtendimentoContext"
import api from "../../../services/api"
import { ContainerBtnSearch } from "../../../components/form/btnSearch/styles"
import { IconSearch } from "../../../components/layout/Svgs"

interface Props {
    readOnly?: boolean
}

const SelecionarSoftware: React.FC<Props> = ({
    readOnly = false
}) => {
    const [modal, setModal] = useState<boolean>(false)
    const [lista, setLista] = useState<any[]>([])
    const [selecionados, setSelecionados] = useState<any[]>([])
    const {atendimento, setAtendimento} = useAtendimento();
    console.log('selecionados', selecionados)

    useEffect(() => {
        api.get("/softwares").then((response) => {
            setLista([...response.data.result])
        })
    }, [])

    const onClickOpenModal = () => {
        if( !readOnly ){
            let array = atendimento.InstalacaoSoftware || []
            setSelecionados(array)
            setModal(true)
        }
    }

    const onClickCheckbox = (key:string, value:boolean) => {
        
        if( value === true ){
            selecionados.push(key)
        
        } else {

            var index = selecionados.indexOf(key)
            selecionados.splice(index, 1)

        }

        setSelecionados([...selecionados])
    }

    const handleSalvar = () => {
        delete atendimento.InstalacaoSoftware
        if( selecionados.length ) atendimento.InstalacaoSoftware = selecionados
        setAtendimento({...atendimento})
        setModal(false)
    }

    return (
    <>
        <ContainerBtnSearch>
            <label>Softwares *</label>
            <div className="item">
                <button type="button" className="input" onClick={onClickOpenModal}>
                    {atendimento.InstalacaoSoftware ? 
                    <>
                        {lista.map((row, key) => (
                            <>
                            {(atendimento.InstalacaoSoftware && atendimento.InstalacaoSoftware.includes(row.SoftwareId)) && 
                                <span className="tag" key={key}>{row.Software}</span>
                            }
                            </>
                        ))}
                    </>
                    :
                    <>
                        <IconSearch /> 
                        <span>Clique aqui para selecionar</span>
                    </>
                    }
                </button>
            </div>
        </ContainerBtnSearch>

        <ContainerModal className={`${modal === true ? "active" : ""}`}>

            <button type="button" className="modalOverlay" onClick={() => setModal(false)}></button>

            <div className="modal-box md">

                <h3 className="title">Softwares</h3>
                <p>(pode ser selecionado mais de um software)</p>
                <br />

                <div className="content-overflow">
                    { lista.map((row, key) =>
                    <div key={key}>
                        <Checkbox 
                            placeholder={row.Software} 
                            onClick={onClickCheckbox} 
                            name={row.SoftwareId} 
                            value={selecionados.includes(row.SoftwareId)}
                        />
                    </div>
                    )}
                </div>

                <div className="content-footer">
                    <button type="button" className="btn btn-primary" onClick={handleSalvar}>Salvar</button>
                    <button type="button" className="btn btn-danger" onClick={() => setModal(false)}>Cancelar</button>
                </div>
            </div>

        </ContainerModal>

    </>
    )
}

export default SelecionarSoftware