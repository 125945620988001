import React, { useEffect, useState } from "react";
import { Container } from "../../styles/layout";
import { Link } from "react-router-dom";
import Btn from "../../components/form/btn/Btn";
import { useHistory } from "react-router";
import { ButtonReset, Cliente, Divider } from "./styles";
import Input from "../../components/form/input/Input";
import ModalClientes from "./components/modal/ModalClientes";
import { IClienteEndereco } from "../../types/ClienteEndereco";
import axios from "axios";
import { ICliente } from "../../types/Cliente";
import { IconRemove } from "../../components/layout/Svgs";
import api from "../../services/api";
import message from "../../services/message";
import Checkbox from "../../components/form/checkbox/Checkbox";

interface Props {
    match: any
}

const Editar: React.FC<Props> = (props) => {
    const history = useHistory<any>()
    const [modal, setModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [DTO, setDTO] = useState<IClienteEndereco>({
        ClienteEnderecoId: 0,
        CodigoCliente: "",
        CodigoLojaCliente: "",
        CnpjCliente: "",
        NomeCliente: "",
        NomeLocal: "",
        CEP: "",
        Logradouro: "",
        Numero: "",
        Complemento: "",
        Bairro: "",
        Cidade: "",
        UF: "",
        Zona: "",
        PontoReferencia: "",
        Latitude: "",
        Longitude: "",
        Ativo: false,
    })

    useEffect(() => {
        api.get(`clientes-enderecos/${history.location.state.id}`).then(response => {
            setDTO({...response.data.result})
        })
    }, [props])

    const onClickModal = (cliente:ICliente) => {
        DTO.CodigoCliente = cliente.CodigoCliente
        DTO.CodigoLojaCliente = cliente.CodigoLojaCliente
        DTO.CnpjCliente = cliente.CNPJCliente
        DTO.NomeCliente = cliente.NomeCliente
        setDTO({...DTO})
        setModal(false)
    }

    const onChangeInput = async (key:string, value:any) => {
        
        let input:any = DTO

        if( key === "CEP" && value.length === 8 ){
            let response = await axios.get(`https://brasilapi.com.br/api/cep/v2/${value}`)
            if( response.data.cep ){
                input.Logradouro = response.data.street
                input.Bairro = response.data.neighborhood
                input.Cidade = response.data.city
                input.UF = response.data.state
                input.Latitude = response.data.location.coordinates?.latitude ?? ""
                input.Longitude = response.data.location.coordinates?.longitude ?? "" 
            }
        }

        input[key] = value
        setDTO({...input})
    }

    const onClickReset = async () => {
        delete DTO.CodigoCliente
        setDTO({...DTO})
    }

    function isFalsyOrEmpty(value: unknown): boolean {
        return !value || value === "";
      }


    async function handleSubmit(e: React.FormEvent)
    {
        try {
            e.preventDefault()
            setLoading(true)

            console.log('DTO', DTO)

            if(isFalsyOrEmpty(DTO.Latitude) || isFalsyOrEmpty(DTO.Longitude)) {
                throw "Endereço sem Latitude ou Longitude, favor preencher este campo"
              }
            
            if( history.location.state.id === "novo" ){
                var response = await api.post("/clientes-enderecos", DTO)
            } else {
                var response = await api.put(`/clientes-enderecos/${history.location.state.id}`, DTO)
            }
            if( response.data.error === true ) throw response.data.message
            setLoading(false)
            message.success("Endereço Salvo...")
            history.push('/enderecos')

        } catch ( err: any ) {
            setLoading(false)
            message.error(String(err))
        }
    }

    return (
        <Container>
            <h1>Endereços</h1>
            
            {!DTO.CodigoCliente ?
            <div className="actions">                        
                <Btn 
                    className="btn btn-featured" 
                    text="Selecionar Cliente" 
                    loading={loading}
                    onClick={() => setModal(true)} 
                />

                <Link to="/enderecos" className="btn btn-danger">
                    <span>Cancelar</span>
                </Link>
            </div>
            :
            <>
            <div className="content" style={{marginBottom: "20px"}}>
                
                <ButtonReset type="button" onClick={onClickReset}>
                    <span>Limpar</span>
                    <IconRemove />
                </ButtonReset>

                <h2 className="subtitle">Cliente</h2>
                
                <Cliente>
                    <div className="content">
                        <div className='client-address'>
                            <div className="item">
                                <p>Código do Cliente</p>
                                <span>{DTO.CodigoCliente}</span>
                            </div> 
                            <Divider />
                            <div className="item">
                                <p>Loja</p>
                                <span>{DTO.CodigoLojaCliente}</span>
                            </div>                              
                            <Divider />
                            <div className="item">
                                <p>Cliente</p>
                                <span>{DTO.NomeCliente}</span>
                            </div>
                            <Divider />
                            <div className="item">
                                <p>CNPJ</p>
                                <span>{DTO.CnpjCliente}</span>
                            </div>
                        </div>
                    </div>                        
                </Cliente>                                     
            </div>

            <form onSubmit={handleSubmit}>
                <div className="content">
                    <h2 className="subtitle">Endereço</h2>
                    <Input label="Nome do Local" placeholder="Digite aqui..."  name="NomeLocal" value={DTO.NomeLocal} onChange={onChangeInput} />
                    <Input label="CEP" placeholder="Digite aqui..."  name="CEP" value={DTO.CEP} onChange={onChangeInput}  mask="integer" maxLength={8} />
                    <Input
                        label="Latitude"
                        placeholder="Digite aqui..."
                        name="Latitude"
                        value={DTO.Latitude}
                        onChange={onChangeInput}

                    />

                    <Input
                        label="Longitude"
                        placeholder="Digite aqui..."
                        name="Longitude"
                        value={DTO.Longitude}
                        onChange={onChangeInput}

                    />
                    <Input label="Logradouro" placeholder="Digite aqui..."  name="Logradouro" value={DTO.Logradouro} onChange={onChangeInput} />
                    <Input label="Número" placeholder="Digite aqui..."  name="Numero" value={DTO.Numero} onChange={onChangeInput} />
                    <Input label="Complemento" placeholder="Digite aqui..."  name="Complemento" value={DTO.Complemento} onChange={onChangeInput} />
                    <Input label="Bairro" placeholder="Digite aqui..."  name="Bairro" value={DTO.Bairro} onChange={onChangeInput} />
                    <Input label="Cidade" placeholder="Digite aqui..."  name="Cidade" value={DTO.Cidade} onChange={onChangeInput} />
                    <Input label="UF" placeholder="Digite aqui..."  name="UF" value={DTO.UF} onChange={onChangeInput}  maxLength={2} />
                    <Input label="Zona" placeholder="Digite aqui..."  name="Zona" value={DTO.Zona} onChange={onChangeInput} />
                    <Input label="Ponto de Referência" placeholder="Digite aqui..."  name="PontoReferencia" value={DTO.PontoReferencia} onChange={onChangeInput} />
                    <Checkbox 
                        name="Ativo"
                        onClick={onChangeInput}
                        value={DTO.Ativo}
                        label="Ativo"
                    />
                </div>
                
                <div className="actions">
                    <Btn className="btn btn-featured" type="submit" text="Salvar" loading={loading} />
                    <Link to="/enderecos" className="btn btn-danger">Voltar</Link>
                </div>
            </form>
            </>
            }

            <ModalClientes
                active={modal}
                setActive={setModal}
                onClick={onClickModal}
            />

        </Container>        
    )
}

export default Editar