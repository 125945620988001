import styled from "styled-components"

export const ContainerHeader = styled.header`
    position: fixed;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;

    background-color: #fff;
    box-shadow: 0px 0px 10px #0000001A;

    > img{
        height: 40px;
        margin-top: 20px;
        margin-left: 20px;
    }

    > .containerAuth{
        position: relative;
        cursor: pointer;
        > h3{
            margin: 20px;
            padding-left: 20px;
            line-height: 40px;
            border-left: 1px solid var(--background);
            font-size: 90%;
        }
        > nav{
            position: absolute;
            right: 0;
            opacity: 0;
            visibility: hidden;

            background-color: #fff;
            box-shadow: 0px 5px 10px #0000001A;
            transition: .2s;

            a, button {
                display: block;
                width: 100%;
                padding: 10px 20px;
                text-align: center;
                text-decoration: none;
                background: transparent;
                border: none;
                color: var(--font);
                transition: .2s;
                &:hover{
                    text-decoration: underline;
                    color: var(--primary);
                }
            }
        }
        &:hover{
            > nav{
                opacity: 1;
                visibility: visible;
            }
        }
    }
`

export const ContainerSidebar = styled.div`
    position: fixed;
    z-index: 8;
    width: 80px;
    padding-top: 80px;
    > nav{
        width: 80px;
        height: calc(100vh - 80px);
        transition: .2s;
        background-color: var(--primary);
        box-shadow: 0px 0px 20px #0000001A;
        a, button{
            display: flex;
            width: 100%;
            height: 60px;
            background: transparent;
            border: none;
            text-decoration: none;
            border-left: 4px solid var(--primary);
            transition: .1s;
            .icon{
                opacity: .5;
                display: flex;
                justify-content: center;
                min-width: 80px;
                width: 80px;
                padding: 20px 0;
                svg{
                    width: 20px;
                    height: 20px;
                    color: #fff;
                }
            }
            .text{
                visibility: hidden;
                opacity: 0;
                width: 0;
                color: var(--font);
                padding: 20px 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: .2s;
                transition-delay: .2s;
            }
            &.active{
                border-color: #fff;
                .icon{
                    opacity: 1;
                }
            }
        }
    }

    :hover, &.active{
        > nav{
            width: 260px;
            background-color: #fff;
            overflow-y: auto;
            overflow-x: hidden;
            a, button{
                border-left: 4px solid #fff;
                .icon{
                    opacity: 1;
                    svg{
                        color: var(--primary);
                    }
                }
                .text{
                    visibility: visible;
                    width: auto;
                    opacity: 1;
                }
                &:hover{
                    border-left: 4px solid var(--primary);
                    .text{
                        color: var(--primary)
                    }
                }
            }
        }
    }
`

export const ContainerLayout = styled.div`
    position: relative;
    display: block;
    > .content{
        display: block;
        padding: 110px 30px 30px 110px;
    }
`