import { useEffect, useState } from 'react';

import { CSVLink } from "react-csv"
import { IconEdit } from '../../layout/Svgs';
import { Container } from './styles';

interface IProps {
    filename: string
    headers: []
    data: []
}

export default function ButtonDownload({filename, headers, data}:IProps){
    const [headersButton, setHeadersButton] = useState<any>([])
    const [dataButton, setDataButton] = useState<any>([])
    
    useEffect(() => {
        const newHeaders:any = []
        headers.map((row:any) => {
            return newHeaders.push({
                label: row.label,
                key: row.value
            })
        })
        setHeadersButton([...newHeaders])
        setDataButton([...data])
    }, [headers, data])

    return (
        <Container>
            <CSVLink filename={filename} headers={headersButton} data={dataButton}>
                <IconEdit color="#fff" />
                Exportar
            </CSVLink>
        </Container>
    )

}