import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  label {
    width: 100%;
  }
`;

export const GroupInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  height: 50px;
  background-color: #fff;
  box-shadow: 2px 5px 5px #0000000A;
  border-radius: 5px;
  padding: 0 0 0 1rem;
  position: relative;

  span {
    white-space: nowrap;
    color: #413F3F;
    font-weight: 600;
    padding: 0 12px 0 0;
  }

  input[type="date"] {
    outline: none;
    border: none;
    background: transparent;
  }
  .datepicker-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
  }
  .datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .buttonDate {
    display: grid;
    place-items: center;
    border: none;
    background-color: transparent;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    padding: 0 1rem 0 0;

    &.isDisabled {
      opacity: 1;
      visibility: visible;
    }
  }
`;