import React, { useEffect, useState } from "react"
import message from "../../services/message"
import Btn from "../../components/form/btn/Btn"
import api from "../../services/api"
import { Container } from "../../styles/layout"
import Select from "../../components/form/select/Select"
import moment from "moment"
import { ReactSortable } from "react-sortablejs"
import { GridPriorizar } from "./styles"
import { IUser } from "../../types/User"
import { IAtendimento } from "../../types/Atendimento"
import Mapa from "./components/Mapa"
import { checkAccessToken } from "../../utils/checkAccessToken"

interface Props {
    match: any
}

const Priorizar: React.FC<Props> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)

    const [atendimentos, setAtendimentos] = useState<IAtendimento[]>([])

    const [tecnicos, setTecnicos] = useState<IUser[]>([])

    const [routes, setRoutes] = useState<any[]>([])

    const [filter, setFilter] = useState<any>({
        idUsuarioTecnico: ""
    })

    useEffect(() => {
        api.get(`/usuarios?PerfilId=1`).then(response => {
            setTecnicos([...response.data.result])
        })
    }, [props])

    const onChangeTecnico = (value:string|number) => {
        filter.idUsuarioTecnico = String(value)
        setFilter({...filter})
        setAtendimentos([])
        if( filter.idUsuarioTecnico ) getAtendimentos()
    }

    const getAtendimentos = async () => {
        setLoading(true)
        const response = await api.get(`atendimentos?idUsuarioTecnico=${filter.idUsuarioTecnico}&StatusIn=Aberto,Retornado&Order=OrdemPriorizacao`)
        setAtendimentos(response.data.result.filter((obj: any) => obj.Status !== "Cancelado"));

        // setAtendimentos([...response.data.result])

        let routes = response.data.result.filter((obj: any) => obj.Status !== "Cancelado").map((row: any) => {
            return {
                lat: row.Latitude,
                lng: row.Longitude,
                att: row.AtendimentoId,
                cod: row.CodigoAtendimento,
                status: row.Status
            }
        })
        setRoutes([...routes]);
        setLoading(false);
    }

    const onClickAtualizarMapa = async () => {

        let routes = atendimentos.map((row:any) => {
            return {
                lat: row.Latitude,
                lng: row.Longitude,
                att: row.AtendimentoId
            }
        })
        setRoutes([...routes])

    }

    const onClickAplicar = async () => {
        try {
            let response = await api.put("atendimentos-priorizar", atendimentos)
            if( response.data.error ) throw response.data.message
            message.success(response.data.message)
        } catch(error){
            if(error === "Token Inválido" || error === "Token expirado.") {
                checkAccessToken("Sessão expirada! 😥");
            } else {
                message.error(String(error))
            }
        }
    }

    const onChangeReordenar = async (id:any, order:any) => {

        if( Number(order) ){
            let items = atendimentos.map((row, key) => {
                row.OrdemPriorizacao = row.AtendimentoId === id ? Number(order) : key+1
                return row
            })
    
            items.sort(function (a, b) {
                if (Number(a.OrdemPriorizacao) > Number(b.OrdemPriorizacao)) {
                    return 1
                } else {
                    return -1
                }
            })
    
            setAtendimentos([...items])
        }
    }

    console.log('ATENDIMN', routes)

    return (
        <Container>

            <h1>Priorizar Atendimentos</h1>

            <form className="filter">
                <div style={{width: 680}}>
                    <Select 
                        label="Técnico"
                        theme="alt"
                        name="idUsuarioTecnico" 
                        value={filter.idUsuarioTecnico} 
                        onChange={(key, value) => onChangeTecnico(value)} 
                        >
                        <option value="">Selecione aqui</option>
                        {tecnicos.map((row, key) => (
                        <option key={key} value={row.Id}>{row.Nome}</option>
                        ))}
                    </Select>
                </div>
            </form>

            <hr />

            { (filter.idUsuarioTecnico && !atendimentos.length) && (
            <>
                <h3 className="result">Resultado</h3>
                <p>Nenhum resultado foi encontrado</p>
            </>
            ) }

            { atendimentos.length > 0 && 
                <>
                    <h3 className="result">Resultado</h3>
                    
                    <GridPriorizar>
                        <div className="default">
                            <div className="arrows"></div>
                            <div>Ordem</div>
                            <div>Código Atendimento</div>
                            <div>Tipo</div>
                            <div>Categoria</div>
                            <div>Nº de Série</div>
                            <div>Data/hora Abertura</div>
                            <div>Data/hora Agendada</div>
                            <div>Cliente</div>
                            <div>Local</div>
                            <div>Status</div> 
                        </div>
                        <ReactSortable className="sortable" list={atendimentos as any} setList={(newState:any) => setAtendimentos(newState)}>
                            {atendimentos.map((row, index) => (
                                <div className="line" key={index}>
                                    <div className="arrows">
                                        <button onClick={() => onChangeReordenar(row.AtendimentoId, index)}><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><g fill="none"><path d="M13.153 4.682c1.236-2.241 4.458-2.24 5.693.001l10.742 19.499C30.782 26.348 29.215 29 26.742 29H5.25c-2.474 0-4.04-2.653-2.846-4.82l10.75-19.498zm3.941.966a1.25 1.25 0 0 0-2.19 0L4.156 25.146A1.25 1.25 0 0 0 5.25 27h21.492a1.25 1.25 0 0 0 1.095-1.853L17.093 5.648z" fill="currentColor"/></g></svg></button>
                                        <button onClick={() => onChangeReordenar(row.AtendimentoId, index+2)}><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><g transform="rotate(180 16 16)"><g fill="none"><path d="M13.153 4.682c1.236-2.241 4.458-2.24 5.693.001l10.742 19.499C30.782 26.348 29.215 29 26.742 29H5.25c-2.474 0-4.04-2.653-2.846-4.82l10.75-19.498zm3.941.966a1.25 1.25 0 0 0-2.19 0L4.156 25.146A1.25 1.25 0 0 0 5.25 27h21.492a1.25 1.25 0 0 0 1.095-1.853L17.093 5.648z" fill="currentColor"/></g></g></svg></button>
                                    </div>
                                    <div className="order">
                                        <input type="text"
                                            value={index+1} 
                                            maxLength={2}
                                            onChange={(e) => onChangeReordenar(row.AtendimentoId, e.target.value)} 
                                            onFocus={(e) => e.target.select()}
                                        />
                                    </div>
                                    <div className="line-data">{row.CodigoAtendimento}</div>
                                    <div className="line-data">{row.TipoAtendimento}</div>
                                    <div className="line-data">{row.CategoriaDoProblema ? row.CategoriaDoProblema : "--"}</div>
                                    <div className="line-data">{row.NroSerieMaquina ? row.NroSerieMaquina : "--"}</div>
                                    <div className="line-data">{moment(row.DataHoraAbertura).utc().format("DD/MM/YYYY HH:mm")}</div>
                                    <div className="line-data">{row.DataHoraAgendamento ? moment(row.DataHoraAgendamento).utc().format("DD/MM/YYYY HH:mm") : "--"}</div>
                                    <div className="line-data">{row.NomeCliente}</div>
                                    <div className="line-data">{row.NomeLocal}</div>
                                    <div className="line-data">{row.Status}</div>                                    
                                </div>
                            ))} 
                        </ReactSortable>    
                    </GridPriorizar>

                    {(routes.length > 0) && <Mapa routes={routes} id={atendimentos[0]?.AtendimentoId}  />}
                    
                    <div className="actions">
                        <Btn className="btn btn-secondary" type="button" text="Atualizar Mapa" loading={loading} onClick={onClickAtualizarMapa} />
                        <Btn className="btn btn-primary" type="button" text="Aplicar" loading={loading} onClick={onClickAplicar} />
                    </div>
                </>
            }
            
        </Container>    
    )
}

export default Priorizar