import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../components/form/input/Input";
import {
  IconAdd,
  IconConfigs,
  IconEdit,
  IconRemove,
} from "../../components/layout/Svgs";
import api from "../../services/api";
import { Container } from "../../styles/layout";
import Modal from "../../components/modal/Modal";
import Textarea from "../../components/form/textarea/Textarea";
import message from "../../services/message";

import Btn from "../../components/form/btn/Btn";
import Filtros from "./components/Filtros";
import { ContainerFiltros } from "./styles";
import moment from "moment";
import { useAuth } from "../../hooks/Auth";
import { useDebounce } from "../../utils/useDebounce";
import Paginate from "../../components/paginate/Paginate";
import { checkAccessToken } from "../../utils/checkAccessToken";
import { useAtendimento } from "../../contexts/AtendimentoContext";

export interface FilterProps {
  Search?: string;
  CodigoCliente?: string;
  NomeCliente?: string;
  CodigoLojaCliente?: string;
  TipoContrato?: string;
  AtendimentoEndereco_NomeLocal?: string;
  AtendimentoEndereco_UF?: string;
  AtendimentoEndereco_Cidade?: string;
  idUsuarioTecnico?: string;
  SemTecnicoAtribuido?: string;
  Status?: string;
  TipoAtendimentoId?: string;
  DataHoraAbertura: string;
  DataHoraAberturaFinal: string;
  DataHoraInicioAtendimento?: string;
  DataHoraTerminoAtendimento?: string;
}

const Listar: React.FC = () => {
  const history = useHistory();
  const { filter, setFilter, setAttendence, attendence } = useAtendimento();
  const { user } = useAuth();
  const [cancelar, setCancelar] = useState<any>(false);
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 700);
  const filterFunc = user.funcionalidades
    .filter((obj) => obj.Tela === "frmAtendimentos")
    .map((row) => row.Nome);

  const [page, setPage] = useState<any>(1);
  const [paginate, setPaginate] = useState<any>({
    total: 0,
    lastPage: 1,
    perPage: 10,
    currentPage: 1,
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(searchTerm);
    } else {
      setSearch(searchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleFilter = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  function orderData(data: any) {
    const splitData = data.split("/");
    const convertData = `${splitData[2]}-${splitData[1]}-${splitData[0]}`;
    return convertData;
  }

  console.log(observation);

  async function getAtendimentos() {
    setAttendence([]);
    try {
      setLoading(true);
      if (filter.DataHoraAberturaFinal.length > 0) {
        const dataInicioConvertida = orderData(filter.DataHoraAbertura);
        const dataFinalConvertida = orderData(filter.DataHoraAberturaFinal);

        if (moment(dataInicioConvertida).isAfter(dataFinalConvertida)) {
          throw "Data Inicial deve ser maior que a data final";
        }
      }

      const response = await api
        .get(
          `atendimentos?Search=${filter.Search}&DataHoraAbertura=${filter.DataHoraAbertura}&CodigoCliente=${filter.CodigoCliente}&NomeCliente=${filter.NomeCliente}&CodigoLojaCliente=${filter.CodigoLojaCliente}&Status=${filter.Status}&TipoContrato=${filter.TipoContrato}&AtendimentoEndereco_NomeLocal=${filter.AtendimentoEndereco_NomeLocal}&AtendimentoEndereco_UF=${filter.AtendimentoEndereco_UF}&AtendimentoEndereco_Cidade=${filter.AtendimentoEndereco_Cidade}&idUsuarioTecnico=${filter.idUsuarioTecnico}&SemTecnicoAtribuido=${filter.SemTecnicoAtribuido}&TipoAtendimentoId=${filter.TipoAtendimentoId}&DataHoraAberturaFinal=${filter.DataHoraAberturaFinal}&DataHoraInicioAtendimento=${filter.DataHoraInicioAtendimento}&DataHoraTerminoAtendimento=${filter.DataHoraTerminoAtendimento}`
        )
        .catch((error) => {
          throw error.response.data.message;
        });

      if (response.data.error) {
        throw response.data.message;
      }

      setAttendence([...response.data.result]);
      setPaginate({ ...response.data.pagination });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (error === "Token Inválido" || error === "Token expirado.") {
        checkAccessToken("Sessão expirada! 😥");
      } else {
        message.error(String(error));
      }
    }
  }

  const handleNew = async () => {
    history.push({
      pathname: "/atendimentos/novo",
      state: { id: "novo" },
    });
  };

  const handleCancelar = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (observation === "") {
        throw "Favor informar uma observação para o cancelamento!";
      }

      const response = await api.put(
        `/atendimentos/${cancelar.AtendimentoId}/cancelar`,
        {
          Observacao: observation,
        }
      );

      if (response.data.error) throw response.data.message;

      // setLoading(false)
      await getAtendimentos();
      setCancelar(false);
      message.success(response.data.message);
    } catch (error) {
      setLoading(false);

      if (error === "Token Inválido" || error === "Token expirado.") {
        checkAccessToken("Sessão expirada! 😥");
      } else {
        message.error(String(error));
      }
    }
  };

  async function setSearch(value: string) {
    filter.Search = value;
    setFilter({ ...filter });
    await getAtendimentos();
  }

  const handleReset = async () => {
    setFilter({
      Search: "",
      CodigoCliente: "",
      NomeCliente: "",
      CodigoLojaCliente: "",
      TipoContrato: "",
      AtendimentoEndereco_NomeLocal: "",
      AtendimentoEndereco_UF: "",
      AtendimentoEndereco_Cidade: "",
      idUsuarioTecnico: "",
      SemTecnicoAtribuido: "",
      Status: "",
      TipoAtendimentoId: "",
      DataHoraAbertura: "",
      DataHoraAberturaFinal: "",
      DataHoraInicioAtendimento: "",
      DataHoraTerminoAtendimento: "",
    });
    setAttendence([]);
    // await getAtendimentos()
  };

  function checkUserType(id: any, type: any) {
    console.log("TYPE", type);
    if (type === 2 || type === 4) {
      history.push(`/atendimentos/${id}/consultar2`, { id });
    } else {
      history.push(`/atendimentos/${id}/consultaSimples`, { id });
    }
  }
  return (
    <Container>
      <h1>Atendimentos</h1>

      <ContainerFiltros>
        <form className="filter justifyContent" onSubmit={handleFilter}>
          <div className="flex">
            <div style={{ width: 600 }}>
              <Input
                placeholder="Digite aqui o Número de Série, Código de atendimento ou nome do cliente"
                label="Buscar atendimento"
                name="Search"
                value={searchTerm}
                search={true}
                theme="alt"
                loading={loading}
                onChange={(name, value) => setSearchTerm(value)}
                onClickReset={() => setSearchTerm("")}
              />
            </div>

            <Filtros
              handleSubmit={(filter) => getAtendimentos()}
              handleReset={handleReset}
            />
            <button type="submit" style={{ display: "none" }}></button>
          </div>

          {filterFunc.includes("INCLUIR ATENDIMENTO") && (
            <button
              type="button"
              className="btn btn-featured"
              onClick={handleNew}
            >
              <IconAdd />
              <span>Incluir</span>
            </button>
          )}
        </form>
      </ContainerFiltros>
      {/* <Notification amount={notifications} /> */}
      <hr />

      {attendence.length > 0 && (
        <>
          <h3 className="result">Resultado</h3>

          <div className="table-scroll">
            <table className="default">
              <thead>
                <tr>
                  {filterFunc.includes("CUNSULTAR ATENDIMENTO") && (
                    <th>Consultar</th>
                  )}
                  {filterFunc.includes("EDITAR ATENDIMENTO") && <th>Editar</th>}
                  {filterFunc.includes("CANCELAR ATENDIMENTO") && (
                    <th>Cancelar</th>
                  )}
                  <th>Código Atendimento</th>
                  <th>Tipo</th>
                  <th>N. de Série</th>
                  <th>Data Abertura</th>
                  <th>Código Cliente</th>
                  <th>Código Loja</th>
                  <th>Técnico</th>
                  <th style={{ minWidth: 350 }}>Cliente</th>
                  <th>Contrato</th>
                  <th>Tipo</th>
                  <th>Local</th>
                  <th>Status</th>
                  <th>Início</th>
                  <th>Término</th>
                  <th>Tempo Atendim.</th>
                  <th>Distância Descolamen.</th>
                  <th>Tempo Deslocamen.</th>
                </tr>
              </thead>

              <tbody>
                {attendence.map((row, index) => (
                  <tr key={index}>
                    {filterFunc.includes("CUNSULTAR ATENDIMENTO") && (
                      <td>
                        <button
                          onClick={() =>
                            checkUserType(row.AtendimentoId, user.PerfilId)
                          }
                          className="btn btn-link-secondary"
                          title="Consultar"
                        >
                          <IconEdit />
                        </button>
                      </td>
                    )}
                    {filterFunc.includes("EDITAR ATENDIMENTO") && (
                      <td>
                        <button
                          onClick={() =>
                            history.push(`/atendimentos/${row.AtendimentoId}`, {
                              id: row.AtendimentoId,
                            })
                          }
                          className="btn btn-link-secondary"
                          title="Editar"
                        >
                          <IconConfigs />
                        </button>
                      </td>
                    )}
                    {filterFunc.includes("CANCELAR ATENDIMENTO") && (
                      <td>
                        <button
                          className="btn btn-link-danger"
                          title="Cancelar"
                          onClick={() => setCancelar(row)}
                        >
                          <IconRemove />
                        </button>
                      </td>
                    )}
                    <td>{row.CodigoAtendimento}</td>
                    <td>{row.TipoAtendimento}</td>
                    <td>{row.NroSerieMaquina}</td>
                    <td>
                      {moment(row.DataHoraAbertura)
                        .utc()
                        .format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td>{row.CodigoCliente}</td>
                    <td>{row.CodigoLojaCliente}</td>
                    <td>{row.NomeTecnico}</td>
                    <td>{row.NomeCliente}</td>
                    <td>{row.NroContrato}</td>
                    <td>{row.TipoContrato}</td>
                    <td>{row.NomeLocal}</td>
                    <td>{row.Status}</td>
                    <td>
                      {row.DataHoraInicioAtendimento &&
                        moment(row.DataHoraInicioAtendimento)
                          .utc()
                          .format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td>
                      {row.DataHoraTerminoAtendimento &&
                        moment(row.DataHoraTerminoAtendimento)
                          .utc()
                          .format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td>{row.TempoExecucao}</td>
                    <td>{row.DistanciaKM}</td>
                    <td>{row.TempoDeslocamento}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Paginate
            total={paginate.total}
            lastPage={paginate.lastPage}
            perPage={paginate.perPage}
            currentPage={page}
            onClickPage={setPage}
          />
        </>
      )}

      <Modal active={cancelar === false ? false : true} setActive={setCancelar}>
        <form onSubmit={handleCancelar}>
          <h3>Antendimento nº {cancelar.CodigoAtendimento}</h3>
          <p>Confirma o cancelamento do atendimento?</p>
          <Textarea
            label="Observações"
            placeholder="Digite aqui..."
            name="Observacao"
            value={observation}
            onChange={(_, value) => setObservation(value)}
          />
          <div className="modalFooter">
            <Btn
              type="submit"
              text="Confirmar"
              className="btn btn-featured"
              loading={loading}
            />
            <Btn
              type="button"
              text="Cancelar"
              className="btn btn-danger"
              onClick={() => setCancelar(false)}
            />
          </div>
        </form>
      </Modal>
    </Container>
  );
};

export default Listar;
