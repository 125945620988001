import React from "react";
import RadioContainer from "./styles";

interface Props {
  name: String;
  value: Boolean;
  placeholder?: String;
  className?: String;
  onChange(name: any, value: boolean): void;
  readOnly?: boolean;
  isDisabled?: boolean;
}

export const Radio: React.FC<Props> = ({
  name,
  value,
  placeholder,
  className,
  onChange,
  readOnly,
  isDisabled
}) => {
  const handleRadio = () => {
    if (!readOnly) {
      if (value) {
        onChange(name, false);
      } else {
        onChange(name, true);
      }
    }
  };

  return (
    <RadioContainer
      className={`${value ? "active" : "inactive"}`}
      active={value ? true : false}
      onClick={handleRadio}
      isDisabled={isDisabled}
    >
      <span className="circle"></span>
      <span className="text">{placeholder}</span>
    </RadioContainer>
  );
};
