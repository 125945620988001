import styled from "styled-components"

export const SectionFilterResume = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 600px) minmax(200px, max-content);
  align-items: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1.5rem;

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;
  } 
`

export const SectionMapResume = styled.div`

`;