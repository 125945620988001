import styled  from "styled-components";

export const NomePlaca = styled.div`
    width: 680px;
    margin-right: 10px;    
`

export const ProprietarioVeiculo = styled.div`
    form {
        margin-top: 30px;
        div.content {
        max-width: 930px !important;
        border-radius: 5px;        
        }    
    }    
    .button {
        margin-top: 30px;
        width: 290px !important;        
    }
    .button.hide {
        visibility: hidden;
    }
    .buttons {
        max-width: 290px;
        position: relative;
        left: 380px;
        top: 80px;
    }
`

export const ConsultarMain = styled.div`
    .button {
        width: 290px !important;
    }
    .buttons {
        display: flex;
        gap: 100px;
    }
`

export const DadosTecnico = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 930px !important;
    margin-top: 20px;
    margin-bottom: 8px;
    border-radius: 5px;
    .subtitle {
        font-size: 18px !important;
        margin-bottom: 30px;
    }
    .technician {
        display: flex;
        justify-content: flex-start;
        margin-right: 60px;
        gap: 60px;
        border-top: 1px solid rgba(0,0,0,.1);
        padding-top: 20px;
        p {
            color: var(--font);
        }                     
        span {
            color: #413f3f;
            font-weight: 600;                
        }   
    }
    .vehicle {
        input {
            margin-top: 10px;
            margin-right: 20px;
        }
        span {
            color: #413f3f;
            font-size: 18px;
            font-weight: 600;
        }
        .plate {
            margin-bottom: 35px;
            p {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }   
    }
`

export const Divider = styled.div`
    border-left: 1px solid #D5D5D5;
`

export const VehicleDivider = styled.div`
    width: 1px;
    background-color: #D5D5D5;
    margin-left: 20px;
    margin-right: 30px;
    height: 30px;
    margin-bottom: 22px;
`

export const CardWrapper = styled.div`
    .content {
        display: flex;
        flex-direction: column;
        max-width: 930px !important;
        margin-bottom: 8px;
        border-radius: 5px;
        .subtitle {
            font-size: 18px !important;
            margin-bottom: 30px;
        }
        .technician {
            display: flex;
            justify-content: space-between;
            margin-right: 60px;
            p {
                color: var(--font);
            }                     
            span {
                color: #413f3f;
                font-size: 18px;
                font-weight: 600;                
            }   
        }
        .vehicle {
            input {
                margin-top: 10px;
                margin-right: 20px;
            }
            span {
                color: #413f3f;
                font-size: 18px;
                font-weight: 600;
            }
            .plate {
                margin-bottom: 35px;
                p {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }   
        }
        .checkbox {
            display: flex;
            align-items: center;
            p {
                margin-left: -10px;
                margin-top: 20px;
                font-weight: 600;
                font-size: 16px;
            }
        }
    }
`