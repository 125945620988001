import message from "../services/message"

export const checkAccessToken = async (msg: string) => {
  let bool = await message.confirm({
    title: 'Sessão!',
    text: msg,
    isCancel: false,
    button: "Voltar"
  })

  if ( bool ) {
    localStorage.removeItem("@AmazonCopy:token");
    localStorage.removeItem("@AmazonCopy:user");
    window.location.reload();
  } 
}
