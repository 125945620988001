import React from "react"
import {DivTextarea} from "./styles"

interface Props {
    name: string
    value: string | undefined
    onChange: (key: string, value: string) => void
    label?: string
    placeholder?: string
    readOnly?: boolean
}

const Textarea: React.FC<Props> = (Props) => {

    const onChange = (e:React.FormEvent<HTMLTextAreaElement>) => {
        let value = e.currentTarget.value
        Props.onChange(Props.name, value)
    }

    return (
        <DivTextarea className="divTextarea">
            <label>
                {Props.label && <div className="label">{Props.label}</div>}
                <textarea
                    placeholder={Props.placeholder} 
                    value={Props.value} 
                    onChange={onChange} 
                    readOnly={Props.readOnly}
                />
            </label>
        </DivTextarea>
    )

            
}

export default Textarea