import { Container, Overlay } from "./styles"

interface Props {
    onClose: () => void;
    visible: boolean;
    id: string;
    children: React.ReactNode
}

export default function ModalDefault({ onClose, id, visible, children }: Props) {
    const handleCloseModal = (e: any) => {
        if (e.target.id === id) {
            onClose();
        }
    }

    return(
        <Overlay id={id} onClick={handleCloseModal} visible={visible}>
            <Container visible={visible}>
                {children}
            </Container>
        </Overlay>
    )
}


// const Modal:React.FC<Props> = (props) => {
//     return (
//         <ContainerModal className={`${props?.className} ${props.active === true ? "active" : ""}`}>
//             <button type="button" className="modalOverlay" onClick={() => props.setActive(false)}></button>
//             <div className="modalContent">
//                 {props.children}
//             </div>
//         </ContainerModal>
//     )
// }

// export default Modal