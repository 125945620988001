
import logoAlt from "../../assets/images/logo-alt.svg"

import { Container, Content } from './styles';

export function Home(){
  return (
    <Container>
      <Content>
        <img src={logoAlt} alt="Amazoncopy" />
      </Content>
    </Container>
  );
}