import axios from "axios"
var baseUrl

if(process.env.NODE_ENV === 'development') {
   // baseUrl = "http://192.168.15.13:3337/"
    baseUrl = "https://amazoncopy.backendtropa.com.br/"
    // baseUrl = 'https://54f6-191-13-211-74.ngrok-free.app/';

} else {
    baseUrl = "https://amazoncopy.backendtropa.com.br/"
}

const api = axios.create({
    baseURL: baseUrl,
    headers: {
        'ngrok-skip-browser-warning': 'ngrok-skip-browser-warning'
    }
    // headers: {
    //     'Authorization': 'Bearer ' + token
    // }
})

export default api
