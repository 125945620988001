import styled from "styled-components";

interface Props {
    width?: string
}

export const ContainerAtendimentos = styled.header`
  .list-infos {
    display: flex;
    flex-direction: column;
    .info {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      .title {
        color: #7b7b7b;
        font-size: 90%;
      }
      .value {
        color: #413f3f;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
  .container-infos {
    padding: 20px 25px;
    background: #fff;
    border-radius: 5px 0px 0px 5px;
    width: 60%;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 15px; */
    .list-infos {
      margin-top: 20px;
      span.title {
        padding-bottom: 5px;
      }
    }
    > p {
      font-size: 16px;
      margin-bottom: 5px;
    }
    .divSelectAlt {
      margin: 0;
      .label {
        padding-left: 4px;
      }
      select {
        padding: 0px 0px 0 0;
        margin-top: 0px;
        box-shadow: none;
        border-radius: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    .observation {
      width: 100%;
      border: solid 1px #d8d8d8;
      border-radius: 4px;
      padding: 20px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .observation.signature {
      text-align: center;
      width: 100%;
      height: 18vh;
    }
  }
  .container-service {
    padding: 20px 25px;
    background: #fff;
    border-radius: 5px 0px 0px 5px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* margin-bottom: 15px; */
    p.title {
      font-size: 16px;
      margin-bottom: 5px;
    }
    span.title {
      padding-bottom: 5px;
    }
    .service-date {
      display: flex;
      align-items: flex-start;
      gap: 15%;
      margin-top: 20px;
      margin-bottom: 20px;
      .date-begin,
      .date-end {
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
          margin-bottom: 10px;
        }
        span.value {
          font-weight: 600;
        }
      }
    }
    .service-km {
      display: flex;
      align-items: flex-start;
      gap: 15%;
      margin-bottom: 20px;
      .km-begin,
      .km-end {
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
          margin-bottom: 10px;
        }
        span.value {
          font-weight: 600;
        }
      }
    }
    .service-data {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .value {
        font-weight: 600;
      }
    }
  }
  .container-table {
    width: 98%;
  }
`;

export const ContainerModal = styled.div`
  .modalOverlay {
    cursor: default;
    z-index: 9;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    border: none;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
  }
  .modal-box {
    box-sizing: border-box;
    z-index: 9999;
    position: fixed;
    width: 1492px;
    max-width: 90%;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--background) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 60px 70px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    &.md {
      width: 600px;
    }
    .head-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 30px;
      form {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .divInput {
          width: 70%;
          margin-bottom: 0px;
          margin-right: 20px;
        }
        .btn {
          width: 170px;
          margin-bottom: 0px;
        }
      }
    }
    .content-table {
      max-height: 50vh;
      width: 100%;
      overflow: auto;
      table {
        width: 100%;
        td {
          cursor: pointer;
        }
      }
    }
    .content-overflow {
      box-sizing: border-box;
      padding: 15px;
      height: 350px;
      overflow-y: scroll;
      background-color: #eee;
    }
    .content-footer {
      display: flex;
      justify-content: space-between;
      .btn {
        margin: 20px 10px 0 10px;
      }
    }
  }
  &.active {
    .modalOverlay {
      visibility: visible;
      opacity: 1;
    }
    .modal-box {
      opacity: 1;
      visibility: visible;
      top: 50%;
    }
  }
`;

export const ContainerTipo = styled.div`
  &.readOnly {
    input,
    select {
      cursor: default;
      border: none;
      padding: 0;
      height: auto;
      font-weight: 600;
    }
  }
`;

export const ContainerFiltros = styled.div`
  .modalFilter {
    .modalContent {
      width: 800px;
      left: calc(50% - 400px);
      background-color: #f5f5f5;
      overflow-y: scroll;
      .accordion {
        position: relative;
        height: 60px;
        overflow: hidden;
        background-color: #fff;
        border-radius: 5px;
        margin: 20px 0;
        &:after {
          position: absolute;
          top: 20px;
          right: 20px;
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-top: 2px solid #777;
          border-left: 2px solid #777;
          border-radius: 2px;
          transform: rotate(-135deg);
        }
        .accordionHeader {
          cursor: pointer;
          padding: 20px;
          height: 60px;
          p {
            font-weight: 600;
            margin: 0;
            padding: 0;
          }
        }
        .accordionContent {
          visibility: hidden;
          margin-top: -20px;
          height: 0px;
        }
        &.active {
          height: auto;
          &:after {
            background-color: var(--primary);
            width: 12px;
            height: 12px;
            border: 0;
            transform: rotate(0);
          }
          .accordionContent {
            visibility: visible;
            margin-top: 0px;
            padding: 0 20px 20px 20px;
            height: auto;
          }
        }
      }
    }
  }
`;

export const AtribuirTecnico = styled.div`
  .list-technician {
    display: flex;
    align-items: flex-end;
    max-width: 970px;
  }
  .btn.btn-featured {
    width: 290px;
    margin-left: 20px;
  }
`;
export const CustomerService = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 2rem;
  padding: 20px;
  background: #ffffff61;
  margin-bottom: 1rem;
  margin-top: 2rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

  >.btn {
    margin: 0;
  }

  .headerStatusAttendence {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .code,
  .info {
    display: flex;
    flex-direction: column;
    span.value {
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 4px;
      .statusBall {
        width: 7px;
        height: 7px;
        border-radius: 12px;
        margin-bottom: -4px;
        &.Aberto {
            background-color: #00A85A;
        }
        &.EmAtendimento {
            background-color: #D5BA07;
        }
        &.EmAnalise {
            background-color: #07B2D5;
        }
        &.Finalizado {
            background-color: #D82121;
        }
      }
    }

    span.title {
      padding-bottom: 5px;
    }
  }
`;

export const TabsService = styled.div`
  margin-bottom: 20px;
  .nav-tab {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding-top: 20px;
    height: 80px;
    li {
      list-style: none;
      width: 100%;
      text-align: center;
      padding: 0 2px 10px 2px;
      border-bottom: 2px solid var(--primary);
      cursor: pointer;
      &.active {
        border: solid var(--primary);
        border-width: 2px 2px 0 2px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        text-align: center;
        padding: 10px 0 12px 0;
        box-sizing: border-box;
        .tabNanAttendence {
          color: var(--primary);
        }
      }
    }
    .tabNanAttendence {
      color: #494747;
      text-decoration: none;
      font-weight: 600;
      text-align: center;
    }
  }
`;

export const GridPriorizar = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0;
  .default {
    display: grid;
    grid-template-columns: 40px 60px repeat(9, 10%);
    justify-items: center;
    white-space: pre;
    margin-bottom: 10px;
    font-size: 12px;
    color: #888888;
  }
  .sortable {
    display: block;
    .line {
      width: 100%;
      display: grid;
      grid-template-columns: 40px 60px repeat(9, 10%);
      justify-items: center;
      align-items: center;
      white-space: pre;
      margin: 5px 0;
      padding: 10px 0;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #fff;
      cursor: move;
      &:hover {
        border: 1px solid var(--primary);
      }
      .arrows {
        button {
          display: block;
          background: transparent;
          border: 0;
        }
      }
      .order {
        input {
          width: 100%;
          padding: 10px;
          background: #f3f3f3;
          border-radius: 8px;
          border: 0;
          text-align: center;
          cursor: pointer;
        }
      }
      .line-data {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 98%;
      }
    }
  }
`;

export const ListInfo = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ? width : '60%'};
  gap: 1rem;

  .tableDefault {
    width: 60%;
    border-spacing: 0 0.5rem;

    tr {
      position: relative;

      th {
        white-space: nowrap;
        color: var(--default);
        text-align: left;
        padding: 0 1rem;

        &.arqImage {
          text-align: center;
        }
      }

      td {
        opacity: 0.8;
        padding: 1rem 1rem;
        background-color: #fff;
        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &.arqImage {
          text-align: center;
        }

        .buttonArqImage {
          background-color: transparent;
          border: none;
          svg {
            transition: 0.45s;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }

      &:hover {
        td {
          opacity: 1;
        }
      }
    }
  }

  .sectionSignature {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    /* background: lightBlue; */

    .signatureImage {
      display: grid;
      place-items: center;

      width: 100%;
      height: 100%;

      img {
        display: grid;
        place-items: center;
        object-fit: contain;
        width: 100%;
        height: 180px;
      }
    }
  }

  .tableDefaultHistory {
        width: 100%;
        border-spacing: 0 4px;
        th {
            padding: 1rem;
            color: #888888;
            font-weight: 600;
            text-align: left;
            line-height: 1.5rem;
            background-color: #F5F5F5;
        }

        td {
            padding: 1rem 1rem;
            border: 0;
            color: #413F3F;
            font-size: 1rem;
        }

        .buttonArqImage {
          background-color: transparent;
          border: none;
          svg {
            transition: 0.45s;
            &:hover {
              transform: scale(1.2);
            }
          }
        }

        .tableStatus {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 1rem 1rem;
            border: 0;
            color: #413F3F;
            font-size: 1rem;
            margin-top: 6px;

            .statusBallColor {
                width: 8px;
                height: 8px;
                border-radius: 12px;
                margin-bottom: -4px;
                background-color: red;
                
                &.Aberto {
                    background-color: #00A85A;
                }
                &.EmAtendimento {
                    background-color: #D5BA07;
                }
                &.EmAnalise {
                    background-color: #07B2D5;
                }
            }
        }

        tbody {
            tr {
                border-radius: 0.25rem;
                background-color: #fff;
                transition: all 0.35s;
    
                &:hover {
                    transition: all 0.35s;
                    cursor: pointer;
                    box-shadow: 2px 2px 25px #0000001A;
                }
            }
        }
  }

  .buttonClosePreview {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 4px 12px;
        gap: 10px;

        line-height: 1rem;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        background-color: transparent;
        border: none;

        transition: all 0.35s;
        &:hover {
            transform: scale(1.1);
        }
    }
`;

export const SectionPreviewImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 440px;
    height: 580px;
    border-radius: 12px;

    .imagePreviewOdo {
        display: grid;
        place-items: center;
        border-radius: 12px;

        object-fit: contain;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.55));
    }

    .buttonClosePreview {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        padding: 4px 12px;
        gap: 10px;

        line-height: 1rem;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        background-color: transparent;
        border: none;

        transition: all 0.35s;
        &:hover {
            transform: scale(1.1);
        }
    }
`;

export const ContainerModalAnalise = styled.div`
  display: flex;
  flex-direction: column;

  width: 780px;
  background-color: #fff;
  border-radius: 5px;

  .headerModalAnalise {
    display: flex;
    flex-direction: column;

    padding: 32px;
  }

  .modalAnaliseInfos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .info {
    display: flex;
    flex-direction: column;
    span.value {
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 4px;
      .statusBall {
        width: 7px;
        height: 7px;
        border-radius: 12px;
        margin-bottom: -4px;
        &.Aberto {
            background-color: #00A85A;
        }
        &.EmAtendimento {
            background-color: #D5BA07;
        }
        &.EmAnalise {
            background-color: #07B2D5;
        }
        &.Finalizado {
            background-color: #D82121;
        }
      }
    }

    span.title {
      padding-bottom: 5px;
      font-size: 1rem;
    }
  }
  }

  .infoModalAnalise {
    padding: 32px;
    background-color: #F5F5F5;

    .sectionModalAnaliseRadio {
      display: flex;
      align-content: center;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .sectionModalAnaliseFooter {
      display: flex;
      gap: 12px;
      width: 70%;
    }

  }
`;
