import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  useJsApiLoader,
} from "@react-google-maps/api";

import { Container } from "./styles";
import message from "../../../services/message";

interface Props {
  routes?: any[];
  id?: number;
}

const Mapa: React.FC<Props> = ({ routes = [] }) => {
  const prevMarkersRef = useRef<any[]>([]);
  const [map, setMap] = useState<any>(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyACHeSJ1lgVWwZJ4Cl1UnQX2XWi3aPiS6A",
  });

  const mapOptions = {
    disableDefaultUI: true,
  };

  const [directions, setDirections] = useState<any>();

  useEffect(() => {
    if (map) addRoutes();
  }, [routes, map]);

  function removeDuplicates(array: any) {
    const result = [];
    const duplicates: any = {};

    for (let i = 0; i < array.length; i++) {
      const { lat, lng } = array[i];
      const key = lat + "," + lng;

      if (!duplicates[key]) {
        duplicates[key] = true;
        result.push(array[i]);
      }
    }
    return result;
  }
  let routesDuplicate = removeDuplicates(routes);

  function filterByLatAndLng(array: any, lat: any, lng: any) {
    return array.filter(
      (obj: any) => obj.lat.toFixed(3) === lat && obj.lng.toFixed(3) === lng
    );
  }

  const onMapLoad = async (map: any) => {
    setMap(map);
  };

  const checkAccessRoutes = async (msg: string) => {
    let bool = await message.confirm({
      title: "Rotas",
      text: msg,
      isCancel: false,
      button: "Voltar",
    });
    if (bool) {
      window.location.reload();
    }
  };

  console.log("ROUTES", routes);

  const addRoutes = () => {
    clearMarkers(prevMarkersRef.current);
    let directionsService = new google.maps.DirectionsService();
    const attId = routesDuplicate.map((row) => row.att);
    const codAtt = routesDuplicate.map((row) => row.cod);
    const statusAtt = routesDuplicate.map((row) => row.status);
    routesDuplicate = routesDuplicate.filter((route) => {
      return route.lat && route.lng;
    });

    let origin = routesDuplicate[0];
    let destination = routesDuplicate[routesDuplicate.length - 1];
    routesDuplicate.shift();
    routesDuplicate.pop();

    let waypoints = routesDuplicate.map((route) => {
      return {
        location: new google.maps.LatLng(route.lat, route.lng),
        stopover: true,
      };
    });

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          let legs = result?.routes[0].legs || [];

          var arrayButtons = filterByLatAndLng(
            routes,
            legs[0].start_location.lat().toFixed(3),
            legs[0].start_location.lng().toFixed(3)
          );
          var lat = legs[0].start_location.lat();
          var lng = legs[0].start_location.lng();
          var step = "1";
          var linkId = String(attId[0]);
          var address = legs[0].start_address;
          var statuss = String(statusAtt[0]);
          var distance = "";
          var duration = "";
          addMarker(
            lat,
            lng,
            step,
            address,
            distance,
            duration,
            linkId,
            statuss,
            arrayButtons
          );

          console.log("lat", legs.length);

            legs.length > 1 &&
              legs.map((leg, key) => {
                var arrayButtons = filterByLatAndLng(
                  routes,
                  leg.end_location.lat().toFixed(3),
                  leg.end_location.lng().toFixed(3)
                );
                var lat = leg.end_location.lat();
                var lng = leg.end_location.lng();
                var step = String(key + 2);
                var linkId =
                  attId.length > 1
                    ? String(attId[key + 1])
                    : String(attId[key]);
                var address = leg.end_address;
                var statuss = String(statusAtt[key]);
                var distance = leg.distance?.text;
                var duration = leg.duration?.text;
                var cod = codAtt;
                addMarker(
                  lat,
                  lng,
                  step,
                  address,
                  distance,
                  duration,
                  linkId,
                  statuss,
                  arrayButtons,
                  cod
                );
              });

          setDirections(result);
        } else {
          checkAccessRoutes("Há rotas muito distantes para o atendimento");
          // message.error("Há rotas muito distantes para o atendimento")
          console.error(`error fetching directions ${result?.routes}`);
        }
      }
    );
  };

  const addMarker = (
    lat: any,
    lng: any,
    step: string,
    address: string,
    distance?: string,
    duration?: string,
    linkId?: string,
    statuss?: string,
    arrayButtons?: any,
    cod?: any
  ) => {
    console.log("ARRAY", arrayButtons);
    console.log("step", step);

    var marker = new google.maps.Marker({
      position: {
        lat: lat,
        lng: lng,
      },
      map: map,
      icon: {
        path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
        fillColor: "#FFF",
        fillOpacity: 1,
        strokeColor: `${
          statuss === "Aberto"
            ? "#00A85A"
            : "#00A85A" || statuss === "Finalizado"
            ? "#D82121"
            : "#00A85A" || statuss === "Em Atendimento"
            ? "#D5BA07"
            : "#00A85A" || statuss === "Em Analise"
            ? "#07B2D5"
            : "#00A85A" || statuss === "Retornado"
            ? "#00A85A"
            : "#00A85A"
        }`,
        strokeWeight: 1,
        scale: 1,
        labelOrigin: new google.maps.Point(0, -28),
      },
      label: step,
    });

    prevMarkersRef.current.push(marker);

    const contentString = `${arrayButtons
      ?.map((row: any, key: any) => {
        return (
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          `<h1 id="firstHeading" class="firstHeading">Etapa ${step}</h1>` +
          `<h1 id="firstHeading" class="firstHeading">Codigo ${row.cod}</h1>` +
          '<div id="bodyContent">' +
          `<p><b>${address}</b>, ${
            distance ? distance + " (" + duration + ")" : ""
          }.</p>` +
          `<a class="buttonHistory" href="atendimentos/${row.att}/consultar2">Ver atendimento <span>&#8594;</span></a>` +
          "</div>" +
          "</div>"
        );
      })
      .join("")}`;
    // '<div id="content">' +
    // '<div id="siteNotice">' +
    // "</div>" +
    // `<h1 id="firstHeading" class="firstHeading">Etapa ${step}</h1>` +
    // '<div id="bodyContent">' +
    // `<p><b>${address}</b>, ${
    //   distance ? distance + " (" + duration + ")" : ""
    // }.</p>` +
    // `<a class="buttonHistory" href="atendimentos/${linkId}/consultar2">Ver atendimento <span>&#8594;</span></a>` +
    // "</div>" +
    // "</div>";

    var infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });
  };

  const clearMarkers = (markers: any) => {
    for (let m of markers) {
      m.setMap(null);
    }
  };

  return (
    <Container>
      <>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "400px" }}
            onLoad={onMapLoad}
            options={mapOptions}
          >
            <DirectionsRenderer
              options={{ suppressMarkers: true }}
              directions={directions}
            />
          </GoogleMap>
        )}
      </>
    </Container>
  );
};

export default Mapa;
