import Swal from 'sweetalert2'

const message = {

	success: function(message:string){
		Swal.fire({
			text: message,
			confirmButtonText: 'Continuar',
			customClass: {
				container: 'messageContainer messageOverlay',
				popup: 'messagePopup messageSuccess',
				title: 'messageTitle',
				confirmButton: 'btn btn-primary',
			}
        })
	},

	error: function(message:string){
		Swal.fire({
			text: message,
			confirmButtonText: 'Voltar',
			customClass: {
				container: 'messageContainer messageOverlay',
				popup: 'messagePopup messageError',
				title: 'messageTitle',
				confirmButton: 'btn btn-primary',
			}
        })
	},

	confirm: async function({
		title = "",
		text = "",
		button ="",
		isCancel = true
	})
	{
		let response = await Swal.fire({
			title: title,
			text: text ? text : 'Deseja confirmar a ação?',
			showCancelButton: isCancel, 
			confirmButtonText: button ? button : 'Confirmar',
			cancelButtonText: 'Cancelar',
			customClass: {
				container: 'messageContainer messageOverlay',
				popup: 'messagePopup messageConfirm',
				title: 'messageTitle',
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-danger-outline',
			}
		})

		return response.value;
	},

	// warning: function(message:string)
	// {
	// 	const Toast = Swal.mixin({
	// 		toast: true,
	// 		position: 'top',
	// 		showConfirmButton: false,
	// 		timer: 3000,
	// 		timerProgressBar: true,
	// 		didOpen: (toast) => {
	// 			toast.addEventListener('mouseenter', Swal.stopTimer)
	// 			toast.addEventListener('mouseleave', Swal.resumeTimer)
	// 		}
	// 	});

	// 	Toast.fire({
	// 		icon: 'warning',
	// 		title: message,
	// 		customClass: {
	// 			container: 'messageContainer',
	// 			popup: 'messagePopup messageWarning',
	// 			title: 'messageTitle',
	// 		}
	// 	});
	// },

	// delete: async function(title="Deseja realmente deletar?", text="Esse processo não poderá ser revertido", buttonConfirm="Deletar")
	// {
	// 	let dialog = await Swal.fire({
	// 		title: title, 
	// 		text: text,
	// 		icon: 'warning', 
	// 		showCancelButton: true, 
	// 		confirmButtonText: buttonConfirm, 
	// 		cancelButtonText: 'Cancelar'
	// 	});
		
	// 	return dialog.value;
	// },

	// popup: function(title:string, text="", button="")
	// {
	// 	Swal.fire({
	// 		title: title,
	// 		text: text,
	// 		confirmButtonText: button ? button : 'Finalizar',
	// 		customClass: {
	// 			container: 'messageContainer messageOverlay',
	// 			popup: 'messagePopup messageLogo',
	// 			title: 'messageTitle',
	// 			confirmButton: 'btn',
	// 		}
	// 	});
	// },

}

export default message