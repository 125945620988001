import { TextareaHTMLAttributes } from "react"
import {DivTextarea} from "./styles"

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string
}


export default function TextareaDefault({label, ...rest}:Props) {
    return (
        <DivTextarea className="divTextarea">
            <label>
                {label && <div className="label">{label}</div>}
                <textarea 
                    {...rest}
                />
            </label>
        </DivTextarea>
    )

}
