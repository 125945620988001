import styled, { css } from "styled-components"

interface Props {
    visible: boolean;
}

export const Overlay = styled.div<Props>`
    position: fixed;
    opacity: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    /* position: absolute; */
    visibility: hidden;
    opacity: 0;
    transition: all 0.7s;

    ${props => props.visible && css`
        visibility: visible;
        opacity: 1;
    `}
`;

export const Container = styled.div<Props>`
    /* position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.7s;

    ${props => props.visible && css`
        visibility: visible;
        opacity: 1;
    `} */

    transform: translateY(-30px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s;

    ${props => props.visible && css`
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
    `}
  
`