import React, { useEffect, useState } from "react"
import { Container } from "../../styles/layout"
import Input from "../../components/form/input/Input"
import { Link, useHistory } from "react-router-dom"
import { IconAdd, IconConfigs, IconEdit } from "../../components/layout/Svgs"
import { InputAddress } from "./styles"
import { IClienteEndereco } from "../../types/ClienteEndereco"
import Paginate from "../../components/paginate/Paginate"
import { AddressDivider } from './styles'
import api from "../../services/api"
import { useAuth } from "../../hooks/Auth"

const Listar: React.FC = (props) => {
    const { user } = useAuth()
    const history = useHistory();
    const [enderecos, setEnderecos] = useState<IClienteEndereco[]>([{}])

    const [filter, setFilter] = useState<any>({
        SearchCliente: "",
        Page: 1
    })

    const [paginate, setPaginate] = useState<any>({
        total: 0,
        lastPage: 1,
        perPage: 10,
        currentPage: 1,
    })

    const filterFunc = user.funcionalidades.filter((obj) => obj.Tela === "frmEndereco").map((row) => row.Nome)
    console.log("TE", filterFunc)
    useEffect(() => {
        api.get("/clientes-enderecos").then((response) => {
            setEnderecos([...response.data.result])
            setPaginate({...response.data.pagination})
        })
    }, [props])


    const onChangeInput = (key:string, value:string|number) => {
        filter[key] = value
        setFilter({...filter})
    }
    
    const handleFilter = async (e:React.FormEvent) => {
        e.preventDefault()
        onChangeInput("Page", 1)
        await getEnderecos()
    }

    function onClickReset() {
        getEnderecos()
    }

    const onClickPage = async (page:number) => {
        onChangeInput("Page", page)
        await getEnderecos()
    }
    
    const getEnderecos = async () => {
        let response = await api.get(`/clientes-enderecos?SearchCliente=${filter.SearchCliente}&Page=${filter.Page}`)
        setEnderecos([...response.data.result])
        setPaginate({...response.data.pagination})
    }

    const handleOnAddress = (id: any) => {
        history.push({
            pathname: `/enderecos/${id}/consultar`,
            state: { id }
        });
    }

    return (
        <Container>
            <h1>Endereços</h1>

            <form className="filter" onSubmit={handleFilter}>                
                <InputAddress>
                    <div>
                        <h2 className="subtitle">Buscar</h2>

                        <Input 
                            theme="alt" 
                            placeholder="Digite aqui o Código, Nome ou CNPJ do cliente"
                            name="SearchCliente" 
                            search={true}
                            value={filter.SearchCliente} 
                            onChange={onChangeInput} 
                            onClickReset={onClickReset} 
                        />
                        
                    </div>  

                    <AddressDivider />

                    {filterFunc.includes("INCLUIR ENDERECO") && (
                        <div className="actions">
                            <Link 
                                to={{
                                    pathname: "/enderecos/novo",
                                    state: { id: 'novo' }
                                }}  
                            className="btn btn-featured"><IconAdd /><span>Incluir</span></Link>
                        </div>
                    )}
                </InputAddress>
            </form>

            <hr />

            <div className="table-scroll">
                <table className="default">
                    <thead>
                        <tr>
                            <th className="sm">Consultar</th>
                            {filterFunc.includes("EDITAR ENDERECO") && (
                                <th className="sm">Editar</th>
                            )}
                            <th>Código do Cliente</th>
                            <th>Loja</th>
                            <th>Cliente</th>                        
                            <th>CNPJ</th>                        
                            <th>Nome do Local</th>                        
                            <th>CEP</th>                        
                            <th>Logradouro</th>                        
                            <th>Número</th>                        
                            <th>Complemento</th>                        
                            <th>Bairro</th>                        
                            <th>Zona</th>                        
                            <th>Cidade</th>                        
                            <th>UF</th>
                            <th>Ponto R.</th>
                        </tr>
                    </thead>

                    <tbody> 
                        {enderecos.map((row, index) => (
                        <tr key={index}>
                            <td className="center">
                                <Link to={{
                                    pathname: `/enderecos/${row.ClienteEnderecoId}/consultar`,
                                    state: { id: row.ClienteEnderecoId }
                                }}  
                                className="btn btn-link-secondary" 
                                title="Consultar">
                                    <IconEdit />
                                </Link>
                            </td>

                            {filterFunc.includes("EDITAR ENDERECO") && (
                                <td className="center">
                                    <Link to={{
                                        pathname: `/enderecos/${row.ClienteEnderecoId}`,
                                        state: { id: row.ClienteEnderecoId}
                                    }} className="btn btn-link-secondary" title="Editar"><IconConfigs /></Link>
                                </td>
                            )}
                            <td>{row.CodigoCliente}</td>
                            <td>{row.CodigoLojaCliente}</td>
                            <td>{row.NomeCliente}</td>
                            <td>{row.CnpjCliente}</td>
                            <td>{row.NomeLocal}</td>
                            <td>{row.CEP}</td>
                            <td>{row.Logradouro}</td>
                            <td>{row.Numero}</td>
                            <td>{row.Complemento}</td>
                            <td>{row.Bairro}</td>
                            <td>{row.Zona}</td>
                            <td>{row.Cidade}</td>
                            <td>{row.UF}</td>
                            <td>{row.PontoReferencia}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            <Paginate 
                total={paginate.total} 
                lastPage={paginate.lastPage} 
                perPage={paginate.perPage} 
                currentPage={filter.Page} 
                onClickPage={onClickPage}
            />

        </Container>
    )

}

export default Listar