import styled from "styled-components"

import bg from "./images/bg.png"

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    height: 100vh;
    background: url(${bg}) no-repeat;
    background-size: cover;

    form {
        width: 500px;
        padding: 50px;
        background-color: #fff;
        box-shadow: 2px 25px 25px #00000029;
        border-radius: 5px;
        text-align: center;

        .description{
            font-weight: 600;
            margin: 20px 0;
        }

        .divInput{
            width: 100%;
            text-align: left;
        }
    }
`