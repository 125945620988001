import styled from 'styled-components';

interface Props {
    inverter?: boolean;
}

export const ViewField = styled.div`
    /* margin-bottom: 14px; */
`;

export const TextTitle = styled.h2`
    font-size: 14px;
    font-weight: normal;
    color: var(--font);
    margin-bottom: 6px;
`;

export const TextSubTitle = styled.h3<Props>`
    font-size: 16px;
    font-weight: 600;
    color: var(--title);
`;
