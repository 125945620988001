import { Container, SectionHeaderParts, SectionListParts } from './styles';

interface DataList {
  CodigoPeca: string;
  DescricaoPeca: string;
}

interface ListPartsProps {
  dataList: DataList[]
}

export function ListParts({ dataList }: ListPartsProps){
  return (
    <Container>
      <SectionHeaderParts>
        <h2 className='headerParts'>Peças</h2>
        <h2 className='headerParts'>Descrição</h2>
      </SectionHeaderParts>

      {dataList.map((row, key) => (
        <SectionListParts key={key}>
          <h3 className='parts'>{row.CodigoPeca}</h3>
          <h4 className='descParts'>{row.DescricaoPeca}</h4>
        </SectionListParts>
      ))}

    </Container>
  );
}