import React, { InputHTMLAttributes, useRef, useState, useCallback } from 'react';
import { IconClose, IconDate } from '../../layout/Svgs';
import { MaskDate } from './Mask';

import { Container, GroupInput } from './styles';


interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  mask: string;
  label: string;
  handleOnClearDate: () => void;
}

export default function InputDate({ name, handleOnClearDate, label, value, mask, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isField, setIsField] = useState(false);

  const handleIsFocus = useCallback(() => {
  }, []);

  const handleInputBlur = useCallback(() => {

    setIsField(!!inputRef.current?.value);
  }, []);

  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (mask === 'date') {
      e.currentTarget.value = MaskDate(e.currentTarget.value)
    }
    return
  }, [mask])

  return (
    <Container>

      <label htmlFor={label}>{label}</label>

      <GroupInput>
        <IconDate />

        <span>{value ? value : '99-99-9999'}</span>
        
        <input 
          type="date" 
          className="datepicker-input"
          onFocus={(handleIsFocus)}
          onBlur={(handleInputBlur)}
          onKeyUp={handleKeyUp}
          id={label}
          ref={inputRef}
          {...rest} 
        />

        <button 
          onClick={handleOnClearDate}
          className={`buttonDate ${!!isField ? 'isDisabled' : ''}`}
        >
          <IconClose />
        </button>
      </GroupInput>

    </Container>
  )
}
