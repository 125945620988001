import React, { useState } from "react"
import BtnSearch from "../../../components/form/btnSearch/BtnSearch"
import { useAtendimento } from "../../../contexts/AtendimentoContext"
import { IClienteEndereco } from "../../../types/ClienteEndereco"
import ModalEnderecos from "./modal/ModalEnderecos"

interface Props {
    readOnly?: boolean
}

const PesquisarEndereco: React.FC<Props> = ({
    readOnly = false
}) => {
    
    const [modal, setModal] = useState<boolean>(false)

    const {atendimento, setAtendimento} = useAtendimento()

    const onClickEndereco = (endereco:IClienteEndereco) => {

        atendimento.AtendimentoEndereco = {
            ClienteEnderecoId: endereco.ClienteEnderecoId,
            NomeLocal: endereco.NomeLocal,
            CEP: endereco.CEP,
            Logradouro: endereco.Logradouro,
            Numero: endereco.Numero,
            Complemento: endereco.Complemento,
            Bairro: endereco.Bairro,
            Cidade: endereco.Cidade,
            UF: endereco.UF,
            Zona: endereco.Zona,
            PontoReferencia: endereco.PontoReferencia,
            Latitude: endereco.Latitude,
            Longitude: endereco.Longitude
        }
        setAtendimento({...atendimento})
        setModal(false)
    }

    const onClickReset = () => {
        atendimento.AtendimentoEndereco = {}
        setAtendimento({...atendimento})
    }

    return (
    <>
        <div className="container-infos">
            <p className="title">Endereço</p>

            {!readOnly &&
            <BtnSearch 
                onReset={onClickReset} 
                onClick={() => setModal(true)} 
                label="Local / Endereço" 
                placeholder="Clique aqui para pesquisar" 
                value={atendimento.AtendimentoEndereco?.NomeLocal}
            />
            }

            { atendimento.AtendimentoEndereco?.NomeLocal && 
            <div className="list-infos">

                {readOnly &&
                <div className="info">
                    <span className="title">Local / Endereço</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.NomeLocal}</span>
                </div>
                }

                <div className="info">
                    <span className="title">CEP</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.CEP}</span>
                </div>

                <div className="info">
                    <span className="title">Logradouro</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.Logradouro}</span>
                </div>

                <div className="info">
                    <span className="title">Número</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.Numero}</span>
                </div>

                <div className="info">
                    <span className="title">Bairro</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.Bairro}</span>
                </div>

                <div className="info">
                    <span className="title">Zona</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.Zona}</span>
                </div>

                <div className="info">
                    <span className="title">Cidade</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.Cidade}</span>
                </div>

                <div className="info">
                    <span className="title">UF</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.UF}</span>
                </div>

                <div className="info">
                    <span className="title">Ponto de referência</span>
                    <span className="value">{atendimento.AtendimentoEndereco?.PontoReferencia}</span>
                </div>

            </div>
            }

        </div>

        <ModalEnderecos
            active={modal}
            setActive={setModal}
            onClick={onClickEndereco}
        />
    </>
    )
}

export default PesquisarEndereco