import React, { useState } from "react"
import Btn from "../../../../components/form/btn/Btn"
import Input from "../../../../components/form/input/Input"
import protheus from "../../../../services/protheus"
import { ICliente } from "../../../../types/Cliente"
import { ContainerModal } from "../../styles"

interface Props {
    active: boolean
    setActive: (bool:boolean) => any
    onClick: (cliente:ICliente) => void
}

const ModalClientes:React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)
    const [noResult, setNoResult] = useState(false)
    const [filter, setFilter] = useState({
        name: "",
        CNPJ: ""
    })

    const [lista, setLista] = useState<ICliente[]>([])

    const onChangeFilter = (key:string, value:string|boolean) => {
        let input:any = filter
        input[key] = value
        setFilter({...input})
    }

    const handleSearch = async (e:React.FormEvent) => {
        
        e.preventDefault()
        try {

            setNoResult(false)
            setLoading(true)
            let response = await protheus.get(`clients?limit=1000&name=${filter.name}&CNPJ=${filter.CNPJ}`)
            let array = response.data.result
            if( !array.length ) setNoResult(true)
            console.log("address", response.data.result)
            setLista([...array])
            setLoading(false)
            
        } catch(err){
            console.log(err)
            setLoading(false)
            setNoResult(true)
        }
        
    }

    return (
        <ContainerModal className={` ${props.active === true ? "active" : ""}`}>

            <button type="button" className="modalOverlay" onClick={() => props.setActive(false)}></button>

            <div className="modal-box">

                <div className="head-form">

                    <form onSubmit={handleSearch}>
                        
                        <Input
                            label="Nome do Cliente"
                            placeholder="Digite aqui..."
                            value={filter.name}
                            name="name"
                            onChange={(name, value) => onChangeFilter(name, value)}
                            theme="alt"
                        />

                        <Input
                            label="CNPJ do Cliente"
                            placeholder="Digite aqui..."
                            value={filter.CNPJ}
                            name="CNPJ"
                            mask="cnpj"
                            onChange={(name, value) => onChangeFilter(name, value)}
                            theme="alt"
                            maxLength={18}
                        />

                        <Btn text="Buscar" loading={loading} className="btn btn-featured" />

                        <button type="button" className="btn" onClick={() => props.setActive(false)}>
                            <span>Fechar</span>
                        </button>

                    </form>
                    
                </div>

                <div className="content-table">
                    {noResult === true && <p>Não foi encontrado nenhum resultado para a pesquisa.</p>}

                    { lista.length > 0 && 
                    <table className="default">
                        <thead>
                            <tr>
                                <th>Código do Cliente</th>
                                <th>Loja</th>
                                <th>Cliente</th>
                                <th>CNPJ</th>
                                <th>Tipo</th>
                            </tr>
                        </thead>
                        <tbody>
                            { lista.map((row, key) =>
                                <tr key={key} onClick={() => props.onClick(row)}>
                                    <td>{row.CodigoCliente}</td>
                                    <td>{row.CodigoLojaCliente}</td>
                                    <td>{row.NomeCliente}</td>
                                    <td>{row.CNPJCliente}</td>
                                    <td>{row.TipoContrato}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    }
                </div>

            </div>
        </ContainerModal>
    )
}

export default ModalClientes