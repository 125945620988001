import React, { useEffect, useState } from "react";
import { Container } from "../../styles/layout";
import { Link } from "react-router-dom";
import { DadosTecnico, Divider, ProprietarioVeiculo } from "./styles";
import { Radio } from "../../components/form/__Radio/Radio";
import ModalTecnicos from "./components/modal/ModalTecnicos";
import message from "../../services/message";
import { useHistory } from "react-router";
import { IVeiculo } from "../../types/Veiculo";
import Input from "../../components/form/input/Input";
import Checkbox from "../../components/form/checkbox/Checkbox";
import Btn from "../../components/form/btn/Btn";
import { IUser } from "../../types/User";
import api from "../../services/api";

interface Props {
    match: any
}

const Consultar: React.FC<Props> = (props) => {
    const history = useHistory<any>()
    const [DTO, setDTO] = useState<IVeiculo>({})

    useEffect(() => {
        api.get(`veiculos/${history.location.state.id}`).then(response => {
            setDTO({...response.data.result})
        })

    }, [props])

    const onChangeInput = (key:string, value:string|boolean) => {
    }

    return (
        <Container>
            <h1>Veículos</h1>               

            <ProprietarioVeiculo>
                <form>
                    <div className="content">
                        <h2 className="subtitle">Proprietário do Veículo</h2>
                        <tbody>
                            <tr>                                    
                                <td>
                                    <Radio 
                                        onChange={(key, value) => onChangeInput(key, "Amazoncopy")}
                                        value={DTO.TipoProprietario === 'Amazoncopy' ? true : false}
                                        name="TipoProprietario"
                                        placeholder="Amazoncopy"
                                        readOnly={true}
                                    />
                                </td>
                                <td>
                                    <Radio 
                                        onChange={(key, value) => onChangeInput(key, "Técnico")}
                                        value={DTO.TipoProprietario === 'Técnico' ? true : false}
                                        name="TipoProprietario"
                                        placeholder="Técnico"
                                        readOnly={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                        
                        { DTO.UsuarioId &&
                        <DadosTecnico>
                        <div className='technician'>
                            <div>
                                <p>Técnico</p>
                                <span>{DTO.UsuarioNome}</span>
                            </div>                               
                            <Divider />
                            <div>
                                <p>Email do Técnico</p>
                                <span>{DTO.UsuarioEmail}</span>
                            </div>
                        </div>
                        </DadosTecnico>
                        }
                    </div>

                    <div className="content" style={{marginTop: "20px"}}>
                        <h2 className="subtitle">Dados do Veículo</h2>
                    
                        <Input placeholder="Digite aqui" label="Placa do Veículo" name="Placa" value={DTO.Placa} onChange={onChangeInput} maxLength={10} readOnly={true} />

                        <Checkbox                     
                            label="Ativo"
                            placeholder="Sim"
                            name="Ativo"
                            value={DTO.Ativo ? true : false}
                            onClick={onChangeInput}
                            readOnly={true}
                        />  
                    </div>
                    
                    <div className="actions">
                        <Link to="/veiculos" className="btn btn-danger">Voltar</Link>
                    </div>
                    
                </form>                 
            </ProprietarioVeiculo>  

        </Container>
        
    )
}

export default Consultar