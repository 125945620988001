import React from "react"
import { useState } from "react"
import Input from "../../components/form/input/Input"
import {Container} from "./styles"
import logo from "../../assets/images/logo.svg"
import { Link, useHistory } from "react-router-dom"
import Message from "../../services/message"
import Btn from "../../components/form/btn/Btn"
import { useAuth } from "../../hooks/Auth"

interface IAuth {
    Email: string
    Senha: string
}

const Login: React.FC = (props) => {
    const { signIn } = useAuth();
    const history = useHistory()

    const [loading, setLoading] = useState<boolean>(false)

    const [login, setLogin] = useState<IAuth>({
        Email: "",
        Senha: ""
    })

    const onChangeLogin = (key:string, value:string) => {
        if( key === "Email" ) login.Email = value
        if( key === "Senha" ) login.Senha = value
        setLogin({...login})
    }

    const handleSubmit = async (e: React.FormEvent) => {
        try {
            e.preventDefault()
            setLoading(true)

            await signIn({
                email: login.Email,
                senha: login.Senha,
            });

            history.push('/home')

            setLoading(false)

        } catch(err: any){
            setLoading(false)
            console.log("ERROR", err)
            Message.error(String(err))
        }
    }

    return (
        <Container>

            <form onSubmit={handleSubmit}>

                <img src={logo} alt="Amazoncopy" />

                <p className="description">Bem vindo!</p>

                <Input label="Email" name="Email" placeholder="Digite aqui seu email" value={login.Email} onChange={onChangeLogin} />

                <Input label="Senha" name="Senha" placeholder="Digite aqui sua senha" value={login.Senha} onChange={onChangeLogin} type="password" />

                <Link to="/recuperar-senha" className="btn btn-link">Esqueci a senha</Link>

                <Btn className="btn btn-primary" text="Entrar" loading={loading} />

            </form>

        </Container>
    )
}

export default Login