
import {
  ViewField,
  TextSubTitle,
  TextTitle,
} from "./styles";

type PropsText = {
  title?: string;
  subTitle?: string | number;
  descriptionTwo?: string;
};

export default function FieldTextDefault({
  title,
  subTitle,
  descriptionTwo,
}: PropsText) {

  return (
    <ViewField>
      {/* {(!title || title !== null) ? <TextTitle>{title}</TextTitle> : null}
      {(!subTitle || subTitle !== null) ? <TextSubTitle inverter={inverter}>{subTitle}</TextSubTitle> : null}
      {(!description || description !== null) ? <TextDescription inverter={inverter}>{description}</TextDescription> : null}
      {(!descriptionTwo || descriptionTwo !== null) ? <TextDescription inverter={inverter}>{descriptionTwo}</TextDescription> : null} */}

      {title && <TextTitle>{title ?? ""}</TextTitle>}
      {subTitle && <TextSubTitle>{subTitle ?? ""}</TextSubTitle>}
      {descriptionTwo && <TextSubTitle>{descriptionTwo?? ""}</TextSubTitle>}
     
    </ViewField>
  );
}
