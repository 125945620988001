import { useState, useEffect } from "react";
import moment from "moment";
import InputDate from "../../components/form/InputDate";
import Select from "../../components/form/select/Select";
import ModalDefault from "../../components/ModalDefault";
import api from "../../services/api";
import message from "../../services/message";
import { Container } from "../../styles/layout";
import {
  IconArrow,
  IconDetails,
  IconFilter,
  IconRemove,
} from "../../components/layout/Svgs";

import {
  HeaderPonto,
  SectionFilterPonto,
  SectionTablePonto,
  SectionDetailsPonto,
  SectionRegisterPonto,
  ContainerPonto,
} from "./styles";
import ButtonDownload from "../../components/form/ButtonDownload/ButtonDownload";

interface PropsTechnical {
  Nome: string;
  Id: number;
}

interface PropsDataPonto {
  DataHora: string;
  Nome: string;
  Entrada: string;
  InicioAlmoco: string;
  TerminoAlmoco: string;
  Saida: string;
  UsuarioId: number;
  id: number;
}

interface DetailsProps {
  PontoId: number;
  UsuarioId: number;
  VeiculoId: number;
  DataHora: string;
  Etapa: string;
  KMOdometro: number;
  Latitude: number;
  Longitude: number;
  CaminhoFoto: string;
  Nome: string;
  Placa: string;
}

interface PropsDetails {
  Nome: string;
  UsuarioId: number;
  Data: string;
  Pontos: DetailsProps[]
}

export default function Ponto() {
  const actualDate = moment(new Date()).utc().format("YYYY-MM-DD");
  const [technical, setTechnical] = useState<PropsTechnical[]>([]);
  const [load, setLoad] = useState(false);
  const [modalPonto, setModalPonto] = useState(false);
  const [dataponto, setDataPonto] = useState<PropsDataPonto[]>([]);
  const [datailsPonto, setDetailsPonto] = useState<PropsDetails[]>([]);
  const [formData, setFormData] = useState({
    dataInicial: actualDate,
    dataFinal: actualDate,
    tecnico: "",
  });

  const filterDetailsPonto = datailsPonto.map((row) => row.Pontos)

  useEffect(() => {
    getTechnical();
  }, []);

  async function getTechnical() {
    api.get('usuarios').then((resp) => {
      const filterPefil = resp.data.result.filter((obj: any) => obj.PerfilId === 1)
      setTechnical(filterPefil)
    }).catch((error) => {
      console.log('ERROR =>>', error)
    })
  }

  function handleOnChangeDate(name: string, value: string) {
    if (!value) {
      setFormData({ ...formData, [name]: actualDate });
    } else {
      const formatDate = moment(value).utc().format("YYYY-MM-DD");
      setFormData({ ...formData, [name]: formatDate });
    }
  }

  function handleOnChange(name: any, value: any) {
    setFormData({ ...formData, [name]: value });
  }

  function handleOnClearDate(name: string) {
    setFormData({ ...formData, [name]: actualDate });
  }

  async function getPoint() {
    try {
      setLoad(true);
      const response = await api.get(
        `ponto-listar-agruparpordia?dataInicial=${formData.dataInicial}&dataFinal=${formData.dataFinal}&UsuarioId=${formData.tecnico}`,
      );
      
      setDataPonto(response.data.result);
      setLoad(false);
    } catch (error: any) {
      message.error(error)
    }
  }

  async function handleOnDetailsPonto(id: any) {
    setModalPonto(!modalPonto);

    try {
      const response = await api.get(`ponto-listar?dataInicial=${formData.dataInicial}&dataFinal=${formData.dataFinal}&UsuarioId=${id}`)
      setDetailsPonto(response.data.result)
    } catch(error: any) {
      message.error(error);
    }
  }

  const headers = [
    { label: "DataHora", value: "Data" },
    { label: "Nome", value: "Nome" },
    { label: "Entrada", value: "Entrada" },
    { label: "InicioAlmoco", value: "InicioAlmoco" },
    { label: "TerminoAlmoco", value: "TerminoAlmoco" },
    { label: "Saida", value: "Saida" }
  ] as any;

  return (
    <Container>
      <HeaderPonto>
        <h1 className="titlePonto">Ponto</h1>
      </HeaderPonto>

      <SectionFilterPonto>
        <Select 
          theme="alt"
          placeholder="Pesquise aqui..."
          label="Técnico"
          name="tecnico"
          value={formData.tecnico}
          onChange={(name, value) => handleOnChange(name, value)}
          onClickReset={() => {}}
          styles={{ width: "100%", margin: "0px" }}
        >
          {technical.map((row) =>
            <option key={row.Id} value={row.Id}>{row.Nome}</option>
          )}
        </Select>

        <InputDate
          mask="date"
          label="Data Inicial"
          name="Data Inicial"
          onChange={(e) => handleOnChangeDate("dataInicial", e.target.value)}
          value={formData.dataInicial}
          maxLength={10}
          handleOnClearDate={() => handleOnClearDate("dataInicial")}
        />

        <InputDate
          mask="date"
          label="Data Final"
          name="Data Final"
          onChange={(e) => handleOnChangeDate("dataFinal", e.target.value)}
          value={formData.dataFinal}
          maxLength={10}
          handleOnClearDate={() => handleOnClearDate("dataFinal")}
        />

        <ButtonDownload filename={`Relatório de Ponto`} headers={headers} data={dataponto as any}/>

        <button
          style={{ margin: "0px", width: "100px", letterSpacing: "1px" }}
          className="btn btn-secondary"
          onClick={getPoint}
        >
          Filtrar
          <IconFilter />
        </button>
      </SectionFilterPonto>

      <SectionTablePonto>
        <table className="default">
          <thead>
            <tr>
              <th>Data</th>
              <th>Nome do Funcionario</th>
              <th>Entrada</th>
              <th>Inicio Almoço</th>
              <th>Retorno Almoço</th>
              <th>Saida</th>
              <th>Detalhes</th>
            </tr>
          </thead>

          <tbody>
            {dataponto.map((row) => (
              <tr key={row.id}>
                <td>{moment(row.DataHora).utc().format("DD/MM/YYYY")}</td>
                <td>{row.Nome}</td>
                <td>{moment(row.Entrada).isValid() ? moment(row.Entrada).utc().format("HH:MM") + "h" : '-'}</td>
                <td>{moment(row.InicioAlmoco).isValid() ? moment(row.InicioAlmoco).utc().format("HH:MM") + "h" : '-'}</td>
                <td>{moment(row.TerminoAlmoco).isValid() ? moment(row.TerminoAlmoco).utc().format("HH:MM") + "h" : '-'}</td>
                <td>{moment(row.Saida).isValid() ? moment(row.Saida).utc().format("HH:MM") + "h" : '-'}</td>
                <td>
                  <button
                    className="btn"
                    onClick={() => handleOnDetailsPonto(row.UsuarioId)}
                  >
                    <IconDetails />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </SectionTablePonto>

      <ModalDefault
        id="modalDefault"
        onClose={() => setModalPonto(!modalPonto)}
        visible={modalPonto}
      >
        <ContainerPonto>
          <SectionDetailsPonto>
            <h1>Detalhes do Ponto</h1>

            <div className="fieldGroupPonto">
              <div className="fieldPonto">
                <h3>Tecnico</h3>
                <h4>{datailsPonto[0]?.Nome}</h4>
              </div>

              <div className="fieldPonto">
                <h3>Data</h3>
                <h4>{datailsPonto[0]?.Data}</h4>
              </div>
            </div>
          </SectionDetailsPonto>

          {filterDetailsPonto[0]?.map((row:any) => (
            
            <SectionRegisterPonto key={row.PontoId}>
              <div className="headerModalPonto">
                <IconArrow />
                <h2>{row.Etapa}</h2>
              </div>

              <div className="dataPonto">
                <div className="sectionImagePonto">
                  <h3>Foto</h3>
                  <div className="imagePonto">
                    <img
                      src={`data:image/jpeg;base64,${row.CaminhoFoto}`}
                      alt="Ponto"
                    />
                  </div>
                </div>


                <div className="sectionInfoPonto">
                  <div className="fieldGroupPontoHeader">
                    <div className="fieldPonto">
                      <h3>Hora</h3>
                      <h4>{moment(row.DataHora).utc(true).utcOffset("+03:00").format("HH:mm") + "h"}</h4>
                    </div>

                    <div className="fieldPonto">
                      <h3>KM</h3>
                      <h4>{row.KMOdometro}</h4>
                    </div>

                    <div className="fieldPonto">
                      <h3>Placa</h3>
                      <h4>{row.Placa}</h4>
                    </div>
                  </div>

                  <div className="fieldGroupPonto">
                    <div className="fieldPonto">
                      <h3>CEP</h3>
                      <h4>099990-000</h4>
                    </div>

                    <div className="fieldPonto">
                      <h3>Logradouro</h3>
                      <h4>Rua logo ali</h4>
                    </div>

                    <div className="fieldPonto">
                      <h3>Numero</h3>
                      <h4>123</h4>
                    </div>

                    <div className="fieldPonto">
                      <h3>Bairro</h3>
                      <h4>perto donde</h4>
                    </div>
                  </div>

                  <div className="fieldGroupPonto">
                    <div className="fieldPonto">
                      <h3>Cidade</h3>
                      <h4>Velha</h4>
                    </div>

                    <div className="fieldPonto">
                      <h3>Complemento</h3>
                      <h4>Zona suna</h4>
                    </div>

                    <div className="fieldPonto">
                      <h3>UF</h3>
                      <h4>AM</h4>
                    </div>
                  </div>
                </div>
              </div>
            </SectionRegisterPonto>
          ))}

        <button
          className="buttonClosePreview"
          onClick={() => setModalPonto(!modalPonto)}
        >
          <IconRemove width="22px" height="22px" />
          Fechar modal
        </button>
        </ContainerPonto>

      </ModalDefault>
    </Container>
  );
}
