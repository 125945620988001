import axios from "axios";
import React, { useEffect, useState } from "react";
import Checkbox from "../../../components/form/checkbox/Checkbox";
import Input from "../../../components/form/input/Input";
import Select from "../../../components/form/select/Select";
import Textarea from "../../../components/form/textarea/Textarea";
import { useAtendimento } from "../../../contexts/AtendimentoContext";
import api from "../../../services/api";
import { IAtendimento } from "../../../types/Atendimento";
import { ContainerTipo } from "../styles";
import PesquisarCliente from "./PesquisarCliente";
import PesquisarEndereco from "./PesquisarEndereco";

interface Props {
  readOnly?: boolean;
  atendimento: IAtendimento;
  handleOnChange: (key: any, value: any) => void;
  onChangeAddress: (name: any, value: any) => void;
}

const Entrega: React.FC<Props> = ({
  readOnly = false,
  atendimento,
  handleOnChange,
  onChangeAddress,
}) => {
  // const {atendimento, setAtendimento} = useAtendimento()

  const [categorias, setCategorias] = useState<any>([]);

  async function handleOnAddress(key: any, value: any) {
    handleOnChange(key, value);
  }

  useEffect(() => {
    api.get("/categoria-entrega").then((response) => {
      setCategorias([...response.data.result]);
    });
  }, []);

  // const onChangeInput = (key:string, value:any) => {
  //     let input:any = atendimento
  //     input[key] = value
  //     setAtendimento({...input})
  // }

  const onChangeEnderecoInput = async (key: string, value: any) => {
    let input: any = atendimento;

    if (key === "CEP" && value.length === 8) {
      let response = await axios.get(
        `https://brasilapi.com.br/api/cep/v2/${value}`,
      );
      if (response.data.cep) {
        input.AtendimentoEndereco.Logradouro = response.data.street;
        input.AtendimentoEndereco.Bairro = response.data.neighborhood;
        input.AtendimentoEndereco.Cidade = response.data.city;
        input.AtendimentoEndereco.UF = response.data.state;
        input.AtendimentoEndereco.Latitude =
          response.data.location.coordinates.latitude;
        input.AtendimentoEndereco.Longitude =
          response.data.location.coordinates.longitude;
      }
    }

    if (!input.AtendimentoEndereco) input.AtendimentoEndereco = {};
    input.AtendimentoEndereco[key] = value;
    onChangeAddress(key, value);
  };

  return (
    <ContainerTipo className={readOnly ? "readOnly" : ""}>
      <PesquisarCliente readOnly={readOnly} />

      {atendimento.CodigoCliente ? (
        <PesquisarEndereco readOnly={readOnly} />
      ) : (
        <div className="container-infos">
          <p className="title">Endereço</p>
          <Input
            label="Nome do Local *"
            placeholder="Digite aqui..."
            name="NomeLocal"
            value={atendimento.AtendimentoEndereco?.NomeLocal}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
          />
          <Input
            label="CEP *"
            placeholder="Digite aqui..."
            name="CEP"
            value={atendimento.AtendimentoEndereco?.CEP}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
            mask="integer"
            maxLength={8}
          />
          <Input
            label="Logradouro *"
            placeholder="Digite aqui..."
            name="Logradouro"
            value={atendimento.AtendimentoEndereco?.Logradouro}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
          />
          <Input
            label="Número *"
            placeholder="Digite aqui..."
            name="Numero"
            value={atendimento.AtendimentoEndereco?.Numero}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
          />
          <Input
            label="Complemento"
            placeholder="Digite aqui..."
            name="Complemento"
            value={atendimento.AtendimentoEndereco?.Complemento}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
          />
          <Input
            label="Bairro *"
            placeholder="Digite aqui..."
            name="Bairro"
            value={atendimento.AtendimentoEndereco?.Bairro}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
          />
          <Input
            label="Cidade *"
            placeholder="Digite aqui..."
            name="Cidade"
            value={atendimento.AtendimentoEndereco?.Cidade}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
          />
          <Input
            label="UF *"
            placeholder="Digite aqui..."
            name="UF"
            value={atendimento.AtendimentoEndereco?.UF}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
            maxLength={2}
          />
          <Input
            label="Zona"
            placeholder="Digite aqui..."
            name="Zona"
            value={atendimento.AtendimentoEndereco?.Zona}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
          />
          <Input
            label="Ponto de Referência"
            placeholder="Digite aqui..."
            name="PontoReferencia"
            value={atendimento.AtendimentoEndereco?.PontoReferencia}
            onChange={onChangeEnderecoInput}
            readOnly={readOnly}
          />
        </div>
      )}

      {atendimento.AtendimentoEndereco?.NomeLocal && (
        <div className="container-infos">
          <p className="title">Dados Adicionais</p>

          <Input
            label="Complemento do Local *"
            placeholder="Digite aqui..."
            name="ComplementoLocal"
            value={atendimento.ComplementoLocal}
            onChange={handleOnAddress}
            readOnly={readOnly}
          />

          <Textarea
            label="Observação geral para o atendimento"
            placeholder="Digite aqui..."
            name="ObservacaoParaAtendimento"
            value={atendimento.ObservacaoParaAtendimento}
            onChange={handleOnAddress}
            readOnly={readOnly}
          />

          <Select
            label="Categoria da Entrega *"
            name="CategoriaEntregaId"
            value={atendimento.CategoriaEntregaId}
            onChange={handleOnAddress}
            readOnly={readOnly}
          >
            <option value="">Selecione aqui</option>
            {categorias.map((categoria: any, key: any) => (
              <option key={key} value={categoria.CategoriaEntregaId}>
                {categoria.CategoriaEntrega}
              </option>
            ))}
          </Select>

          <Checkbox
            label="Emergencial *"
            placeholder="Sim"
            name="Emergencial"
            value={atendimento.Emergencial ? true : false}
            onClick={handleOnAddress}
            readOnly={readOnly}
          />
          <Input
            label="Nome do Documento"
            placeholder="Digite aqui..."
            name="NomeDocumento"
            value={atendimento.NomeDocumento}
            onChange={handleOnAddress}
            readOnly={readOnly}
          />
          <Input
            label="Nº do Documento"
            placeholder="Digite aqui..."
            name="NroDocumento"
            value={atendimento.NroDocumento}
            onChange={handleOnAddress}
            readOnly={readOnly}
          />
        </div>
      )}
    </ContainerTipo>
  );
};

export default Entrega;
