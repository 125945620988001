import React from "react";
import Checkbox from "../../../components/form/checkbox/Checkbox";
import Input from "../../../components/form/input/Input";
import Select from "../../../components/form/select/Select";
import Textarea from "../../../components/form/textarea/Textarea";
import { useAtendimento } from "../../../contexts/AtendimentoContext";
import { IAtendimento } from "../../../types/Atendimento";
import { ContainerTipo } from "../styles";
import PesquisarEndereco from "./PesquisarEndereco";
import PesquisarMaquina from "./PesquisarMaquina";
import SelecionarSoftware from "./SelecionarSoftware";

interface Props {
  readOnly?: boolean;
  atendimento: IAtendimento;
  handleOnChange: (key: any, value: any) => void;
}

const Instalacao: React.FC<Props> = ({
  readOnly = false,
  atendimento,
  handleOnChange,
}) => {
  // const {atendimento, setAtendimento} = useAtendimento()

  // const onChangeInput = (key:string, value:any) => {
  //     let input:any = atendimento
  //     input[key] = value
  //     setAtendimento({...input})
  // }

  return (
    <ContainerTipo className={readOnly ? "readOnly" : ""}>
      <PesquisarMaquina readOnly={readOnly} />

      {atendimento.NroContrato && <PesquisarEndereco readOnly={readOnly} />}

      {atendimento.AtendimentoEndereco?.NomeLocal && (
        <div className="container-infos">
          <p className="title">Dados Adicionais</p>

          <Input
            label="Complemento do Local *"
            placeholder="Digite aqui..."
            name="ComplementoLocal"
            value={atendimento.ComplementoLocal}
            onChange={handleOnChange}
            readOnly={readOnly}
          />

          <div
            className="fieldGroup"
            style={{ display: "flex", gap: "10px", margin: "15px 0" }}
          >
            <Checkbox
              label="Retorno"
              placeholder="Sim"
              name="Retorno"
              value={atendimento.Retorno}
              onClick={handleOnChange}
              readOnly={readOnly}
            />

            {atendimento.Retorno && (
              <Input
                label="Código Atendimento Anterior"
                placeholder="Digite aqui..."
                name="CodigoAtendimentoAnterior"
                value={atendimento.CodigoAtendimentoAnterior}
                onChange={handleOnChange}
                mask="integer"
                maxLength={8}
                readOnly={readOnly}
                styles={{ margin: "0", flex: "1" }}
              />
            )}
          </div>

          <Textarea
            label="Observação geral para o atendimento"
            placeholder="Digite aqui..."
            name="ObservacaoParaAtendimento"
            value={atendimento.ObservacaoParaAtendimento}
            onChange={handleOnChange}
            readOnly={readOnly}
          />

          <Input
            label="Nome do Solicitante *"
            placeholder="Digite aqui..."
            name="NomeSolicitante"
            value={atendimento.NomeSolicitante}
            onChange={handleOnChange}
            readOnly={readOnly}
          />
          <Input
            label="Email do Solicitante *"
            placeholder="Digite aqui..."
            name="EmailSolicitante"
            value={atendimento.EmailSolicitante}
            onChange={handleOnChange}
            readOnly={readOnly}
          />
          <Input
            label="Telefone do Solicitante *"
            placeholder="Digite aqui..."
            name="TelefoneSolicitante"
            value={atendimento.TelefoneSolicitante}
            onChange={handleOnChange}
            mask="phone"
            readOnly={readOnly}
          />
          <Input
            label="Setor do Solicitante *"
            placeholder="Digite aqui..."
            name="SetorSolicitante"
            value={atendimento.SetorSolicitante}
            onChange={handleOnChange}
            readOnly={readOnly}
          />

          <Select
            label="Rede Elétrica *"
            name="RedeEletrica"
            value={atendimento.RedeEletrica}
            onChange={handleOnChange}
            readOnly={readOnly}
          >
            <option value="">Selecione aqui...</option>
            <option value="110">110V</option>
            <option value="220">220V</option>
          </Select>

          <Checkbox
            label="Tem estabilizador? *"
            placeholder="Sim"
            name="PossuiEstabilizador"
            value={atendimento.PossuiEstabilizador ? true : false}
            onClick={handleOnChange}
            readOnly={readOnly}
          />

          {atendimento.PossuiEstabilizador === true && (
            <Input
              label="Número de Série"
              placeholder="Digite aqui..."
              name="NroSerieEstabilizador"
              value={atendimento.NroSerieEstabilizador}
              onChange={handleOnChange}
              readOnly={readOnly}
            />
          )}

          <SelecionarSoftware readOnly={readOnly} />
        </div>
      )}
    </ContainerTipo>
  );
};

export default Instalacao;
