import React from "react"
import { ContainerModal } from "./styles"

interface Props {
    className?:string
    active:boolean
    setActive(bool:boolean):any
}

const Modal:React.FC<Props> = (props) => {
    return (
        <ContainerModal className={`${props?.className} ${props.active === true ? "active" : ""}`}>
            <button type="button" className="modalOverlay" onClick={() => props.setActive(false)}></button>
            <div className="modalContent">
                {props.children}
            </div>
        </ContainerModal>
    )
}

export default Modal