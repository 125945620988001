import styled from "styled-components"

export const DivTextarea = styled.div`
    display: block;
    /* margin: 15px 0; */
    label{
        display: block;
        width: 100%;
        font-size: 90%;
        color: var(--font);
    }
    .label{
        margin-bottom: 10px;
    }
    
    textarea{
        width: 100%;
        background: transparent;
        border: none;
        padding: 10px;
        border: 1px solid rgba(0,0,0,.1);
        min-height: 100px;
        resize: none;
    }
`;