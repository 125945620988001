import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateY(-80px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 140px);
  /* background-color: #cecece; */
`;

export const Content = styled.div`
  display: grid;
  place-items: center;
  height: 100%;

  > img {
    max-width: 80%;
    height: 100%;

    animation: ${appearFromLeft} 0.7s;
  }
`