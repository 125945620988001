import axios from "axios"
import React, { useState } from "react"
import { useEffect } from "react"
import Btn from "../../../components/form/btn/Btn"
import Checkbox from "../../../components/form/checkbox/Checkbox"
import Input from "../../../components/form/input/Input"
import Select from "../../../components/form/select/Select"
import { IconFilter } from "../../../components/layout/Svgs"
import Modal from "../../../components/modal/Modal"
import { useAtendimento } from "../../../contexts/AtendimentoContext"
import api from "../../../services/api"

interface Props {
    handleSubmit: (filter:any) => void
    handleReset: () => {}
    page?: string
}

interface IBGEResponse {
    sigla: string;
  }

interface FilterProps {
    Search?: string
    CodigoCliente?: string
    NomeCliente?: string
    CodigoLojaCliente?: string
    TipoContrato?: string
    AtendimentoEndereco_NomeLocal?: string
    AtendimentoEndereco_UF?: string
    AtendimentoEndereco_Cidade?: string
    idUsuarioTecnico?: string
    SemTecnicoAtribuido?: string
    Status?: string
    TipoAtendimentoId?: string
    DataHoraAbertura: string
    DataHoraAberturaFinal: string
    DataHoraInicioAtendimento?: string
    DataHoraTerminoAtendimento?: string
}
  
const Filtros:React.FC<Props> = ({
    handleSubmit,
    handleReset,
    page
}) => {
    const { filter, setFilter } = useAtendimento();
    const [modal, setModal] = useState<boolean>(false)
    const [newFilter, setNewFilter] = useState<FilterProps>({
        Search: "",
        CodigoCliente: "",
        NomeCliente: "",
        CodigoLojaCliente: "",
        TipoContrato: "",
        AtendimentoEndereco_NomeLocal: "",
        AtendimentoEndereco_UF: "",
        AtendimentoEndereco_Cidade: "",
        idUsuarioTecnico: "",
        SemTecnicoAtribuido: "",
        Status: "",
        TipoAtendimentoId: "",
        DataHoraAbertura: "",
        DataHoraAberturaFinal: "",
        DataHoraInicioAtendimento: "",
        DataHoraTerminoAtendimento: "",
    });
    const [accordion, setAccordion] = useState<any>([])
    const [usuarios, setUsuarios] = useState<any>([])
    const [tiposDeAtendimento, setTiposDeAtendimento] = useState<any>([])
    const [filterSize, setFilterSize] = useState<any>(0)
    const [ufs, setUfs] = useState<string[]>([]);

    useEffect(() => {
        axios.get<IBGEResponse[]>('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then(response => {
          const ufInitials = response.data.map(uf => uf.sigla);
    
          setUfs(ufInitials);
        });
      }, []);

    useEffect(() => {

        api.get("/usuarios?PerfilId=1").then(response => {
            setUsuarios([...response.data.result])
        })

        api.get("/tipos-de-atendimento").then(response => {
            setTiposDeAtendimento([...response.data.result])
        })

    }, [])

    useEffect(() => {

        var filtros = filter as any
        var size = 0
        for(var key in filtros) { if( filtros[key] && key !== "Search" ) size++ }
        setFilterSize(size)

    }, [filter])

    const onChangeInput = (key:string, value:any) => {

        // var filtros = filter as any
        // filtros[key] = value
        // setFilter({...filter})

        setFilter({...filter, [key]: value})
    }

    const onClickAccordion = (value:string) => {
        var index = accordion.indexOf(value)
        if( index !== -1 ){
            accordion.splice(index, 1)
        } else {
            accordion.push(value)
        }
        setAccordion([...accordion])
    }

    const onClickSubmit = () => {
        setModal(false)
        handleSubmit(filter)
    }

    const onClickReset = () => {
        setFilter({
            Search: "",
            CodigoCliente: "",
            NomeCliente: "",
            CodigoLojaCliente: "",
            TipoContrato: "",
            AtendimentoEndereco_NomeLocal: "",
            AtendimentoEndereco_UF: "",
            AtendimentoEndereco_Cidade: "",
            idUsuarioTecnico: "",
            SemTecnicoAtribuido: "",
            Status: "",
            TipoAtendimentoId: "",
            DataHoraAbertura: "",
            DataHoraAberturaFinal: "",
            DataHoraInicioAtendimento: "",
            DataHoraTerminoAtendimento: "",
        })
        handleReset()
        setModal(false)
    }

    return (
    <>
        <button type="button" className="btn btn-icon btn-secondary" onClick={() => setModal(true)}>
            <IconFilter />
            {filterSize > 0 && <span className="notification">{filterSize}</span>}
        </button>

        <Modal className="modalFilter" active={modal === false ? false : true} setActive={setModal}>
            <p>Filtrar por:</p>

            <div className={`accordion ${accordion.includes("Dados do Cliente") && "active"}`}>
                <div className="accordionHeader" onClick={() => onClickAccordion("Dados do Cliente")}>
                    <p>Dados do Cliente</p>
                </div>
                <div className="accordionContent">
                    <Input label="Código do Cliente" placeholder="Digite aqui..." name="CodigoCliente" value={filter.CodigoCliente} onChange={onChangeInput} />
                    <Input label="Nome do Cliente" placeholder="Digite aqui..." name="NomeCliente" value={filter.NomeCliente} onChange={onChangeInput} />
                    <Input label="Código da Loja" placeholder="Digite aqui..." name="CodigoLojaCliente" value={filter.CodigoLojaCliente} onChange={onChangeInput} />
                    <Input label="Tipo de Contrato" placeholder="Digite aqui..." name="TipoContrato" value={filter.TipoContrato} onChange={onChangeInput} />
                </div>
            </div>

            <div className={`accordion ${accordion.includes("Local") && "active"}`}>
                <div className="accordionHeader" onClick={() => onClickAccordion("Local")}>
                    <p>Local / Endereço</p>
                </div>
                <div className="accordionContent">
                    <Input label="Local" placeholder="Digite aqui..." name="AtendimentoEndereco_NomeLocal" value={filter.AtendimentoEndereco_NomeLocal} onChange={onChangeInput} />
                    {/*<Input 
                        label="UF" 
                        placeholder="Digite aqui..." 
                        name="AtendimentoEndereco_UF" 
                        value={filter.AtendimentoEndereco_UF} 
                        onChange={onChangeInput}
                     />*/}
                    <Input label="Cidade" placeholder="Digite aqui..." name="AtendimentoEndereco_Cidade" value={filter.AtendimentoEndereco_Cidade} onChange={onChangeInput} />
                    <Select
                        name="AtendimentoEndereco_UF"
                        label="UF"
                        placeholder="Selecione UF"
                        value={filter.AtendimentoEndereco_UF}
                        onChange={onChangeInput}
                    >
                        {/* <option value="0">Selecione uma UF</option> */}
                        {ufs.map(uf => (
                            <option key={uf} value={uf}>{uf}</option>
                        ))}
                    </Select>
                </div>
            </div>

            <div className={`accordion ${accordion.includes("Técnico") && "active"}`}>
                <div className="accordionHeader" onClick={() => onClickAccordion("Técnico")}>
                    <p>Técnico</p>
                </div>
                <div className="accordionContent">
                    <Select label="Técnico" name="idUsuarioTecnico" value={filter.idUsuarioTecnico} onChange={onChangeInput}>
                        <option value="">Selecione aqui</option>
                        {usuarios.map((row:any, key:any) => 
                            <option key={key} value={row.Id}>{row.Nome}</option>
                        )}
                    </Select>

                    <Checkbox label="Sem Técnico Atribuído" placeholder="Sim" name="SemTecnicoAtribuido" value={filter.SemTecnicoAtribuido ? true : false} onClick={onChangeInput} />
                </div>
            </div>
        
            <div className={`accordion ${accordion.includes("Status") && "active"}`}>
                <div className="accordionHeader" onClick={() => onClickAccordion("Status")}>
                    <p>Status e Tipo de atendimento</p>
                </div>
                <div className="accordionContent">

                    {page === "atribuir" ? 
                    <Select label="Status" name="Status" value={filter.Status} onChange={onChangeInput}>
                        <option value="">Aberto</option>
                    </Select>
                    :
                    <Select label="Status" name="Status" value={filter.Status} onChange={onChangeInput}>
                        <option value="0">Selecione aqui</option>
                        {["ABERTO", "EM ATENDIMENTO", "EM ANALISE", "RETORNADO", "FINALIZADO", "CANCELADO"].map((status:any, key:any) => 
                            <option key={key} value={status}>{status}</option>
                        )}
                    </Select>
                    }

                    <Select label="Tipo de Atendimento" name="TipoAtendimentoId" value={filter.TipoAtendimentoId} onChange={onChangeInput}>
                        <option value="">Selecione aqui</option>
                        {tiposDeAtendimento.map((row:any, key:any) => 
                            <option key={key} value={row.TipoAtendimentoId}>{row.TipoAtendimento}</option>
                        )}
                    </Select>
                </div>
            </div>
        
            <div className={`accordion ${accordion.includes("Data") && "active"}`}>
                <div className="accordionHeader" onClick={() => onClickAccordion("Data")}>
                    <p>Data</p>
                </div>
                <div className="accordionContent">
                    <div className="flex">
                        <Input label="Data da Abertura Inicial" placeholder="__/__/____" name="DataHoraAbertura" value={filter.DataHoraAbertura} onChange={onChangeInput} mask="date" />
                        <Input label="Data Final" placeholder="__/__/____" name="DataHoraAberturaFinal" value={filter.DataHoraAberturaFinal} onChange={onChangeInput} mask="date" />
                    </div>

                    <div className="flex">
                        <Input label="Data de Atendimento Inicial" placeholder="__/__/____" name="DataHoraInicioAtendimento" value={filter.DataHoraInicioAtendimento} onChange={onChangeInput} mask="date" />
                        <Input label="Data Final" placeholder="__/__/____" name="DataHoraTerminoAtendimento" value={filter.DataHoraTerminoAtendimento} onChange={onChangeInput} mask="date" />
                    </div>
                </div>
            </div>
            
            <div className="modalFooter">
                <Btn type="button" text="Aplicar Filtros" className="btn btn-secondary" onClick={() => onClickSubmit()} />
                <Btn type="button" text="Limpar Filtro" className="btn btn-danger-outline" onClick={() => onClickReset()} />
            </div>
        </Modal>
    
    </>
    )
}

export default Filtros