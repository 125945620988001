import React from "react";

interface IconProps {
  width?: string;
  height?: string;
  color?: string;
}

export const IconAtendimento: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <defs></defs>
      <path
        d="M17,2V4h3.007A.994.994,0,0,1,21,4.993V21.007a.994.994,0,0,1-.993.993H3.993A.994.994,0,0,1,3,21.007V4.993A.994.994,0,0,1,3.993,4H7V2ZM7,6H5V20H19V6H17V8H7ZM9,16v2H7V16Zm0-3v2H7V13Zm0-3v2H7V10Zm6-6H9V6h6Z"
        transform="translate(-3 -2)"
      />
    </svg>
  );
};

export const IconEdit: React.FC<IconProps> = ({ color = "#00A85A" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs></defs>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={color}
        d="M20,2a1,1,0,0,1,1,1V6.757l-2,2V4H5V20H19V17.242l2-2V21a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V3A1,1,0,0,1,4,2Zm1.778,6.808,1.414,1.414L15.414,18,14,18l0-1.412,7.778-7.778ZM13,12v2H8V12Zm3-4v2H8V8Z"
      />
    </svg>
  );
};

export const IconDetails: React.FC<IconProps> = ({ color = "#B7AC16" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_92_140)">
        <path
          d="M12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92894C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.76121C17.6541 3.51809 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22V22ZM12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4003 4.00347 8.93854 4.60897C7.47673 5.21447 6.2273 6.23985 5.34825 7.55544C4.4692 8.87104 4 10.4178 4 12C4 14.1217 4.84286 16.1566 6.34315 17.6569C7.84344 19.1572 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
          fill="#B7AC16"
        />
      </g>
      <defs>
        <clipPath id="clip0_92_140">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconArrow: React.FC = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_96_129)">
        <path
          d="M0.999023 13.4011C0.733807 13.4011 0.479529 13.2957 0.291992 13.1082C0.104456 12.9206 -0.000976562 12.6663 -0.000976562 12.4011V10.2009L4.06348 6.70093L-0.000976562 3.20093V1.00073C-0.000976562 0.735516 0.104456 0.481238 0.291992 0.293701C0.479529 0.106165 0.733807 0.000732422 0.999023 0.000732422C1.18474 0.00166407 1.36692 0.0546406 1.52441 0.153076L10.6973 5.85278C10.8423 5.9409 10.962 6.06507 11.0449 6.21313C11.1278 6.36119 11.1706 6.52832 11.1699 6.698C11.1711 6.86871 11.1278 7.03653 11.0449 7.18579C10.9621 7.33505 10.8428 7.46074 10.6973 7.55005L1.52441 13.2498C1.36715 13.3486 1.18476 13.4013 0.999023 13.4011V13.4011Z"
          fill="#00A85A"
        />
      </g>
      <defs>
        <clipPath id="clip0_96_129">
          <rect width="11.171" height="13.404" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconSearch: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs></defs>
    <path fill="none" d="M0,0H24V24H0Z" />
    <path d="M11,2a9,9,0,1,1-9,9A9,9,0,0,1,11,2Zm0,16a7,7,0,1,0-7-7A7,7,0,0,0,11,18Zm8.485.071L22.314,20.9,20.9,22.314l-2.828-2.829,1.414-1.414Z" />
  </svg>
);

export const IconLoading: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
      opacity=".5"
      fill="currentColor"
    />
    <path d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z" fill="currentColor">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 12 12"
        to="360 12 12"
        dur="1s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

export const IconRemove: React.FC<IconProps> = ({
  width = "1em",
  height = "1em",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <g fill="currentColor">
      <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z" />
      <path d="M14.71 9.29a1 1 0 0 0-1.42 0L12 10.59l-1.29-1.3a1 1 0 0 0-1.42 1.42l1.3 1.29l-1.3 1.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l1.29-1.3l1.29 1.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42L13.41 12l1.3-1.29a1 1 0 0 0 0-1.42z" />
    </g>
  </svg>
);

export const IconAtribuir: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <defs></defs>
      <path
        d="M15,4H5V20H19V8H15ZM3,2.992A1,1,0,0,1,4,2H16l5,5V20.993A1,1,0,0,1,20.007,22H3.993A1,1,0,0,1,3,21.008ZM11,11V8h2v3h3v2H13v3H11V13H8V11Z"
        transform="translate(-3 -2)"
      />
    </svg>
  );
};

export const IconPriorizar: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <defs></defs>
      <path
        d="M15,4H5V20H19V8H15ZM3,2.992A1,1,0,0,1,4,2H16l5,5V20.993A1,1,0,0,1,20.007,22H3.993A1,1,0,0,1,3,21.008ZM11,15h2v2H11Zm0-8h2v6H11Z"
        transform="translate(-3 -2)"
      />
    </svg>
  );
};

export const IconPonto: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <defs></defs>
      <path
        d="M21,2.992V21.008a1,1,0,0,1-.993.992H3.993A.993.993,0,0,1,3,21.008V2.992A1,1,0,0,1,3.993,2H20.007A.993.993,0,0,1,21,2.992ZM19,4H5V20H19Zm-7.707,9.121,4.243-4.242,1.414,1.414L11.293,15.95,7.4,12.06l1.415-1.414,2.475,2.475Z"
        transform="translate(-3 -2)"
      />
    </svg>
  );
};

export const IconResumo: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="20.192"
      height="20"
      viewBox="0 0 20.192 20"
    >
      <defs></defs>
      <path
        d="M20,2a1,1,0,0,1,1,1V6.757l-2,2V4H5V20H19V17.242l2-2V21a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V3A1,1,0,0,1,4,2Zm1.778,6.808,1.414,1.414L15.414,18,14,18l0-1.412,7.778-7.778ZM13,12v2H8V12Zm3-4v2H8V8Z"
        transform="translate(-3 -2)"
      />
    </svg>
  );
};

export const IconEnderecos: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14c4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2c0 3.32-2.67 7.25-8 11.8c-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconUsuarios: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2a5 5 0 1 0 5 5a5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3a3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconVeiculos: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        d="M20.772 10.156l-1.368-4.105A2.995 2.995 0 0 0 16.559 4H7.441a2.995 2.995 0 0 0-2.845 2.051l-1.368 4.105A2.003 2.003 0 0 0 2 12v5c0 .753.423 1.402 1.039 1.743c-.013.066-.039.126-.039.195V21a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-2h12v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-2.062c0-.069-.026-.13-.039-.195A1.993 1.993 0 0 0 22 17v-5c0-.829-.508-1.541-1.228-1.844zM4 17v-5h16l.002 5H4zM7.441 6h9.117c.431 0 .813.274.949.684L18.613 10H5.387l1.105-3.316A1 1 0 0 1 7.441 6z"
        fill="currentColor"
      />
      <circle cx="6.5" cy="14.5" r="1.5" fill="currentColor" />
      <circle cx="17.5" cy="14.5" r="1.5" fill="currentColor" />
    </svg>
  );
};

export const IconIntegracoes: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 512 512"
    >
      <path
        d="M421.415 309.528c-7.209 0-14.186.938-20.909 2.54l-.636-1.005l-83.542-131.894c18.528-16.698 30.257-40.888 30.257-67.894c0-50.366-40.556-91.197-90.585-91.197c-50.028 0-90.585 40.83-90.585 91.197c0 27.006 11.728 51.196 30.257 67.894L112.13 311.063l-.636 1.005c-6.723-1.602-13.7-2.54-20.91-2.54C40.557 309.528 0 350.358 0 400.725s40.556 91.197 90.585 91.197c50.028 0 90.584-40.83 90.584-91.197c0-34.507-19.045-64.525-47.122-80.016l81.138-128.098c12.276 6.257 26.114 9.86 40.815 9.86c14.701 0 28.54-3.603 40.816-9.86l81.137 128.098c-28.077 15.49-47.122 45.509-47.122 80.016c0 50.366 40.556 91.197 90.584 91.197c50.029 0 90.585-40.83 90.585-91.197s-40.556-91.197-90.585-91.197zM90.353 443.791c-23.319 0-42.223-18.903-42.223-42.222s18.904-42.223 42.223-42.223s42.222 18.904 42.222 42.223s-18.903 42.222-42.222 42.222z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconLogout: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3zm2-7h7v2H6v3l-5-4l5-4v3z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconAnexar: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor">
        <path d="M13.29 9.29l-4 4a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4-4a1 1 0 0 0-1.42-1.42z" />
        <path d="M12.28 17.4L11 18.67a4.2 4.2 0 0 1-5.58.4a4 4 0 0 1-.27-5.93l1.42-1.43a1 1 0 0 0 0-1.42a1 1 0 0 0-1.42 0l-1.27 1.28a6.15 6.15 0 0 0-.67 8.07a6.06 6.06 0 0 0 9.07.6l1.42-1.42a1 1 0 0 0-1.42-1.42zm7.38-14.18a6.18 6.18 0 0 0-8.13.68L10.45 5a1.09 1.09 0 0 0-.17 1.61a1 1 0 0 0 1.42 0L13 5.3a4.17 4.17 0 0 1 5.57-.4a4 4 0 0 1 .27 5.95l-1.42 1.43a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l1.42-1.42a6.06 6.06 0 0 0-.6-9.06z" />
      </g>
    </svg>
  );
};

export const IconConfigs: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4s-4 1.794-4 4s1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2s-2-.916-2-2s.916-2 2-2z"
        fill="currentColor"
      />
      <path
        d="M2.845 16.136l1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65l.998-1.729l1.145.662a.997.997 0 0 0 1.188-.142a6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956a6.083 6.083 0 0 1 2.384 1.399a.999.999 0 0 0 1.188.142l1.144-.661l1 1.729l-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378c0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649l-.998 1.729l-1.145-.661a.996.996 0 0 0-1.188.142a6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956a6.083 6.083 0 0 1-2.384-1.399a.992.992 0 0 0-1.188-.141l-1.144.662l-1-1.729l1.124-.651a1 1 0 0 0 .471-1.108z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconAdd: React.FC<IconProps> = ({ color = "currentColor" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.284"
      height="28.284"
      viewBox="0 0 28.284 28.284"
    >
      <path
        d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm0-9.414,2.828-2.829,1.415,1.415L13.414,12l2.829,2.828-1.415,1.415L12,13.414,9.172,16.243,7.757,14.828,10.586,12,7.757,9.172,9.172,7.757Z"
        transform="translate(14.142 -2.828) rotate(45)"
        fill={color}
      />
    </svg>
  );
};

export const IconDate: React.FC<IconProps> = ({ color = "#00A85A" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 11H9V13H7V11ZM7 15H9V17H7V15ZM11 11H13V13H11V11ZM11 15H13V17H11V15ZM15 11H17V13H15V11ZM15 15H17V17H15V15Z"
        fill={color}
      />
      <path
        d="M5 22H19C20.103 22 21 21.103 21 20V6C21 4.897 20.103 4 19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V20C3 21.103 3.897 22 5 22ZM19 8L19.001 20H5V8H19Z"
        fill={color}
      />
    </svg>
  );
};

export const IconClose: React.FC<IconProps> = ({ color = "#E15353" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.172 16.2421L12 13.4141L14.828 16.2421L16.242 14.8281L13.414 12.0001L16.242 9.17206L14.828 7.75806L12 10.5861L9.172 7.75806L7.758 9.17206L10.586 12.0001L7.758 14.8281L9.172 16.2421Z"
        fill={color}
      />
      <path
        d="M12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22ZM12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4Z"
        fill={color}
      />
    </svg>
  );
};

export const IconLike: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <path fill="none" d="M0,0H72V72H0Z" />
      <path
        fill="#00a85a"
        d="M41.8,22.724H61a6,6,0,0,1,6,6v6.312a6,6,0,0,1-.45,2.286L57.265,59.867a3,3,0,0,1-2.775,1.857H4a3,3,0,0,1-3-3v-30a3,3,0,0,1,3-3H14.446A3,3,0,0,0,16.9,24.455L33.256,1.274A1.5,1.5,0,0,1,35.152.8l5.442,2.721a7.5,7.5,0,0,1,3.915,8.559ZM19,30.488V55.724H52.48L61,35.036V28.724H41.8a6,6,0,0,1-5.814-7.479L38.695,10.6a1.5,1.5,0,0,0-.783-1.713L35.929,7.9,21.8,27.914A9.049,9.049,0,0,1,19,30.488Zm-6,1.236H7v24h6Z"
        transform="translate(2 1.276)"
      />
    </svg>
  );
};

export const IconWarning: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="74"
      viewBox="0 0 74 74"
    >
      <path fill="none" d="M0,0H74V74H0Z" />
      <path
        fill="#e15353"
        d="M32.833,63.667A30.833,30.833,0,1,1,63.667,32.833,30.833,30.833,0,0,1,32.833,63.667Zm0-6.167A24.667,24.667,0,1,0,8.167,32.833,24.667,24.667,0,0,0,32.833,57.5ZM29.75,42.083h6.167V48.25H29.75Zm0-24.667h6.167v18.5H29.75Z"
        transform="translate(4.167 4.167)"
      />
    </svg>
  );
};

export const IconFilter: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        d="M21 3H5a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L10 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 14 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L6 6.59V5h14.001l.002 1.583l-5.71 5.71z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconFile: React.FC = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2H20C20.2638 1.99999 20.517 2.10425 20.7043 2.29006C20.8916 2.47587 20.9979 2.72817 21 2.992V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.86168 21.9991 3.73182 21.9723 3.61085 21.9212C3.48987 21.8701 3.38015 21.7957 3.28794 21.7022C3.19573 21.6087 3.12284 21.4979 3.07344 21.3762C3.02403 21.2545 2.99908 21.1243 3 20.993V8L9 2ZM5.83 8H9V4.83L5.83 8ZM11 4V9C11 9.26522 10.8946 9.51957 10.7071 9.70711C10.5196 9.89464 10.2652 10 10 10H5V20H19V4H11Z"
        fill="#00A85A"
      />
    </svg>
  );
};
