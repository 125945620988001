import React, { createContext, useCallback, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import api from "../services/api";
import protheus from "../services/protheus";

interface Functionalities {
  Aplicacao: string;
  Controle: string;
  FuncionalidadeId: number;
  Nome: string;
  Tela: string;
}

interface User {
  Ativo: boolean;
  BatePonto: boolean;
  DataDeCriacao: string;
  Email: string;
  Id: number;
  Nome: string;
  PerfilId: number;
  RegistraKM: boolean;
  Token: string;
  horaInicioTrabalho: string;
  tiposAtendimentos: any;

  funcionalidades: Functionalities[]
}

interface SignInCredencials {
  email: string;
  senha: string;
}

interface AuthState {
  profile: User;
  token: string;
}

interface AuthContextData {
  user: User;
  signIn(credencials: SignInCredencials): Promise<void>;
  signOut(): void;
  getUser(id: any): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("@AmazonCopy:token");
    const profile = localStorage.getItem("@AmazonCopy:user");

    if (token && profile) {
      // api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      api.defaults.headers.authorization = `Bearer ${token}`;

      protheus.defaults.headers.authorization = `Bearer ${token}`;

      return { token, profile: JSON.parse(profile) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, senha }) => {
    const response = await api.post("/auth/login", {
        Email: email,
        Senha: senha,
    });

    if( response.data.error === true ) throw response.data.message

    const { token, profile } = response.data.result;

    localStorage.setItem("@AmazonCopy:token", token);
    localStorage.setItem("@AmazonCopy:user", JSON.stringify(profile));
    // api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.authorization = `Bearer ${token}`;
    protheus.defaults.headers.authorization = `Bearer ${token}`;


    setData({
        token,
        profile,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@AmazonCopy:token");
    localStorage.removeItem("@AmazonCopy:user");

    setData({} as AuthState);

    history.push('/')
    
  }, []);

//   const updateUser = useCallback(
//     (user: User) => {
//       localStorage.setItem("@GoBarber:user", JSON.stringify(user));

//       setData({
//         token: data.token,
//         user,
//       });
//     },
//     [setData, data.token],
//   );

  const getUser = useCallback((id: any ) => {
    api.get(`auth/profile/${id}`).then((response) => {
      setData({...data, ['profile']: response.data.result})
      localStorage.setItem("@AmazonCopy:user", JSON.stringify(response.data.result));
    })
  }, [])

  return (
    <AuthContext.Provider
      value={{ user: data.profile, signIn, signOut, getUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}