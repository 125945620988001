import axios from "axios"

const token = window.localStorage.getItem('@AmazonCopy:token');
var baseUrl

if(process.env.NODE_ENV === 'development') {
    // baseUrl = "http://192.168.15.8:3337/protheus/"
    baseUrl = "https://amazoncopy.backendtropa.com.br/protheus/"
} else {
    baseUrl = "https://amazoncopy.backendtropa.com.br/protheus/"
}

const api = axios.create({
    baseURL: baseUrl,
    // headers: {
    //     'Authorization': 'Bearer ' + token
    // }
})

export default api