import React, { useEffect, useState } from "react"
import message from "../../services/message"
import Btn from "../../components/form/btn/Btn"
import api from "../../services/api"
import { Container } from "../../styles/layout"
import Select from "../../components/form/select/Select"
import moment from "moment"
import { AtribuirTecnico, ContainerFiltros } from "./styles"
import Filtros from "./components/Filtros"
import { Divider } from "../usuarios/styles"
import Checkbox from "../../components/form/checkbox/Checkbox"
import { IUser } from "../../types/User"
import { IAtendimento } from "../../types/Atendimento"
import { IAtendimentoAtribuir } from "../../types/AtendimentoAtribuir"
import { FilterProps } from "./Listar"
import { checkAccessToken } from "../../utils/checkAccessToken"

interface Props {
    match: any
}

const Atribuir: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [DTO, setDTO] = useState<IAtendimentoAtribuir>({
        IdsAtendimentos: [],
        IdUsuarioTecnico: 0
    })
    const [atendimentos, setAtendimentos] = useState<IAtendimento[]>([])
    const [tecnicos, setTecnicos] = useState<IUser[]>([])
    const [newFilter, setNewFilter] = useState<FilterProps>({
        Search: "",
        CodigoCliente: "",
        NomeCliente: "",
        CodigoLojaCliente: "",
        TipoContrato: "",
        AtendimentoEndereco_NomeLocal: "",
        AtendimentoEndereco_UF: "",
        AtendimentoEndereco_Cidade: "",
        idUsuarioTecnico: "",
        SemTecnicoAtribuido: "",
        Status: "",
        TipoAtendimentoId: "",
        DataHoraAbertura: "",
        DataHoraAberturaFinal: "",
        DataHoraInicioAtendimento: "",
        DataHoraTerminoAtendimento: "",
    });

    useEffect(() => {
        api.get(`/usuarios?PerfilId=1`).then(response => {
            setTecnicos([...response.data.result])
        })
    }, [props])

    useEffect(() => {
        getAtendimentos()
    }, [newFilter])

    const onChangeTecnico = (value:string|number) => {

        if( value === "0" ){
            newFilter.SemTecnicoAtribuido = "true"
            newFilter.idUsuarioTecnico = ""
        } else {
            newFilter.SemTecnicoAtribuido = ""
            newFilter.idUsuarioTecnico = String(value)
        }
        setNewFilter({...newFilter})
    }

    const onChangeDTO = (key:string, value:string|number) => {
        let copy = DTO as any
        copy[key] = value
        setDTO({...copy})
    }

    const handleCheckbox = (key:any, value:boolean) => {
        let array = DTO?.IdsAtendimentos || []

        let index = array.indexOf(key)
        if( index === -1 ){
            array.push(key)
        } else {
            array.splice(index, 1)
        }

        let copy = DTO as any
        copy.IdsAtendimentos = array
        setDTO({...copy})
    }

    function handleOnFilter(dataFilter: any) {
        setNewFilter(dataFilter);
    }

    const handleSave = async () => {
        try {
            setLoading(true)
            var response = await api.put(`atendimentos-atribuir`, DTO)
            setLoading(false)

            if( response.data.error ) throw response.data.message
            message.success(response.data.message)
            
            setDTO({
                IdsAtendimentos: [],
                IdUsuarioTecnico: 0
            })

            getAtendimentos()

        } catch(error){
            if(error === "Token Inválido" || error === "Token expirado.") {
                checkAccessToken("Sessão expirada! 😥");
            } else {
                message.error(String(error))
            }
        }
    }

    const onClickReset = async () => {
        setAtendimentos([])
    }
    
    const handleFilter = async (e:React.FormEvent) => {
        e.preventDefault()
        await getAtendimentos()
    }

    const handleReset = async () => {
        setNewFilter({
            Search: "",
            CodigoCliente: "",
            NomeCliente: "",
            CodigoLojaCliente: "",
            TipoContrato: "",
            AtendimentoEndereco_NomeLocal: "",
            AtendimentoEndereco_UF: "",
            AtendimentoEndereco_Cidade: "",
            idUsuarioTecnico: "",
            SemTecnicoAtribuido: "",
            Status: "",
            TipoAtendimentoId: "",
            DataHoraAbertura: "",
            DataHoraAberturaFinal: "",
            DataHoraInicioAtendimento: "",
            DataHoraTerminoAtendimento: "",
        })
    }

    const getAtendimentos = async () => {

        setAtendimentos([])

        var filtros = newFilter as any
        var size = 0
        for(var key in filtros) { 
            if( filtros[key] ){
                size++ 
            } else {
                delete filtros[key]
            }
        }

        if( size > 0 ){
            var queryString = new URLSearchParams(filtros).toString()
            queryString += `&Status=Aberto`
            setLoading(true)
            const response = await api.get(`atendimentos?Order=OrderCodigoAtendimento&${queryString}`)
            setAtendimentos([...response.data.result])
            setLoading(false)
        }
    }

    return (
        <Container>
            <h1>Atribuir Atendimentos</h1>

            <ContainerFiltros>
                <form className="filter" onSubmit={handleFilter}>
                    <div style={{width: 680}}>
                        <Select 
                            label="Técnico"
                            theme="alt"
                            name="idUsuarioTecnico" 
                            value={newFilter.SemTecnicoAtribuido ? "0" : newFilter.idUsuarioTecnico} 
                            onChange={(key, value) => onChangeTecnico(value)} 
                            onClickReset={onClickReset}>
                            <option value="">Selecione aqui</option>
                            <option value="0">Sem Técnico Atribuído</option>
                            {tecnicos.map((row, key) => (
                            <option key={key} value={row.Id}>{row.Nome}</option>
                            ))}
                        </Select>  
                    </div>

                    <Divider />

                    <Filtros handleSubmit={(data) => handleOnFilter(data)} handleReset={handleReset} page="atribuir" />
                    <button type="submit" style={{display: "none"}}></button>                  
                    
                </form> 
            </ContainerFiltros>

            <hr />

            { (newFilter.idUsuarioTecnico && !atendimentos.length) && (
            <>
                <h3 className="result">Resultado</h3>
                <p>Nenhum resultado foi encontrado</p>
            </>
            ) }

            { atendimentos.length > 0 && 
            <>
                <h3 className="result">Resultado</h3>
            
                <div className="table-scroll">
                    <table className="default">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Código Atendimento</th>
                                <th>Tipo</th>
                                <th>N. de Série</th>
                                <th>Modelo</th>
                                <th>Data/hora Abertura</th>
                                <th>Data/hora Agendada</th>
                                <th>Técnico</th>
                                <th>Cliente</th>
                                <th>Status</th>
                                <th>Local</th>
                                <th>Cidade</th>
                                <th>Zona</th>
                                <th>Bairro</th>
                            </tr>
                        </thead>

                        <tbody>
                            {atendimentos.map((row, key) => (
                            <tr key={key}>
                                <td>
                                    <Checkbox
                                        name={String(row.AtendimentoId)}
                                        value={(DTO.IdsAtendimentos.includes(String(row.AtendimentoId))) ? true : false}
                                        onClick={handleCheckbox}
                                    />  
                                </td>
                                <td>{row.CodigoAtendimento}</td>
                                <td>{row.TipoAtendimento}</td>
                                <td>{row.NroSerieMaquina}</td>
                                <td>{row.DescricaoModeloMaquina}</td>
                                <td>{moment(row.DataHoraAbertura).utc().format("DD/MM/YYYY HH:mm")}</td>
                                <td>{row.DataHoraAgendamento ? moment(row.DataHoraAgendamento).utc().format("DD/MM/YYYY HH:mm") : "--"}</td>
                                <td>{row.UsuarioTecnico ? row.UsuarioTecnico : "--"}</td>
                                <td>{row.NomeCliente}</td>
                                <td>{row.Status}</td>
                                <td>{row.NomeLocal}</td>
                                <td>{row.Cidade}</td>
                                <td>{row.Zona}</td>
                                <td>{row.Bairro}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <AtribuirTecnico>
                    <div className="list-technician">
                        <div style={{width: 680}} >
                            <Select 
                                label="Atribuir para"
                                theme="alt"
                                name="IdUsuarioTecnico"
                                value={DTO.IdUsuarioTecnico ? DTO.IdUsuarioTecnico : ""}
                                onChange={onChangeDTO}>
                                <option value="">Selecione aqui</option>
                                {tecnicos.map((row, key) => (
                                <option key={key} value={row.Id} disabled={(String(newFilter.idUsuarioTecnico) === String(row.Id)) ? true : false}>{row.Nome}</option>
                                ))}
                            </Select>    

                        </div>
                        <Btn className="btn btn-featured" type="button" text="Atribuir" loading={loading} onClick={handleSave} /> 
                    </div>
                </AtribuirTecnico>
            </>
            }

        </Container>    
    )
}

export default Atribuir
