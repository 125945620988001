import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import Btn from "../../components/form/btn/Btn";
import Select from "../../components/form/select/Select";
import api from "../../services/api";
import message from "../../services/message";
import { Container } from "../../styles/layout";
import { ContainerAtendimentos } from "./styles";
import Leitura from "./components/Leitura";
import ManutencaoCorretiva from "./components/ManutencaoCorretiva";
import { useAtendimento } from "../../contexts/AtendimentoContext";
import ManutencaoPreventiva from "./components/ManutencaoPreventiva";
import Instalacao from "./components/Instalacao";
import Desinstalacao from "./components/Desinstalacao";
import Entrega from "./components/Entrega";
import Retorno from "./components/Retorno";
import moment from "moment";

interface Props {
  match: any;
}

const Editar: React.FC<Props> = (props) => {
  const history = useHistory<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tiposAtendimento, setTiposAtendimentos] = useState<any[]>([]);
  const { atendimento, setAtendimento } = useAtendimento();
  const dateTime = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");

  useEffect(() => {
    getAttendence();
    api.get("tipos-de-atendimento").then((response) => {
      setTiposAtendimentos(response.data.result);
    });
  }, [props]);

  async function getAttendence() {
    try {
      setLoading(true);

      const response = await api.get(
        `atendimentos/${history.location.state.id}`,
      );
      setAtendimento({ ...response.data.result });

      setLoading(false);
    } catch (error: any) {
      alert(error.response.data.message);
      setLoading(false);
    }
  }

  const onChangeInput = (key: string, value: any) => {
    let input: any = atendimento;
    input[key] = value;
    setAtendimento({ ...input });
  };

  const onChangeAddress = (name: any, value: any) => {
    const addres: any = atendimento;

    addres.AtendimentoEndereco[name] = value;

    setAtendimento({ ...addres });
  };

  async function handleSave() {
    try {
      setLoading(true);

      if (history.location.state.id !== "novo") {
        var response = await api
          .put(`atendimentos/${atendimento.AtendimentoId}`, {
            ...atendimento,
            DataHoraAbertura: dateTime,
          })
          .catch((error) => {
            throw error.response.data.message;
          });
      } else {
        var response = await api
          .post("atendimentos", atendimento)
          .catch((error) => {
            throw error.response.data.message;
          });
      }

      setLoading(false);

      if (response.data.error) {
        throw response.data.message;
      }
      
      message.success(history.location.state.id !== 'novo' ? 'Edições Salvas com Sucesso' : response.data.message);
      history.push("/atendimentos");
    } catch (error: any) {
      setLoading(false);
      message.error(error);
    }
  }

  const handleCancel = async () => {
    let bool = await message.confirm({
      title: "Cancelar",
      text: `Deseja realmente cancelar o atendimento?`,
    });

    if (bool) history.push("/atendimentos");
  };

  return (
    <Container>
      <ContainerAtendimentos>
        <h1>
          Atendimento |{" "}
          {history.location.state.id !== "novo" ? "Editar" : "Adicionar"}
        </h1>

        <h2 className="subtitle">Dados do atendimento</h2>

        <div className="list-infos">
          {atendimento.CodigoAtendimento && (
            <div className="info">
              <span className="title">Código do Atendimento</span>
              <span className="value">{atendimento.CodigoAtendimento}</span>
            </div>
          )}

          <div className="info">
            <span className="title">Status</span>
            <span className="value">{atendimento.Status}</span>
          </div>

          <div className="info">
            <span className="title">Data e Hora de Abertura</span>
            <span className="value">
              {moment(new Date()).format("DD/MM/YYYY HH:mm")}
            </span>
          </div>
        </div>

        <div className="container-infos">
          <Select
            label="Tipo de atendimento"
            placeholder="Selecione um tipo"
            name="TipoAtendimentoId"
            value={atendimento.TipoAtendimentoId || ""}
            onChange={onChangeInput}
            theme="alt"
          >
            {tiposAtendimento.map((row, key) => (
              <option key={key} value={row.TipoAtendimentoId}>
                {row.TipoAtendimento}
              </option>
            ))}
          </Select>
        </div>

        {String(atendimento.TipoAtendimentoId) === "1" && (
          <Leitura atendimento={atendimento} handleOnChange={onChangeInput} />
        )}

        {String(atendimento.TipoAtendimentoId) === "2" && (
          <ManutencaoCorretiva
            atendimento={atendimento}
            handleOnChange={onChangeInput}
          />
        )}

        {String(atendimento.TipoAtendimentoId) === "3" && (
          <ManutencaoPreventiva
            atendimento={atendimento}
            handleOnChange={onChangeInput}
          />
        )}

        {String(atendimento.TipoAtendimentoId) === "4" && (
          <Instalacao
            atendimento={atendimento}
            handleOnChange={onChangeInput}
          />
        )}

        {String(atendimento.TipoAtendimentoId) === "5" && (
          <Desinstalacao
            atendimento={atendimento}
            handleOnChange={onChangeInput}
          />
        )}

        {String(atendimento.TipoAtendimentoId) === "6" && (
          <Entrega
            atendimento={atendimento}
            handleOnChange={onChangeInput}
            onChangeAddress={onChangeAddress}
          />
        )}

        {String(atendimento.TipoAtendimentoId) === "7" && (
          <Retorno
            atendimento={atendimento}
            handleOnChange={onChangeInput}
            onChangeAddress={onChangeAddress}
          />
        )}

        <div className="actions">
          <Btn
            className="btn btn-featured"
            type="button"
            text="Salvar"
            loading={loading}
            onClick={handleSave}
          />
          <button onClick={handleCancel} className="btn btn-danger">
            Cancelar
          </button>
        </div>
      </ContainerAtendimentos>
    </Container>
  );
};

export default Editar;
