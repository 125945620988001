import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  border-radius: 5px;
  color: var(--primary);
  font-weight: 600;
  transition: .2s;
  text-decoration: none;
  background-color: var(--featured);
  color: #FFFFFF;
  box-shadow:0px 10px 10px rgba(0,0,0,.03);
  cursor: pointer;
  &:hover{
      opacity: .8;
  }

  >a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 8px;
    color: #FFFFFF;
    text-decoration: none;
  }
`;
