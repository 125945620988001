import styled from "styled-components"

export const DivCheckbox = styled.div`
    margin-right: 20px;
    .label{
        font-size: 90%;
        color: var(--font);
    }
    .checkbox {
        display: flex;
        justify-content: left;
        cursor: pointer;
        margin: 10px 0;
        .square{
            box-sizing: border-box;
            vertical-align: middle;
            display: inline-block;
            width: 22px;
            height: 22px;
            border: 2px solid #ddd;
            border-radius: 3px;
            &:before{
                display: block;
                content: "✔";
                font-size: 80%;
                line-height: 18px;
                width: 18px;
                text-align: center;
                color: #ddd;
                transition: .2s;
                opacity: 0;
            }
        }
        .text{
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
            line-height: 22px;
        }
        &.active{
            .square{
                border: 2px solid var(--primary);
                background-color: var(--primary);
                &:before{
                    opacity: 1;
                    color: #fff;
                }
            }
        }
    }
    
    @media (min-width: 600px) {
        .checkbox {
            &:hover{
                .square{
                    &:before{
                        opacity: 1;
                    }
                }
            }
        }
    }
`;