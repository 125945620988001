import React, { useState } from "react"
import {ContainerHeader} from "./styles"
import logoAlt from "../../assets/images/logo-alt.svg"
import Modal from "../modal/Modal"
import Input from "../form/input/Input"
import Btn from "../form/btn/Btn"
import Message from "../../services/message"
import api from "../../services/api"
import { useAuth } from "../../hooks/Auth"

const Header: React.FC = (props) => {
    const { user } = useAuth()
    const [modal, setModal] = useState<boolean>(false)
    const [DTO, setDTO] = useState<any>({
        SenhaAtual: "",
        SenhaNova: "",
        SenhaConfirmar: ""
    })

    const onChangeInput = (key:string, value:string) => {
        DTO[key] = value
        setDTO({...DTO})
    }

    const handleChangePassword = async (e:React.FormEvent) => {
        e.preventDefault()
        try {
            let response = await api.put("auth/change-password", DTO)
            if( response.data.error === true ) throw response.data.message

            Message.success(response.data.message)
            setDTO({
                SenhaAtual: "",
                SenhaNova: "",
                SenhaConfirmar: ""
            })
            setModal(false)
        } catch(message) {
            Message.error(String(message))
        }
    }

    return (
        <>
        <ContainerHeader>
            <img src={logoAlt} alt="Amazoncopy" />

            <div className="containerAuth">
                <h3>Olá {user?.Nome}</h3>
                <nav>
                    <button type="button" onClick={() => setModal(true)}>Trocar Senha</button>
                </nav>
            </div>
        </ContainerHeader>

        <Modal active={modal} setActive={setModal}>
            <h3 className="subtitle center">Trocar senha</h3>
            <br/>
            <p className="center">A senha deve conter no mínimo 6 caracteres e pelo menos uma letra maiúscula e um número</p>

            <form onSubmit={handleChangePassword}>
                <Input type="password" label="Senha Atual" name="SenhaAtual" value={DTO.SenhaAtual} onChange={onChangeInput} />
                <Input type="password" label="Nova Senha" name="SenhaNova" value={DTO.SenhaNova} onChange={onChangeInput} />
                <Input type="password" label="Confirmar nova senha" name="SenhaConfirmar" value={DTO.SenhaConfirmar} onChange={onChangeInput} />
                <br/>
                <Btn text="Confirmar" className="btn btn-primary" />
            </form>
        </Modal>
        </>
    )
}

export default Header