import React from "react"
import {DivSelect, DivSelectAlt} from "./styles"

interface Props {
    name: string
    value: any
    className?: string,
    onChange: (key: string, value: string) => void
    onClickReset?: (key: string) => void
    label?: string
    placeholder?: string
    theme?: "default" | "alt"
    readOnly?: boolean
    styles?: React.CSSProperties
}

const Select: React.FC<Props> = (Props) => {

    const onChange = (e:React.FormEvent<HTMLSelectElement>) => {
        let value = e.currentTarget.value
        Props.onChange(Props.name, value)
    }
    
    const onClickReset = () => {
        Props.onChange(Props.name, "")
        if( Props.onClickReset ) Props.onClickReset(Props.name)
    }

    const content = (
        <label className={Props.className}>
            {Props.label && <div className="label">{Props.label}</div>}
    
            {(Props.theme === "alt" && Props.value) && <button type="button" className="reset" onClick={onClickReset}>
                <span>Limpar</span>
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="currentColor"><path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"/><path d="M14.71 9.29a1 1 0 0 0-1.42 0L12 10.59l-1.29-1.3a1 1 0 0 0-1.42 1.42l1.3 1.29l-1.3 1.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l1.29-1.3l1.29 1.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42L13.41 12l1.3-1.29a1 1 0 0 0 0-1.42z"/></g></svg>
            </button>}
            
            <select
                value={Props.value} 
                onChange={onChange} 
                disabled={Props.readOnly}
            >
                { Props.placeholder && 
                    <option value="0">{Props.placeholder}</option>
                }
                {Props.children}
            </select>
        </label>
    )

    return (
        <>
        {Props.theme === "alt" ? 
            <DivSelectAlt style={Props.styles} className="divSelect divSelectAlt">{content}</DivSelectAlt>
        :
            <DivSelect className="divSelect">{content}</DivSelect>
        } 
        </>
    )
    
}

export default Select