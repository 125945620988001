import React, { useState } from "react"
import Checkbox from "../../../components/form/checkbox/Checkbox"
import Input from "../../../components/form/input/Input"
import { useAtendimento } from "../../../contexts/AtendimentoContext"
import { ICliente } from "../../../types/Cliente"
import ModalClientes from "./modal/ModalClientes"

interface Props {
    readOnly?: boolean
}

const PesquisarCliente: React.FC<Props> = ({
    readOnly = false
}) => {
    
    const [modal, setModal] = useState<boolean>(false)

    const {atendimento, setAtendimento} = useAtendimento()

    const onChangeInput = (key:string, value:any) => {
        let input:any = atendimento
        input[key] = value
        setAtendimento({...input})
    }

    const onClickCliente = (cliente:ICliente) => {
        atendimento.CodigoCliente = cliente.CodigoCliente
        atendimento.CodigoLojaCliente = cliente.CodigoLojaCliente
        atendimento.NomeCliente = cliente.NomeCliente
        atendimento.NroContrato = cliente.NroContrato
        atendimento.TipoContrato = cliente.TipoContrato
        setAtendimento({...atendimento})
        setModal(false)
    }

    const onClickReset = () => {
        atendimento.CodigoCliente = ""
        atendimento.CodigoLojaCliente = ""
        atendimento.NomeCliente = ""
        atendimento.NroContrato = ""
        atendimento.TipoContrato = ""
        atendimento.AtendimentoEndereco = {}
        setAtendimento({...atendimento})
    }

    return (
    <>
    <div className="container-infos">

        {readOnly ?
        <p>Cliente</p>
        :
        <>
        <p>Buscar Cliente da Base?</p>
        <div style={{display: "flex"}}>
            <Checkbox placeholder="Sim" name="" value={atendimento.CodigoCliente ? true : false} onClick={() => setModal(true)} />
            <Checkbox placeholder="Não" name="" value={atendimento.CodigoCliente ? false : true} onClick={() => onClickReset()} />
        </div>
        </>
        }

        { atendimento.CodigoCliente ?
            <div className="list-infos">

                <div className="info">
                    <span className="title">Código do Cliente</span>
                    <span className="value">{atendimento.CodigoCliente}</span>
                </div>

                <div className="info">
                    <span className="title">Loja</span>
                    <span className="value">{atendimento.CodigoLojaCliente}</span>
                </div>

                <div className="info">
                    <span className="title">Cliente</span>
                    <span className="value">{atendimento.NomeCliente}</span>
                </div>

                <div className="info">
                    <span className="title">Número do Contrato</span>
                    <span className="value">{atendimento.NroContrato}</span>
                </div>

                <div className="info">
                    <span className="title">Tipo do Contrato</span>
                    <span className="value">{atendimento.TipoContrato}</span>
                </div>

            </div>
        :
            <Input label="Nome do Cliente" placeholder="Digite aqui..." name="NomeCliente" value={atendimento.NomeCliente} onChange={onChangeInput} readOnly={readOnly} />
        }

    </div>

    <ModalClientes 
        active={modal}
        setActive={setModal}
        onClick={onClickCliente}
    />
    </>
    )
}

export default PesquisarCliente