import React from "react"
import { useHistory } from "react-router-dom"
import { useAtendimento } from "../../contexts/AtendimentoContext"
import { useAuth } from "../../hooks/Auth"
import {ContainerSidebar} from "./styles"

const Sidebar: React.FC<any> = ({ menu }) => {
    const { user } = useAuth();
    const history = useHistory();
    const { setFilter } = useAtendimento();
    async function handleOnNavigate(link: any) {
        setFilter({
            Search: "",
            CodigoCliente: "",
            NomeCliente: "",
            CodigoLojaCliente: "",
            TipoContrato: "",
            AtendimentoEndereco_NomeLocal: "",
            AtendimentoEndereco_UF: "",
            AtendimentoEndereco_Cidade: "",
            idUsuarioTecnico: "",
            SemTecnicoAtribuido: "",
            Status: "",
            TipoAtendimentoId: "",
            DataHoraAbertura: "",
            DataHoraAberturaFinal: "",
            DataHoraInicioAtendimento: "",
            DataHoraTerminoAtendimento: "",
        })
        history.push(link)
    }

    return (
        <ContainerSidebar>
            <nav>

                {menu.map((row: any, key: any) => user?.funcionalidades.filter((obj: any) => obj.Nome === row.name && row.name !== 'Sair').length > 0 && (
                    <button 
                        key={key}
                        // to={row.to} 
                        type="button"
                        className={`${history.location.pathname === row.to && "active"}`}
                        onClick={() => handleOnNavigate(row.to)}
                    >
                        <span className="icon">{row.icon}</span>
                        <span className="text">{row.name}</span>
                    </button>    
                ))}

                {menu.map((row: any, key: any) => user?.funcionalidades.filter((obj: any) => obj.Nome === row.name && row.name === 'Sair').length > 0 && (
                    <button 
                        key={key}
                        className={`${history.location.pathname === row.to && "active"}`}
                        onClick={row.onClick}
                    >
                        <span className="icon">{row.icon}</span>
                        <span className="text">{row.name}</span>
                    </button>    
                ))}
            </nav>
        </ContainerSidebar>
    )
}

export default Sidebar