import React, { createContext, useContext, useState } from 'react'
import { IAtendimento } from '../types/Atendimento'

interface IFilter {
    Search?: string
    CodigoCliente?: string
    NomeCliente?: string
    CodigoLojaCliente?: string
    TipoContrato?: string
    AtendimentoEndereco_NomeLocal?: string
    AtendimentoEndereco_UF?: string
    AtendimentoEndereco_Cidade?: string
    idUsuarioTecnico?: string
    SemTecnicoAtribuido?: string
    Status?: string
    TipoAtendimentoId?: string
    DataHoraAbertura: string
    DataHoraAberturaFinal: string
    DataHoraInicioAtendimento?: string
    DataHoraTerminoAtendimento?: string
}

interface IContext {
    atendimento: IAtendimento
    setAtendimento: React.Dispatch<React.SetStateAction<IAtendimento>>
    filter: IFilter
    setFilter: React.Dispatch<React.SetStateAction<IFilter>>
    attendence: IAtendimento[]
    setAttendence: React.Dispatch<React.SetStateAction<IAtendimento[]>>
}

const AtendimentoContext = createContext<IContext>({} as IContext)

export const AtendimentoProvider: React.FC = ({ children }) => {
    const [atendimento, setAtendimento] = useState<IAtendimento>({})
    const [attendence, setAttendence] = useState<IAtendimento[]>([]);
    const [filter, setFilter] = useState<IFilter>({
        Search: "",
        CodigoCliente: "",
        NomeCliente: "",
        CodigoLojaCliente: "",
        TipoContrato: "",
        AtendimentoEndereco_NomeLocal: "",
        AtendimentoEndereco_UF: "",
        AtendimentoEndereco_Cidade: "",
        idUsuarioTecnico: "",
        SemTecnicoAtribuido: "",
        Status: "",
        TipoAtendimentoId: "",
        DataHoraAbertura: "",
        DataHoraAberturaFinal: "",
        DataHoraInicioAtendimento: "",
        DataHoraTerminoAtendimento: "",
    })

    return (
        <AtendimentoContext.Provider value={{atendimento, setAtendimento, filter, setFilter, attendence, setAttendence }}>
            {children}
        </AtendimentoContext.Provider>
    )
}

export function useAtendimento() {
    const context = useContext(AtendimentoContext)
    return context
}

export default AtendimentoContext