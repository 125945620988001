import { createGlobalStyle } from 'styled-components'
import segoeuiTTF from '../assets/fonts/segoeui.ttf'
import iconAlert from '../assets/images/icon-alert.svg'
import iconLike from '../assets/images/icon-like.svg'
import logo from '../assets/images/logo.svg'

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Segoe UI';
    src: url(${segoeuiTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}
:root {
    --default: #000000;
    --primary: #00A85A;
    --secondary: #09A5B5;
    --featured: #0ABF9E;
    --danger: #E15353;
    --font: #7B7B7B;
    --background: #F5F5F5;
    --title: #413F3F;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
}
html {
    @media (max-width: 1080px) {
      font-size: 14px;
    }
    @media (max-width: 720px) {
      font-size: 12px;
    }
}
body {
    background: var(--background);
    color: var(--default);
    -webkit-font-smoothing: antialiased;
}
body, input, textarea, button {
    font-family: 'Segoe UI', sans-serif;
    font-size: 14px;
    font-weight: 400;
    outline: none;
}
h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
}
button {
    cursor: pointer;
}
hr {
    height: 1px;
    margin: 15px 0;
    background-color: #ddd;
    border: none;
}
.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    height: 50px;
    background: transparent;
    border: none;
    border-radius: 5px;
    color: var(--primary);
    margin: 15px 0;
    font-weight: 600;
    transition: .2s;
    text-decoration: none;
    svg{
        margin: 0 5px;
    }
    &:hover{
        opacity: .8;
    }
    &.btn-primary {
        background-color: var(--primary);
        color: #FFFFFF;
        box-shadow:0px 10px 10px rgba(0,0,0,.03);
    }
    &.btn-primary-outline {
        background-color: transparent;
        border: 1px solid var(--primary);
        color: var(--primary);
        box-shadow:0px 10px 10px rgba(0,0,0,.03);
    }
    &.btn-secondary {
        background-color: var(--secondary);
        color: #FFFFFF;
        box-shadow:0px 10px 10px rgba(0,0,0,.03);
    }
    &.btn-secondary-outline {
        background-color: transparent;
        border: 1px solid var(--secondary);
        color: var(--secondary);
        box-shadow:0px 10px 10px rgba(0,0,0,.03);
    }
    &.btn-featured {
        background-color: var(--featured);
        color: #FFFFFF;
        box-shadow:0px 10px 10px rgba(0,0,0,.03);
    }
    &.btn-danger {
        background-color: var(--danger);
        color: #FFFFFF;
        box-shadow:0px 10px 10px rgba(0,0,0,.03);
    }
    &.btn-danger-outline {
        background-color: #fff;
        border: 1px solid var(--danger);
        color: var(--danger);
        box-shadow:0px 10px 10px rgba(0,0,0,.03);
    }
    &.btn-link-primary{
        color: var(--primary);
        &:hover{
            text-decoration: underline;
        }
    }
    &.btn-link-secondary{
        color: var(--secondary);
        &:hover{
            text-decoration: underline;
        }
    }
    &.btn-link-danger{
        color: var(--danger);
        &:hover{
            text-decoration: underline;
        }
    }
    &.btn-link{
        &:hover{
            text-decoration: underline;
        }
    }
}
.color-primary{
    color: var(--primary);
}
.center{
    text-align: center
}
.messageContainer{
    z-index: 99999999!important;
    &.messageOverlay{
        background: rgba(0,0,0,.75)!important;
    }
    .messagePopup{
        border-radius: 5px;
        box-shadow: 0px 7px 6px #00000029;
        &.messageLogo{
            &:before{
                content: "";
                width: 200px;
                height: 100px;
                margin: 30px auto;
                background: url(${logo}) no-repeat center;
                background-size: 100% auto;
            }
        }
        &.messageError{
            &:before{
                content: "";
                width: 200px;
                height: 80px;
                margin: 20px auto;
                background: url(${iconAlert}) no-repeat center;
                background-size: auto 80%;
            }
        }
        &.messageSuccess{
            &:before{
                content: "";
                width: 200px;
                height: 80px;
                margin: 20px auto;
                background: url(${iconLike}) no-repeat center;
                background-size: auto 80%;
            }
        }
        &.messageConfirm{
            padding-top: 30px;
        }
        .messageTitle{
            font-size: 100%;
            line-height: 120%;
            font-weight: 600;
        }
        .swal2-html-container{
            margin: 0 20px;
            font-size: 100%;
        }
        .btn{
            min-width: 160px;
            font-size: 100%;
        }
        .swal2-actions{
            display: flex;
            width: 100%;
            justify-content: center;
            .btn{
                width: 40%;
                margin: 15px;
            }
        }
    }
}
`