import React from "react"
import { IconRemove, IconSearch } from "../../layout/Svgs"
import {ContainerBtnSearch} from "./styles"

interface Props {
    label: string
    placeholder: string
    value?: string
    onReset: () => void
    onClick: () => void
}

const BtnSearch: React.FC<Props> = (Props) => {
    return (
        <ContainerBtnSearch>

            <label>{ Props.label }</label>

            <div className="item">

                <button type="button" className="input" onClick={Props.onClick}>
                    <IconSearch/>
                    <span>{ Props.value ? Props.value : Props.placeholder}</span>
                </button>

                { Props.value && 
                    <button type="button" className="reset" onClick={Props.onReset}>
                        <span>Limpar</span>
                        <IconRemove/>
                    </button>
                }
            </div>

        </ContainerBtnSearch>
    )
}

export default BtnSearch