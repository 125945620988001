import styled from "styled-components"

export const ContainerBtnSearch = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    label {
        font-size: 13px;
        color: #7B7B7B;
        font-size: 90%;
        font-weight: 400;
        margin-top: 5px;
    }
    .item {
        border-bottom: 1px solid rgba(0,0,0, .1);
        padding: 5px 0px;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        .input {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background: transparent;
            border: none;
            svg {
                margin-right: 5px;
            }
            span {
                color: rgba(0,0,0,.5);
            }
        }
        .reset{
            display: flex;
            align-items: center;
            background: #fff;
            border: none;
            border-right: 6px solid #FFF;
            color: var(--danger);
            span{
                font-size: 90%;
            }
            svg{
                width: 16px;
                height: 16px;
                margin-left: 2px;
            }
        }
    }
    .tag{
        margin-right: 10px;
        &:before{
            content: "";
            display: inline-block;
            margin: 0px 2px 0 0;
            width: 12px;
            height: 12px;
            background-color: var(--primary);
            border-radius: 2px;
        }
    }
`;