import { useAuth } from "../../hooks/Auth"
import message from "../../services/message"
import Header from "./Header"
import Sidebar from "./Sidebar"
import {ContainerLayout} from "./styles"
import { IconAtendimento, IconAtribuir, IconEnderecos, IconIntegracoes, IconLogout, IconPonto, IconPriorizar, IconResumo, IconUsuarios, IconVeiculos } from "./Svgs"

const Layout: React.FC = (props) => {
    const { signOut } = useAuth();

    const handleLogout = () => {
        signOut()
    }

    const handleCancel = async () => {

        let bool = await message.confirm({
            title: 'Deseja sair do sistema?',
            button: 'Sair',
            text: ' '
        })

        if ( bool ) handleLogout()

    }

    return (
        <ContainerLayout>
            
            <Header />
            
            <Sidebar 
                menu={[
                    {
                        name: 'Atendimentos',
                        icon: <IconAtendimento />,
                        to: "/atendimentos",
                    },
                    {
                        name: 'Atribuir Atendimentos',
                        icon: <IconAtribuir />,
                        to: "/atendimento/atribuir",
                    },
                    {
                        name: 'Priorizar Atendimentos',
                        icon: <IconPriorizar />,
                        to: "/priorizar",
                    },
                    {
                        name: 'Ponto',
                        icon: <IconPonto />,
                        to: "/ponto",
                    },
                    {
                        name: 'Resumo Técnico',
                        icon: <IconResumo />,
                        to: "/resumoTecnico",
                    },
                    {
                        name: 'Endereços',
                        icon: <IconEnderecos />,
                        to: "/enderecos",
                    },
                    {
                        name: 'Usuários',
                        icon: <IconUsuarios />,
                        to: "/usuarios"
                    },
                    {
                        name: 'Veículos',
                        icon: <IconVeiculos />,
                        to: "/veiculos"
                    },
                    {
                        name: 'Integrações',
                        icon: <IconIntegracoes />,
                        to: "#"
                    },
                    {
                        name: 'Sair',
                        icon: <IconLogout />,
                        onClick: handleCancel,
                        to: '/'
                    },
                ]} 
            />

            <div className="content">
                {props.children}
            </div>
        </ContainerLayout>
    )
}

export default Layout