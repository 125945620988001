import { Switch } from 'react-router-dom'
import Layout from '../components/layout/Layout'
import { AtendimentoProvider } from '../contexts/AtendimentoContext'
import { ResumoTecnico } from '../pages/ResumoTecnico'
import Login from '../pages/login/Login'
import RecuperarSenha from '../pages/login/RecuperarSenha'
import PrivateRoutes from './privateRoutes'

import UsuariosListar from '../pages/usuarios/Listar'
import UsuariosEditar from '../pages/usuarios/Editar'

import EndereçosListar from '../pages/enderecos/Listar'
import EnderecoConsultar from '../pages/enderecos/Consultar'
import EnderecoEditar from '../pages/enderecos/Editar'

import VeiculoListar from '../pages/veiculos/Listar'
import VeiculoEditar from '../pages/veiculos/Editar'
import VeiculoConsultar from '../pages/veiculos/Consultar'

import AtendimentosListar from '../pages/atendimento/Listar'
import AtendimentosEditar from '../pages/atendimento/Editar'
import AtendimentosConsultar2 from '../pages/atendimento/Consultar2'
import AtendimentoAtribuir from '../pages/atendimento/Atribuir'
import AtendimentoPriorizar from '../pages/atendimento/Priorizar'
import NotFound from '../pages/login/NotFound'
import { useAuth } from '../hooks/Auth'
import Ponto from '../pages/Ponto'
import { Home } from '../pages/Home'
import ConsultarSimple from '../pages/atendimento/ConsultarSimple'

export default function Routes({ ...rest }) {
  const { user } = useAuth();


    return (
      <>
        <Switch>
          <PrivateRoutes exact path="/" component={Login}/>

          <PrivateRoutes
            path="/recuperar-senha"
            component={RecuperarSenha}
          />
          
          <PrivateRoutes
            path="/recuperar-senha/:token"
            component={RecuperarSenha}
          />

          {!!user ? (
            <AtendimentoProvider>

            <Layout>
            <PrivateRoutes
              exact 
              path="/resumoTecnico" 
              component={ResumoTecnico}
              isPrivate
            />

            <PrivateRoutes
              exact
              path="/usuarios"
              component={UsuariosListar}
              isPrivate
            />
            <PrivateRoutes
              exact
              path="/usuarios/:id"
              component={UsuariosEditar}
              isPrivate
            />

            <PrivateRoutes
              exact
              path="/enderecos"
              component={EndereçosListar}
              isPrivate
            />
            <PrivateRoutes
              exact
              path="/enderecos/:id/consultar"
              component={EnderecoConsultar}
              isPrivate
            />
            <PrivateRoutes
              exact
              path="/enderecos/:id"
              component={EnderecoEditar}
              isPrivate
            />

            <PrivateRoutes
              exact
              path="/veiculos"
              component={VeiculoListar}
              isPrivate
            />
            <PrivateRoutes
              exact
              path="/veiculos/:id/consultar"
              component={VeiculoConsultar}
              isPrivate
            />
            <PrivateRoutes
              exact
              path="/veiculos/:id"
              component={VeiculoEditar}
              isPrivate
            />

              <PrivateRoutes
                exact
                path="/atendimentos"
                component={AtendimentosListar}
                isPrivate
              />
              <PrivateRoutes
                exact
                path="/home"
                component={Home}
                isPrivate
              />

              <PrivateRoutes
                exact
                path="/atendimentos/:id/consultaSimples"
                component={ConsultarSimple}
                isPrivate
              />

              <PrivateRoutes
                exact
                path="/atendimentos/:id/consultar2"
                component={AtendimentosConsultar2}
                isPrivate
              />

              <PrivateRoutes
                exact
                path="/atendimentos/:id"
                component={AtendimentosEditar}
                isPrivate
              />

              <PrivateRoutes
                exact
                path="/ponto"
                component={Ponto}
                isPrivate
              />
              <PrivateRoutes
                exact
                path="/atendimento/atribuir"
                component={AtendimentoAtribuir}
                isPrivate
              />

            <PrivateRoutes
              exact
              path="/priorizar"
              component={AtendimentoPriorizar}
              isPrivate
              />
          </Layout>
              </AtendimentoProvider>
          ) : (
            <PrivateRoutes path="*" component={NotFound} />
          )}

        </Switch>
      </>
    );
}
