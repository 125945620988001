import styled from "styled-components"

export const Container = styled.div`
    h1{
        padding-bottom: 15px;
        /* margin-bottom: 15px; */
        border-bottom: 1px solid #ddd;
        font-size: 140%;
        font-weight: 600;
    }
    h2 {
        font-size: 16px;
        /* margin-bottom: 15px; */
        color: #413F3F;
    }
    h3 {
        &.result {
            color: #413f3f;
            font-size: 15px;
            opacity: .3;
        }
    }
    form.filter{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        .divInput, .divSelect{
            min-width: 200px;
        }
        .divInput, .divSelect, .btn{
            margin-right: 20px;
        }
        .btn{
            max-width: 150px;
        }
        .btn-icon{
            position: relative;
            width: auto;
            height: 50px;
            span{
                margin-right: 10px;
            }
            .notification{
                position: absolute;
                right: -12px;
                top: -12px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                margin: 0;
                border-radius: 50%;
                background-color: #fff;
                color: var(--secondary);
                box-shadow: 0px 0px 10px #0000001A;
            }
        }
        &.justifyContent {
            justify-content: space-between;                 
        }
        .flex{
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
        }
    }
    table.default{
        width: calc(100% + 30px);
        border-collapse: collapse;
        tr {
            position: relative;
            th {
                white-space: nowrap;
            }
            th, td {
                padding: 20px;
                &.sm{
                    width: 100px;
                    max-width: 100px;
                    text-align: center;
                }
                &.center{
                    text-align: center;
                }
            }
            th {
                color: var(--font);
                text-align: left;
            }
            td {
                border-bottom: 1px solid #eee;
                opacity: .5;
                background-color: #fff;
                &:first-child{
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
            }
            &:hover{
                td{
                    opacity: 1;
                }
            }
        }
        .btn{
            margin: 0;
            padding: 0;
            svg{
                width: 18px;
                height: 18px;
                transition: .2s;
                &:hover{
                    transform: scale(1.4) rotate(180deg);
                }
            }
        }
    }
    div.table-scroll {
        width: 100%;
        overflow: auto;
        td > .order {  
            text-align: center;
            padding: 15px 20px;
            border-radius: 8px;
            background: #d8d8d8;
        }
    }
    div.content{
        max-width: 600px;
        padding: 20px;
        background-color: #fff;
        position: relative;
        .subtitle{
            font-size: 100%;
        }
    }
    div.actions{
        max-width: 600px;
        display: flex;
        justify-content: left;
        .btn{
            box-sizing: border-box;
            max-width: 200px;
            margin-right: 15px;
        }
    }
`