import React from "react"
import { IconLoading, IconSearch } from "../../layout/Svgs"
import { MaskCnpj, MaskCpf, MaskDate, MaskInteger, MaskPhone, MaskTime } from "./Mask"
import {DivInput, DivInputAlt} from "./styles"

interface Props {
    name: string
    value: any
    onChange: (key: string, value: string) => void
    onClickReset?: (key: string) => void
    type?: "text" | "password"
    label?: string
    placeholder?: string
    mask?: "cpf" | "cnpj" | "integer" | "phone" | "date" | "time" 
    theme?: "default" | "alt",
    search?: boolean
    loading?: boolean
    maxLength?: number
    readOnly?: boolean
    styles?: React.CSSProperties
}

const Input: React.FC<Props> = (Props) => {

    const onChange = (e:React.FormEvent<HTMLInputElement>) => {
        let value = e.currentTarget.value
        if( Props.mask === "cnpj" ) value = MaskCnpj(value)
        if( Props.mask === "cpf" ) value = MaskCpf(value)
        if( Props.mask === "integer" ) value = MaskInteger(value)
        if( Props.mask === "phone" ) value = MaskPhone(value)
        if( Props.mask === "date" ) value = MaskDate(value)
        if( Props.mask === "time" ) value = MaskTime(value)
        Props.onChange(Props.name, value)
    }

    const onClickReset = () => {
        Props.onChange(Props.name, "")
        if( Props.onClickReset ) Props.onClickReset(Props.name)
    }

    const content = (
        <label>

            {Props.label && <div className="label">{Props.label}</div>}

            {(Props.theme === "alt" && Props.value) && 
                <button type="button" className="reset" onClick={onClickReset}>
                    <span>Limpar</span>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="currentColor"><path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"/><path d="M14.71 9.29a1 1 0 0 0-1.42 0L12 10.59l-1.29-1.3a1 1 0 0 0-1.42 1.42l1.3 1.29l-1.3 1.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l1.29-1.3l1.29 1.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42L13.41 12l1.3-1.29a1 1 0 0 0 0-1.42z"/></g></svg>
                </button>
            }

            <div className="input-container">

                { Props.search === true && 
                    <>
                        { Props.loading === true ? 
                            <IconLoading/>
                        : 
                            <IconSearch/>
                        }
                    </>
                }

                <input type={Props.type || "text"} 
                    placeholder={Props.placeholder} 
                    value={Props.value} 
                    onChange={onChange} 
                    maxLength={Props.maxLength}
                    readOnly={Props.readOnly}
                />

            </div>
            
        </label>
    )

    return (
    <>
    {Props.theme === "alt" ? 
        <DivInputAlt style={Props.styles} className="divInput">{content}</DivInputAlt>
    :
        <DivInput className="divInput">{content}</DivInput>
    } 
    </>
    )

            
}

export default Input