import React from 'react';

import { Container, CardTitle } from './styles';

interface CardProps {
    title?: string
    children: React.ReactNode;
}

export function CardGeral({ title, children }: CardProps){
  return (
    <Container>
        {title && (
            <CardTitle>{title}</CardTitle>
        )}

        { children }
    </Container>
  );
}