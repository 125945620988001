import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/Auth';
import Routes from './routes/routes';
import { GlobalStyle } from './styles/global';

const App = () => {
    return (
       <>
        <BrowserRouter>
            <AuthProvider>
                <Routes />
            </AuthProvider>
        </BrowserRouter>
        <GlobalStyle />
   
       </>
     
    );
};

export default App;
