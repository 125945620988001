import styled from "styled-components"

export const ContainerPaginate = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    button{
        border: 1px solid rgba(0,0,0,.1);
        width: 40px;
        padding: 10px 0;
        text-align: center;
        color: var(--font);
        background-color: transparent;
        font-size: 90%;
        font-weight: 600;
        transition: .2s;
        &:hover{
            background-color: #fff;
        }
        &.active{
            font-size: 110%;
            background-color: #fff;
            color: var(--primary);
        }
    }
`