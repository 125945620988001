import styled from "styled-components"

export const DivSelect = styled.div`
    display: block;
    margin: 15px 0;
    label{
        display: block;
        width: 100%;
        font-size: 90%;
        color: var(--font);
    }
    .label{
        margin-bottom: 10px;
    }
    select{
        outline: none;
        width: 100%;
        height: 50px;
        background: transparent;
        border: none;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
`;

export const DivSelectAlt = styled.div`
    position: relative;
    display: block;
    margin: 15px 0;
    label{
        display: block;
        font-size: 90%;
        width: 100%;
        color: var(--font);
    }
    .label{
        margin-bottom: 10px;
    }
    select{
        outline: none;
        width: 100%;
        height: 50px;
        padding: 0 10px;
        background: #fff;
        border-radius: 5px;
        border: none;
        box-shadow: 0px 10px 10px rgba(0,0,0,.03);
    }
    .reset{
        display: flex;
        align-items: center;
        position: absolute;
        right: 3px;
        bottom: 17px;
        background: #fff;
        border: none;
        border-right: 6px solid #FFF;
        color: var(--danger);
        span{
            font-size: 90%;
        }
        svg{
            width: 16px;
            height: 16px;
            margin-left: 2px;
        }
    }
`;