import React, { useEffect, useState } from "react"
import Checkbox from "../../../components/form/checkbox/Checkbox"
import { ContainerModal } from "../styles"
import { useAtendimento } from "../../../contexts/AtendimentoContext"
import api from "../../../services/api"
import { ContainerBtnSearch } from "../../../components/form/btnSearch/styles"
import { IconSearch } from "../../../components/layout/Svgs"

interface Props {
    readOnly?: boolean
}

const SelecionarCategoriaDoProblema: React.FC<Props> = ({
    readOnly = false
}) => {
    
    const [modal, setModal] = useState<boolean>(false)

    const [lista, setLista] = useState<any[]>([])

    const [selecionados, setSelecionados] = useState<any[]>([])

    const {atendimento, setAtendimento} = useAtendimento()

    useEffect(() => {

        api.get("/categoria-problema").then((response) => {
            setLista([...response.data.result])
        })

    }, [])

    const onClickOpenModal = () => {

        if( !readOnly ){
            let array = atendimento.CategoriaDoProblema || []
            setSelecionados(array)
            setModal(true)
        }

    }

    const onClickCheckbox = (key:string, value:boolean) => {
        
        if( value === true ){
            selecionados.push(key)
        
        } else {

            var index = selecionados.indexOf(key)
            selecionados.splice(index, 1)

        }

        setSelecionados([...selecionados])
    }

    const handleSalvar = () => {
        delete atendimento.CategoriaDoProblema
        if( selecionados.length ) atendimento.CategoriaDoProblema = selecionados
        setAtendimento({...atendimento})
        setModal(false)
    }

    return (
    <>
        <ContainerBtnSearch>
            <label>Categoria do Problema (pode ser selecionado mais de uma categoria)</label>
            <div className="item">
                <button type="button" className="input" onClick={onClickOpenModal}>
                    {atendimento.CategoriaDoProblema ? 
                    <>
                        {lista.map((row, key) => (
                            <span key={key}>
                            {(atendimento.CategoriaDoProblema && atendimento.CategoriaDoProblema.includes(row.CategoriaProblemaId)) && 
                                <span className="tag">{row.CategoriaProblema}</span>
                            }
                            </span>
                        ))}
                    </>
                    :
                    <>
                        <IconSearch /> 
                        <span>Clique aqui para selecionar</span>
                    </>
                    }
                </button>
            </div>
        </ContainerBtnSearch>

        <ContainerModal className={`${modal === true ? "active" : ""}`}>

            <button type="button" className="modalOverlay" onClick={() => setModal(false)}></button>

            <div className="modal-box md">

                <h3 className="title">Categoria do Problema</h3>
                <p>(pode ser selecionado mais de uma categoria)</p>
                <br />

                <div className="content-overflow">
                    { lista.map((row, key) =>
                    <div key={key}>
                        <Checkbox 
                            placeholder={row.CategoriaProblema} 
                            onClick={onClickCheckbox} 
                            name={row.CategoriaProblemaId} 
                            value={selecionados.includes(row.CategoriaProblemaId)}
                        />
                    </div>
                    )}
                </div>

                <div className="content-footer">
                    <button type="button" className="btn btn-primary" onClick={handleSalvar}>Salvar</button>
                    <button type="button" className="btn btn-danger" onClick={() => setModal(false)}>Cancelar</button>
                </div>
            </div>

        </ContainerModal>

    </>
    )
}

export default SelecionarCategoriaDoProblema