/*@import '../../../Styles/Mixin.scss';*/
import styled, { css } from "styled-components"

interface RadioProps {
    active: boolean
    isDisabled?: boolean
}

const RadioContainer = styled.div<RadioProps>`
    display: flex;
    align-items: center;
    margin-right: 40px;
    cursor: pointer;

    ${({ isDisabled }) => isDisabled && css`
        opacity: 0.4;
        cursor: not-allowed;
    `}
    .circle {
        width: 20px;
        height: 20px;
        border-radius: 100px;
        border: 1px solid rgba(0,0,0,.3);
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            transition: all .2s;
            width: ${props => props.active ? '10px' : '0px'};
            height: ${props => props.active ? '10px' : '0px'};
            border: 4px solid var(--primary);
            border-radius: 50%;
            content: '';
            visibility: ${props => props.active ? 'visible' : 'hidden'};
            opacity: ${props => props.active ? '1' : '0'};
        }
    }
    .text {
        margin-left: 5px;
    }
`



export default RadioContainer