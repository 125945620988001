import React, { useEffect, useState } from "react";
import { Container } from "../../styles/layout";
import { Link } from "react-router-dom";
import { DadosTecnico, Divider, ProprietarioVeiculo } from "./styles";
import { Radio } from "../../components/form/__Radio/Radio";
import ModalTecnicos from "./components/modal/ModalTecnicos";
import message from "../../services/message";
import { useHistory } from "react-router";
import { IVeiculo } from "../../types/Veiculo";
import Input from "../../components/form/input/Input";
import Checkbox from "../../components/form/checkbox/Checkbox";
import Btn from "../../components/form/btn/Btn";
import { IUser } from "../../types/User";
import api from "../../services/api";

interface Props {
    match: any
}

const Editar: React.FC<Props> = (props) => {
    const history = useHistory<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [modalTecnicos, setModalTecnicos] = useState<boolean>(false)
    const [DTO, setDTO] = useState<IVeiculo>({
        TipoProprietario: ""
    })

    useEffect(() => {
        api.get(`veiculos/${history.location.state.id}`).then(response => {
            setDTO({...response.data.result})
        })

    }, [props])

    function handleSetTecnico(user:IUser)
    {
        DTO.UsuarioId = user.Id
        DTO.UsuarioNome = user.Nome
        DTO.UsuarioEmail = user.Email
        setDTO({...DTO})
        setModalTecnicos(false)
    }

    async function handleSubmit(e: React.FormEvent)
    {
        try {
            e.preventDefault()
            setLoading(true)
            
            if( history.location.state.id === "novo" ){
                var response = await api.post("/veiculos", DTO)
            } else {
                var response = await api.put(`/veiculos/${history.location.state.id}`, DTO)
            }
            if( response.data.error === true ) throw response.data.message
            setLoading(false)
            message.success(response.data.message)
            history.push('/veiculos')

        } catch ( err: any ) {
            setLoading(false)
            message.error(String(err))
        }
    }

    const onChangeInput = (key:string, value:string|boolean) => {

        if( key === "TipoProprietario" && value === "Técnico" ) setModalTecnicos(true)
        if( key === "TipoProprietario" && value === "Amazoncopy" ) DTO.UsuarioId = null

        const copy:any = DTO
        copy[key] = value
        setDTO({...copy})
    }

    const onClickReset = () => {
        setDTO({})
    }

    return (
        <Container>
            <h1>Veículos</h1>               

            <ProprietarioVeiculo>
                <form onSubmit={handleSubmit}>
                    <div className="content">
                        <h2 className="subtitle">Proprietário do Veículo</h2>
                        <tbody>
                            <tr>                                    
                                <td>
                                    <Radio 
                                        onChange={(key, value) => onChangeInput(key, "Amazoncopy")}
                                        value={DTO.TipoProprietario === 'Amazoncopy' ? true : false}
                                        name="TipoProprietario"
                                        placeholder="Amazoncopy"
                                    />
                                </td>
                                <td>
                                    <Radio 
                                        onChange={(key, value) => onChangeInput(key, "Técnico")}
                                        value={DTO.TipoProprietario === 'Técnico' ? true : false}
                                        name="TipoProprietario"
                                        placeholder="Técnico"
                                    />
                                </td>
                            </tr>
                        </tbody>
                        
                        { DTO.UsuarioId &&
                        <DadosTecnico>
                        <div className='technician'>
                            <div>
                                <p>Técnico</p>
                                <span>{DTO.UsuarioNome}</span>
                            </div>                               
                            <Divider />
                            <div>
                                <p>Email do Técnico</p>
                                <span>{DTO.UsuarioEmail}</span>
                            </div>
                        </div>
                        </DadosTecnico>
                        }
                    </div>

                    { DTO.TipoProprietario && 
                        <div className="content" style={{marginTop: "20px"}}>
                            <h2 className="subtitle">Dados do Veículo</h2>
                        
                            <Input placeholder="Digite aqui" label="Placa do Veículo" name="Placa" value={DTO.Placa} onChange={onChangeInput} onClickReset={onClickReset} maxLength={10} />                    

                            <Checkbox                     
                                label="Ativo"
                                placeholder="Sim"
                                name="Ativo"
                                value={DTO.Ativo ? true : false}
                                onClick={onChangeInput}
                            />  
                        </div>
                    }
                    
                    <div className="actions">
                        <Btn className="btn btn-featured" type="submit" text="Salvar" loading={loading} />
                        <Link to="/veiculos" className="btn btn-danger">Voltar</Link>
                    </div>
                    
                </form>                 
            </ProprietarioVeiculo>  

            <ModalTecnicos 
                active={modalTecnicos}
                setActive={setModalTecnicos}
                onClick={(data) => handleSetTecnico(data)}
            />
            
        </Container>
        
    )
}

export default Editar