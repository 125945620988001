import React, { useEffect, useState } from "react"
import { ContainerPaginate } from "./styles"

interface Props{
    total:number
    lastPage:number
    perPage:number
    currentPage:number
    onClickPage: (key: number) => void
}

const Paginate: React.FC<Props> = (props) => {

    const [pages, setPages] = useState<any[]>([])

    useEffect(() => {
        
        let min = (props.currentPage < 5) ? 1 : (props.currentPage - 5)
        let max = (props.currentPage < 5) ? 10 : (props.currentPage + 5)
        let pgs = []

        for(var i=1; i<=props.lastPage; i++){
            if( i >= min && i <= max ) pgs.push(i)
        }
        setPages([...pgs])

    }, [props])

    const onClickPage = (page:number) => {
        props.onClickPage(page)
    }

    return (
        <>
        {pages.length > 0 &&
        <ContainerPaginate>
            <button type="button" onClick={() => onClickPage(1)}>
                ˂
            </button>

            {pages.map((page) => (
            <button type="button" onClick={() => onClickPage(page)} className={`${props.currentPage === page && "active"}`}>
                {page}
            </button>
            ))}
            
            <button type="button" onClick={() => onClickPage(props.lastPage)}>
                ˃
            </button>
        </ContainerPaginate>
        }
        </>
    )
}

export default Paginate