import React, { useEffect } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import Input from "../../components/form/input/Input"
import Select from "../../components/form/select/Select"
import { IconAdd, IconConfigs, IconEdit } from "../../components/layout/Svgs"
import api from "../../services/api"
import { Container } from "../../styles/layout"
import { NomePlaca, VehicleDivider } from "./styles"
import Paginate from "../../components/paginate/Paginate"
import { IVeiculo } from "../../types/Veiculo"
import { useAuth } from "../../hooks/Auth"

const Incluir: React.FC = (props) => {
    const { user } = useAuth()
    const [veiculos, setVeiculos] = useState<IVeiculo[]>([])
    const [filter, setFilter] = useState<any>({
        Search: "",
        TipoProprietario: "",
        Page: 1
    })

    const [paginate, setPaginate] = useState<any>({
        total: 0,
        lastPage: 1,
        perPage: 10,
        currentPage: 1,
    })

    const filterFunc = user.funcionalidades.filter((obj) => obj.Tela === "frmVeiculo").map((row) => row.Nome)

    useEffect(() => {

        api.get("/veiculos").then((response) => {
            setVeiculos([...response.data.result])
            setPaginate({...response.data.pagination})
        })

    }, [props])

    const onChangeInput = (key:string, value:string|number) => {
        filter[key] = value
        setFilter({...filter})
    }
    
    const handleFilter = async (e:React.FormEvent) => {
        e.preventDefault()
        onChangeInput("Page", 1)
        await getVeiculos()
    }

    const onClickReset = async () => {
        await getVeiculos()
    }

    const onClickPage = async (page:number) => {
        onChangeInput("Page", page)
        await getVeiculos()
    }

    const getVeiculos = async () => {
        let response = await api.get(`/veiculos?Search=${filter.Search}&TipoProprietario=${filter.TipoProprietario}&Page=${filter.Page}`)
        setVeiculos([...response.data.result])
        setPaginate({...response.data.pagination})
    }

    return (
        <Container>
            <h1>Veículos</h1>

            <form className="filter" onSubmit={handleFilter}>                
                <NomePlaca>
                    <Input 
                        label="Nome ou Email do Técnico ou Placa do Veículo"
                        theme="alt" 
                        placeholder="Digite aqui"                         
                        name="Search" 
                        search={true}  
                        value={filter.Search}
                        onChange={onChangeInput} 
                        onClickReset={onClickReset}
                    />
                </NomePlaca>
                
                
                <div style={{width: 680}}>
                    <Select 
                        label="Tipo"
                        theme="alt"                         
                        name="TipoProprietario" 
                        value={filter.TipoProprietario} onChange={onChangeInput} onClickReset={onClickReset}>
                        <option value="">Selecione aqui</option>
                        <option value="Amazoncopy">Amazoncopy</option>
                        <option value="Técnico">Técnico</option>
                    </Select>                    
                </div>     
               
                <VehicleDivider />

                <button type="submit" className="btn btn-secondary">Filtrar</button>

                {filterFunc.includes("INCLUIR VEICULO") && (
                    <Link  
                        to={{
                            pathname: "/veiculos/novo",
                            state: { id: 'novo' }
                        }}
                        className="btn btn-featured"
                    >
                        <IconAdd />
                        <span>Incluir</span>
                    </Link>
                )}
            </form> 

            <hr />

            <table className="default">
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center' }}>Ações</th>
                        <th>Tipo</th>
                        <th>Placa</th>
                        <th>Nome do Usuário</th>                        
                        <th>Email</th>                        
                    </tr>
                </thead>

                <tbody> 
                    {veiculos.map((row, index) => (
                        <tr key={index}>
                        <td className="center">
                            <Link to={{
                                pathname: `/veiculos/${row.VeiculoId}`,
                                state: { id: row.VeiculoId }
                            }}
                            className="btn btn-link-secondary" title="Editar"><IconConfigs /></Link>
                        </td>
                        <td>{row.TipoProprietario}</td>
                        <td>{row.Placa}</td>
                        <td>{row.UsuarioNome || "--"}</td>                                                                  
                        <td>{row.UsuarioEmail || "--"}</td>                                                                  
                    </tr>
                    ))}
                </tbody>
            </table>

            <Paginate 
                total={paginate.total} 
                lastPage={paginate.lastPage} 
                perPage={paginate.perPage} 
                currentPage={filter.Page} 
                onClickPage={onClickPage}
            />

        </Container>
    )
}

export default Incluir