import styled from 'styled-components';

export const Container = styled.div`

.gm-style-iw {
    width: 300px;

    >button {
        top: 8px !important;
        right: 8px !important;
    }
}

#content {
    color: var(--title);
}

#bodyContent {
    margin-top: 10px;
    color: var(--title);
}
  
  .buttonHistory {
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;

    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    background-color: #0ABF9E;
    margin-top: 12px;
    transition: background-color 0.3s;

    :hover {
        transition: background-color 0.3s;
        background-color: hsl(169deg, 85%, 44%);

        span {
            transform: scale(1.1);
        }
    }
    
    span {
        display: grid;
        place-items: center;
        font-size: 20px;
    }
  }
`;
