import moment from "moment";
import { useEffect, useState } from "react";
import InputDate from "../../components/form/InputDate";
import Select from "../../components/form/select/Select";
import api from "../../services/api";
import message from "../../services/message";
import { Container } from "../../styles/layout";
import { IAtendimento } from "../../types/Atendimento";
import Mapa from "../atendimento/components/Mapa";
import { SectionFilterResume, SectionMapResume } from "./styles";

interface PropsTechnical {
  Nome: string;
  Id: number;
}

export function ResumoTecnico() {
  const actualDate = moment(new Date()).utc().format("DD/MM/YYYY");
  const [load, setLoad] = useState(false);
  const [technical, setTechnical] = useState<PropsTechnical[]>([]);
  const [formData, setFormData] = useState({
    tecnico: "0",
    data: actualDate,
  });

  const [attendence, setAttendence] = useState<IAtendimento[]>([]);
  const [routes, setRoutes] = useState<any[]>([]);

  useEffect(() => {
    getTechnical();
  }, []);

  useEffect(() => {
    if (formData.tecnico === "0") {
      setAttendence([]);
      setRoutes([]);
    }

    getAtendimentos();
  }, [formData]);

  async function getTechnical() {
    api
      .get("usuarios").then((resp) => {
        const filterPefil = resp.data.result.filter(
          (obj: any) => obj.PerfilId === 1,
        );
        setTechnical(filterPefil);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  }

  function handleOnChange(name: string, value: string) {
    setFormData({ ...formData, [name]: value });
  }

  function handleOnChangeDate(name: string, value: string) {
    if (!value) {
      setFormData({ ...formData, [name]: actualDate });
    } else {
      const formatDate = moment(value).utc().format("DD/MM/YYYY");
      setFormData({ ...formData, [name]: formatDate });
    }
  }

  function handleOnClearDate(name: string) {
    setFormData({ ...formData, [name]: actualDate });
  }

  async function getAtendimentos() {
    setLoad(true);
    // const response = await api.get(
    //   `atendimentos?idUsuarioTecnico=${formData.tecnico}&Status=Aberto&Order=OrdemPriorizacao&DataHoraAbertura=${formData.data}`,
    // );

    const resp = await api.get(
      `atendimentos?idUsuarioTecnico=${formData.tecnico}&Order=OrdemPriorizacao&DataHoraAbertura=${formData.data}&DataHoraAberturaFinal=${formData.data}`,
    );

    // console.log('RESP', resp.data.result.filter((obj: any) => obj.Status !== "Cancelado"))

    setAttendence(resp.data.result.filter((obj: any) => obj.Status !== "Cancelado"));

    let routes = resp.data.result.filter((obj: any) => obj.Status !== "Cancelado").map((row: any) => {
      return {
        lat: row.Latitude,
        lng: row.Longitude,
        att: row.AtendimentoId,
        cod: row.CodigoAtendimento,
        status: row.Status
      };
    });
    setRoutes([...routes]);
    setLoad(false);
  }

  // const addOffsetToDuplicateMarkers = () => {
  //   const updatedMarkers = [...routes];

  //   routes.forEach((marker, index) => {
  //     const indexOfDuplicateMarker = routes.findIndex(
  //       (m, i) => i !== index && m.lat === marker.lat && m.lng === marker.lng
  //     );

  //     if (indexOfDuplicateMarker !== -1) {
  //       const { lat, lng } = marker;
  //       const offset = 0.0005;

  //       updatedMarkers[index] = {
  //         lat: lat + offset,
  //         lng: lng + offset,
  //       };
  //     }
  //   });

  //   console.log('UPDATE-ROUTES', updatedMarkers)
  //   // setRoutes(updatedMarkers);
  // };

  // const addOffsetToDuplicateMarkers = () => {
  //   const updatedMarkers = [...routes];
  //   const duplicates: any = {};
  
  //   routes.forEach((marker, index) => {
  //     const key = `${marker.lat}-${marker.lng}`;
  //     if (duplicates[key] === undefined) {
  //       duplicates[key] = [];
  //     }
  
  //     duplicates[key].push(index);
  
  //     if (duplicates[key].length > 1) {
  //       const firstDuplicateIndex = duplicates[key][0];
  //       const { lat, lng } = updatedMarkers[firstDuplicateIndex];
  //       let offset = 0.0005;
  
  //       if (duplicates[key].length > 2) {
  //         const previousOffset = updatedMarkers[duplicates[key][duplicates[key].length - 2]].offset;
  //         offset = previousOffset + 0.0005;
  //       }
  
  //       updatedMarkers[index] = {
  //         lat: lat + offset,
  //         lng: lng + offset,
  //         // offset: offset,
  //       };
  //     }
  //   });
  
  //   console.log('UPDATE-ROUTES', updatedMarkers)
  //   setRoutes(updatedMarkers);
  // };

  // console.log('UPDATE-ROUTES', routes)


  return (
    <Container>
      <h1 className="titlePonto">Resumo Técnico</h1>

      {/* <button onClick={addOffsetToDuplicateMarkers}>
        Adicionar offset para marcadores duplicados
      </button> */}

      <SectionFilterResume>
        <Select
          theme="alt"
          placeholder="Pesquise aqui..."
          label="Técnico"
          name="tecnico"
          value={formData.tecnico}
          onChange={(name, value) => handleOnChange(name, value)}
          onClickReset={() => {}}
          styles={{ width: "100%", margin: "0px" }}
        >
          {technical.map((row) => (
            <option key={row.Id} value={row.Id}>
              {row.Nome}
            </option>
          ))}
        </Select>

        <InputDate
          mask="date"
          label="Data"
          name="Data Inicial"
          onChange={(e) => handleOnChangeDate("data", e.target.value)}
          value={formData.data}
          maxLength={10}
          handleOnClearDate={() => handleOnClearDate("data")}
        />
      </SectionFilterResume>

      <SectionMapResume>
        {routes.length > 0 && <Mapa routes={routes} id={attendence[0]?.AtendimentoId} />}
      </SectionMapResume>
    </Container>
  );
}
