import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;

    width: 100%;
    padding: 20px;

    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border-radius: 5px;
`;

export const CardTitle = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
    color: var(--title);
`
