import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionHeaderParts = styled.div`
    display: flex;
    align-items: center;
    gap: 120px;
    margin-bottom: 20px;

    .headerParts {
        font-size: 0.875rem;
        text-align: left;
        font-weight: 600;
        color: #7B7B7B;
    }
`;

export const SectionListParts = styled.div`
    display: flex;
    align-items: center;
    gap: 120px;

    .parts {
        text-align: left;
        font-size: 1rem;
        font-weight: 600;
        color: #413F3F;
    }

    .descParts {
        text-align: left;
        font-size: 1rem;
        font-weight: 500;
        color: #413F3F;
    }
`;

