import React, { useState } from "react"
import Btn from "../../../../components/form/btn/Btn"
import Input from "../../../../components/form/input/Input"
import { useAtendimento } from "../../../../contexts/AtendimentoContext"
import api from "../../../../services/api"
import { IAtendimentoEndereco } from "../../../../types/Atendimento"
import { ContainerModal } from "../../styles"

interface Props {
    active: boolean
    setActive: (bool:boolean) => any
    onClick: (endereco:IAtendimentoEndereco) => void
}

const ModalEnderecos:React.FC<Props> = ({
    active,
    setActive, 
    onClick
}) => {

    const {atendimento} = useAtendimento()
    const [loading, setLoading] = useState(false)
    const [noResult, setNoResult] = useState(false)
    const [filter, setFilter] = useState({
        search: "",
    })

    const [lista, setLista] = useState<IAtendimentoEndereco[]>([])

    const onChangeFilter = (key:string, value:string|boolean) => {
        let input:any = filter
        input[key] = value
        setFilter({...input})
    }
    const handleSearch = async (e:React.FormEvent) => {
        e.preventDefault()
        try {
            setLoading(true)
            const response = await api.get(`clientes-enderecos?Search=${filter.search}&CodigoCliente=${atendimento.CodigoCliente}&CodigoLojaCliente=${atendimento.CodigoLojaCliente}`)
            setLista([...response.data.result])
            if( !response.data.result.length ) setNoResult(true)
            setLoading(false)
            
        } catch(err){
            console.log(err)
            setLoading(false)
            setNoResult(true)
        }
    }

    const handleOnResetFilter = () => {
        setFilter({
            search: "",
        })
        setLista([]);
    }
    
    return (
        <ContainerModal className={` ${active === true ? "active" : ""}`}>

            <button type="button" className="modalOverlay" onClick={() => setActive(false)}></button>

            <div className="modal-box">

                <div className="head-form">
                    
                    <p className="subtitle">Cliente: <span className="color-primary">{atendimento.NomeCliente}</span></p>

                    <form onSubmit={handleSearch}>
                        
                        <Input
                            placeholder="Nome do local, logradouro ou cidade"
                            value={filter.search}
                            name="search"
                            onChange={(name, value) => onChangeFilter(name, value)}
                            theme="alt"
                            search={true}
                            loading={loading}
                            onClickReset={handleOnResetFilter}
                        />

                        <Btn text="Buscar" loading={loading} className="btn btn-featured" />

                        <button type="button" className="btn" onClick={() => setActive(false)}>
                            <span>Fechar</span>
                        </button>

                    </form>
                    
                </div>

                <div className="content-table">
                    {noResult === true && <p>Não foi encontrado nenhum resultado para a pesquisa.</p>}

                    { lista.length > 0 && 
                    <table className="default">
                        <thead>
                            <tr>
                                <th>Nome do Local</th>
                                <th>CEP</th>
                                <th>Logradouro</th>
                                <th>Número</th>
                                <th>Bairro</th>
                                <th>Cidade</th>
                                <th>UF</th>
                                <th>Ponto de Referência</th>
                            </tr>
                        </thead>
                        <tbody>
                            { lista.map((row, key) =>
                                <tr key={key} onClick={() => onClick(row)}>
                                    <td>{row.NomeLocal}</td>
                                    <td>{row.CEP}</td>
                                    <td>{row.Logradouro}</td>
                                    <td>{row.Numero}</td>
                                    <td>{row.Bairro}</td>
                                    <td>{row.Cidade}</td>
                                    <td>{row.UF}</td>
                                    <td>{row.PontoReferencia}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    }
                </div>

            </div>
        </ContainerModal>
    )
}

export default ModalEnderecos