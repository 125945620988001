import React, { useState } from "react"
import Btn from "../../../../components/form/btn/Btn"
import Input from "../../../../components/form/input/Input"
import protheus from "../../../../services/protheus"
import { IMaquina } from "../../../../types/Maquina"
import { ContainerModal } from "../../styles"

interface Props {
    active: boolean
    setActive: (bool:boolean) => any
    onClick: (maquina:IMaquina) => void
}

const ModalMaquinas:React.FC<Props> = (props) => {

    const [loading, setLoading] = useState(false)

    const [noResult, setNoResult] = useState(false)

    const [filter, setFilter] = useState({
        clientName: "",
        serieNumber: ""
    })

    const [lista, setLista] = useState<IMaquina[]>([])

    const onChangeFilter = (key:string, value:string|boolean) => {
        let input:any = filter
        input[key] = value
        setFilter({...input})
    }

    const handleSearch = async (e:React.FormEvent) => {
        
        e.preventDefault()

        try {

            setNoResult(false)
            setLoading(true)
            let response = await protheus.get(`machines?limit=1000&clientName=${filter.clientName}&serieNumber=${filter.serieNumber}`)
            let array = response.data.result
            if( !array.length ) setNoResult(true)
            setLista([...array])
            setLoading(false)
            
        } catch(err){
            console.log(err)
            setLoading(false)
            setNoResult(true)
        }
        
    }

    return (
        <ContainerModal className={` ${props.active === true ? "active" : ""}`}>

            <button type="button" className="modalOverlay" onClick={() => props.setActive(false)}></button>

            <div className="modal-box">

                <div className="head-form">

                    <form onSubmit={handleSearch}>
                        
                        <Input
                            label="Número de Série"
                            placeholder="Ex: BND3QWT43"
                            value={filter.serieNumber}
                            name="serieNumber"
                            onChange={(name, value) => onChangeFilter(name, value)}
                            theme="alt"
                        />

                        <Input
                            label="Nome do Cliente"
                            placeholder="Ex: Carrefour"
                            value={filter.clientName}
                            name="clientName"
                            onChange={(name, value) => onChangeFilter(name, value)}
                            theme="alt"
                        />

                        <Btn text="Buscar" loading={loading} className="btn btn-featured" />

                        <button type="button" className="btn" onClick={() => props.setActive(false)}>
                            <span>Fechar</span>
                        </button>

                    </form>
                    
                </div>

                <div className="content-table">
                    {noResult === true && <p>Não foi encontrado nenhum resultado para a pesquisa.</p>}

                    { lista.length > 0 && 
                    <table className="default">
                        <thead>
                            <tr>
                                <th>Número de Série</th>
                                <th>Marca</th>
                                <th>Modelo</th>
                                <th>Cod. Cliente</th>
                                <th>Loja</th>
                                <th>Cliente</th>
                                <th>Contrato</th>
                                <th>Tipo</th>
                            </tr>
                        </thead>
                        <tbody>
                            { lista.map((row, key) =>
                                <tr key={key} onClick={() => props.onClick(row)}>
                                    <td>{row.NROSerie}</td>
                                    <td>{row.FabricanteMaquina}</td>
                                    <td>{row.DescricaoModeloMaquina}</td>
                                    <td>{row.CodigoCliente}</td>
                                    <td>{row.CodigoLojaCliente}</td>
                                    <td>{row.NomeCliente}</td>
                                    <td>{row.NROContrato}</td>
                                    <td>{row.TipoContrato}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    }
                </div>

            </div>
        </ContainerModal>
    )
}

export default ModalMaquinas