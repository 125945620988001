import React from "react"
import { IconLoading } from "../../layout/Svgs"

interface Props {
    text: string
    loading?: boolean
    type?: "submit" | "button" | "reset"
}

const Btn: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({...Props}) => {
    return (
        <button {...Props} type={Props.type ? Props.type : "submit"}>
            {Props.loading === true && <IconLoading/>}
            <span>{Props.text}</span>
        </button>
    )
}

export default Btn