import React, { useEffect } from "react"
import { useState } from "react"
import Input from "../../components/form/input/Input"
import {Container} from "./styles"
import logo from "../../assets/images/logo.svg"
import api from "../../services/api"
import { Link, useHistory, useLocation } from "react-router-dom"
import Message from "../../services/message"
import Btn from "../../components/form/btn/Btn"

const RecuperarSenha: React.FC<any> = (props) => {
    const history = useHistory<any>();
    const { pathname } = useLocation()
    const [loading, setLoading] = useState<boolean>(false)

    const [auth, setAuth] = useState<any>({
        EmailEnviado: false,
        Email: "",
        Token: "",
        Senha: "",
        SenhaConfirmar: "",
    })

    useEffect(() => {
        auth.Token = pathname.split('/', 3)[2]
        setAuth({...auth})
    }, [props])

    const onChangeInput = (key:string, value:string) => {
        auth[key] = value
        setAuth({...auth})
    }

    const handleSubmitStep1 = async (e: React.FormEvent) => {
        try {
            e.preventDefault()
            setLoading(true)
            let response = await api.post("/auth/recover-password/step1", auth).catch((error) => {
                throw error.response.data.message
            })
            setLoading(false)
            if( response.data.error === true ) throw response.data.message
            
            auth.EmailEnviado = true
            setAuth({...auth})

        } catch(message){
            Message.error(String(message))
        }
    }

    const handleSubmitStep2 = async (e: React.FormEvent) => {
        try {
            e.preventDefault()
            setLoading(true)
            let response = await api.post("/auth/recover-password/step2", auth).catch((error) => {
                throw error.response.data.message
            })
            setLoading(false)
            if( response.data.error === true ) throw response.data.message
            Message.success('Senha alterada com sucesso!')
            history.push("/login")

        } catch(message){
            Message.error(String(message))
        }
    }

    return (
        <Container>

            {!auth.Token ?
            <form onSubmit={handleSubmitStep1}>
                <img src={logo} alt="Amazoncopy" />

                {auth.EmailEnviado ? 
                <>
                <p className="description">Email enviado!</p>
                <p>Agora é só seguir os passos de cadastro enviados para o seu email</p>
                <br/>
                <Link to="/login" className="btn btn-primary">Ok!</Link>
                </>
                :
                <>
                <p className="description">Esqueceu a senha?</p>
                <p>Enviaremos para o seu e-mail o passo a passo da recuperação de senha</p>
                <Input placeholder="Digite seu email aqui" label="Email" name="Email" value={auth.Email} onChange={onChangeInput} />
                <br/>
                <Btn className="btn btn-primary" text="Enviar" loading={loading} />
                </>
                }
            </form>
            
            :

            <form onSubmit={handleSubmitStep2}>
                <img src={logo} alt="Amazoncopy" />
                <p className="description">Criar senha</p>
                <p>A senha deve conter no mínimo 6 caracteres com pelo menos uma letra maiúscula e um número</p>
                <Input type="password" placeholder="Digite sua senha" label="Senha" name="Senha" value={auth.Senha} onChange={onChangeInput} />
                <Input type="password" placeholder="Confirme aqui sua senha" label="Confirmar senha" name="SenhaConfirmar" value={auth.SenhaConfirmar} onChange={onChangeInput} />
                <br/>
                <Btn className="btn btn-primary" text="Confirmar" loading={loading} />
            </form>
            }

        </Container>
    )
}

export default RecuperarSenha