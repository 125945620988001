export const MaskCnpj = (value:string) => {
    return value
    .replace(/\D/g, '')
}

export const MaskCpf = (value:string) => {
    return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const MaskInteger = (value:string) => {
    return value
    .replace(/\D/g, '') //apenas numeros 0-9
}

export const MaskPhone = (value:string) => {
    return value
    .replace(/\D/g, '') //apenas numeros 0-9
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const MaskPhone13 = (value:string) => {
    return value
    .replace(/\D/g, '') //apenas numeros 0-9
    .replace(/(\d{2})(\d{2})(\d)/, '$1 ($2) $3')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const MaskDate = (value:string) => {
    return value
    .replace(/\D/g, '') //apenas numeros 0-9
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

export const MaskTime = (value:string) => {
    return value
    .replace(/\D/g, '') //apenas numeros 0-9
    .replace(/(\d{2})(\d)/, '$1:$2')
    .replace(/(:\d{2})\d+?$/, '$1')
}