import styled from "styled-components";

export const InputAddress = styled.div`
    max-width: 100% !important;
    margin-right: 10px; 
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    input {
        width: 60vw !important;        
    }

    .btn.btn-featured {
        width: 290px !important;
    }
`

export const Cliente = styled.div`
    .content {
        display: flex;
        flex-direction: column;        
        margin-bottom: 8px;
        border-radius: 5px;
        padding: 0!important;
        .client-address {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .item{
                min-width: 10%;
            }
            p {
                color: var(--font);
            }                     
            span {
                color: #413f3f;
                font-weight: 600;  
            }   
        }
        .client {
            input {
                margin-top: 10px;
                margin-right: 20px;
            }
            span {
                color: #413f3f;
                font-weight: 600;
            }
            .address {
                margin-bottom: 35px;
                p {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }   
        }
    }
`

export const ClientData = styled.div`
    max-width: 930px;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    div.client {
        max-width: 930px;
    }    
`

export const Buttons = styled.div`
    .buttons{
        display: flex;
        max-width: 800px;
        gap: 100px;
    }
`

export const Divider = styled.div`
    width: 1px;
    background-color: #D5D5D5;
    margin-left: 10px;
    margin-right: 5px;
    height: 30px;
    margin-bottom: 5px;
`

export const AddressDivider = styled.div`
    width: 1px;
    background-color: #D5D5D5;
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    margin-bottom: 22px;
`

export const ContainerModal = styled.div`
    .modalOverlay{
        cursor: default;
        z-index: 9;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.8);
        border: none;
        overflow: auto;
        visibility: hidden;
        opacity: 0;
        transition: .4s;
    }
    .modal-box{
        box-sizing: border-box;
        z-index: 9999;
        position: fixed;
        width: 1492px;
        max-width: 90%;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 60px 70px;
        opacity: 0;
        visibility: hidden;
        transition: all .5s;
        &.md{
            width: 600px;
        }
        .head-form {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 30px;
            form {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .divInput {
                    width: 70%;
                    margin-bottom: 0px;
                    margin-right: 20px;
                }
                .btn {
                    width: 170px;
                    margin-bottom: 0px;
                }
            }
        }
        .content-table {
            max-height: 50vh;
            width: 100%;
            overflow: auto;
            table {
                width: 100%;
                td {
                    cursor: pointer;
                }
            }
        }
        .content-overflow {
            box-sizing: border-box;
            padding: 15px;
            height: 350px;
            overflow-y: scroll;
            background-color: #eee;
        }
        .content-footer{
            display: flex;
            justify-content: space-between;
            .btn{
                margin: 20px 10px 0 10px;
            }
        }
    }
    &.active{
        .modalOverlay{
            visibility: visible;
            opacity: 1;
        }
        .modal-box{
            opacity: 1;
            visibility: visible;
            top: 50%;
        }
    }
`

export const ButtonReset = styled.button`
    display: flex;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 17px;
    background: none;
    border: none;
    border-right: 6px solid #FFF;
    color: var(--danger);
    span{
        font-size: 90%;
    }
    svg{
        width: 16px;
        height: 16px;
        margin-left: 2px;
    }
`