import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import moment from "moment";

import { Container } from "../../styles/layout";
import { ContainerAtendimentos, ListInfo } from "./styles";
import { CardGeral } from "./components/Cards/CardGeral";
import FieldTextDefault from "./components/Cards/FieldTextDefault";
import { useAtendimento } from "../../contexts/AtendimentoContext";
import api from "../../services/api";
import Textarea from "../../components/form/textarea/Textarea";
import { Link } from "react-router-dom";
import SelecionarCategoriaDoProblema from "./components/SelecionarCategoriaDoProblema";
import Checkbox from "../../components/form/checkbox/Checkbox";
import SelecionarSoftware from "./components/SelecionarSoftware";

interface Props {
  match: any;
}

const ConsultarSimple: React.FC<Props> = (props) => {
  const history = useHistory<any>();
  const { id } = useParams<any>();
  const { atendimento, setAtendimento } = useAtendimento();
  const [loading, setLoading] = useState<boolean>(false);
  const dateTime = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");

  useEffect(() => {
    api.get(`atendimentos/${id}`).then((response) => {
      setAtendimento({ ...response.data.result });
    });
  }, []);

  return (
    <Container>
      <ContainerAtendimentos>
        <h1>
          Atendimento / <b>Consultar Atendimento</b>
        </h1>

        <ListInfo style={{ marginTop: "2rem" }}>
          <CardGeral title="Dados do Atendimento">
            <FieldTextDefault
              title="Código do Atendimento"
              subTitle={atendimento.CodigoAtendimento}
            />

            <FieldTextDefault title="Status" subTitle={atendimento.Status} />

            <FieldTextDefault
              title="Data e Hora de Abertura"
              subTitle={
                moment(atendimento.DataHoraAbertura).format(
                  "DD/MM/YYYY HH:mm"
                ) + "h"
              }
            />

            <FieldTextDefault
              title="Técnico"
              subTitle={atendimento.NomeTecnico}
            />
          </CardGeral>

          {/* TIPO */}

          <CardGeral>
            <FieldTextDefault
              title="Tipo de Atendimento"
              subTitle={atendimento.TipoAtendimento}
            />
          </CardGeral>

          {/* ENDEREÇO */}

          <CardGeral title="Máquina">
            {atendimento.TipoAtendimentoId !== 6 &&
              atendimento.TipoAtendimentoId !== 7 && (
                <>
                  <FieldTextDefault
                    title="Número de Série"
                    subTitle={atendimento.NroSerieMaquina}
                  />

                  <FieldTextDefault
                    title="Marca"
                    subTitle={atendimento.FabricanteMaquina}
                  />

                  <FieldTextDefault
                    title="Modelo"
                    subTitle={atendimento.DescricaoModeloMaquina}
                  />
                </>
              )}

            <FieldTextDefault
              title="Código do Cliente"
              subTitle={atendimento.CodigoCliente}
            />

            <FieldTextDefault
              title="Loja"
              subTitle={atendimento.CodigoLojaCliente}
            />

            <FieldTextDefault
              title="Cliente"
              subTitle={atendimento.NomeCliente}
            />

            <FieldTextDefault
              title="Número do contrato"
              subTitle={atendimento.NroContrato}
            />

            <FieldTextDefault
              title="Tipo do Contrato"
              subTitle={atendimento.TipoContrato}
            />
          </CardGeral>

          {/* ENDEREÇO */}

          <CardGeral title="Endereço">
            <FieldTextDefault
              title="Local / Endereço"
              subTitle={atendimento.NomeLocal}
            />

            <FieldTextDefault
              title="CEP"
              subTitle={atendimento.AtendimentoEndereco?.CEP}
            />

            <FieldTextDefault
              title="Logradouro"
              subTitle={atendimento.AtendimentoEndereco?.Logradouro}
            />

            <FieldTextDefault
              title="Número"
              subTitle={atendimento.AtendimentoEndereco?.Numero}
            />

            <FieldTextDefault
              title="Bairro"
              subTitle={atendimento.AtendimentoEndereco?.Bairro}
            />

            <FieldTextDefault
              title="Zona"
              subTitle={atendimento.AtendimentoEndereco?.Zona}
            />

            <FieldTextDefault
              title="UF"
              subTitle={atendimento.AtendimentoEndereco?.UF}
            />

            <FieldTextDefault
              title="Local / Endereço"
              subTitle={atendimento.AtendimentoEndereco?.PontoReferencia}
            />

            <FieldTextDefault
              title="Complemento do Local"
              subTitle={atendimento.AtendimentoEndereco?.Complemento}
            />
          </CardGeral>

          {atendimento.TipoAtendimentoId === 1 && (
            <CardGeral>
              <Textarea
                readOnly
                label="Observação geral para o Atendimento"
                name="detailsProblema"
                onChange={() => {}}
                value={atendimento.ObservacaoParaAtendimento}
              />
            </CardGeral>
          )}

          {atendimento.TipoAtendimentoId === 7 && (
            <CardGeral>
              <Textarea
                readOnly
                label="Observação geral para o Atendimento"
                name="detailsProblema"
                onChange={() => {}}
                value={atendimento.ObservacaoParaAtendimento}
              />

              <FieldTextDefault
                title="Quem solicitou"
                subTitle={atendimento.NomeSolicitante}
              />

              <FieldTextDefault title="Motivo" subTitle={atendimento.Motivo} />
            </CardGeral>
          )}

          <CardGeral>
            <FieldTextDefault
              title="Nome Técnico"
              subTitle={atendimento.NomeTecnico}
            />
          </CardGeral>

          {atendimento.TipoAtendimentoId === 6 && (
            <CardGeral>
              <Textarea
                readOnly
                label="Observação geral para o Atendimento"
                name="detailsProblema"
                onChange={() => {}}
                value={atendimento.ObservacaoParaAtendimento}
              />

              <FieldTextDefault
                title="Categoria de entrega"
                subTitle={atendimento.CategoriaEntregaId}
              />

              <FieldTextDefault
                title="Nome do documento"
                subTitle={atendimento.NomeDocumento}
              />

              <FieldTextDefault
                title="Numero documento"
                subTitle={atendimento.NroDocumento}
              />
            </CardGeral>
          )}

          {/* RETORNO */}

          {(atendimento.TipoAtendimentoId === 2 ||
            atendimento.TipoAtendimentoId === 3 ||
            atendimento.TipoAtendimentoId === 4 ||
            atendimento.TipoAtendimentoId === 5) && (
            <>
              <CardGeral title="Retorno">
                {atendimento.Retorno === "1" && (
                  <FieldTextDefault
                    title="Código atendimento anterior"
                    subTitle={atendimento.CodigoAtendimentoAnterior}
                  />
                )}

                <FieldTextDefault
                  title="Nome solicitante"
                  subTitle={atendimento.NomeSolicitante}
                />

                <FieldTextDefault
                  title="DDD do solicitante"
                  subTitle={atendimento.DDDSolicitante}
                />

                <FieldTextDefault
                  title="Telefone do solicitante"
                  subTitle={atendimento.TelefoneSolicitante}
                />

                <FieldTextDefault
                  title="Email do solicitante"
                  subTitle={atendimento.EmailSolicitante}
                />

                <FieldTextDefault
                  title="Setor do solicitante"
                  subTitle={atendimento.SetorSolicitante}
                />

                {atendimento.TipoAtendimentoId === 4 && (
                  <>
                    <FieldTextDefault
                      title="Rede Eletrica"
                      subTitle={atendimento.RedeEletrica}
                    />

                    <Checkbox
                      label="Tem estabilizador"
                      name="PossuiEstabilizador"
                      value={atendimento.PossuiEstabilizador ? true : false}
                      onClick={() => {}}
                      readOnly={true}
                    />

                    {atendimento.PossuiEstabilizador === true && (
                      <FieldTextDefault
                        title="Numero série Estabilizador"
                        subTitle={atendimento.NroSerieEstabilizador}
                      />
                    )}
                    {/* <SelecionarSoftware readOnly={true} /> */}
                  </>
                )}

                {atendimento.TipoAtendimentoId === 2 && (
                  <>
                    <SelecionarCategoriaDoProblema readOnly={true} />

                    <Textarea
                      readOnly
                      label="Detalhamento do problema"
                      name="detailsProblema"
                      onChange={() => {}}
                      value={atendimento.DetalheProblema}
                    />
                  </>
                )}

                {atendimento.TipoAtendimentoId === 3 && (
                  <FieldTextDefault
                    title="Data Hora Agendamento"
                    subTitle={
                      moment(atendimento.DataHoraAgendamento).format(
                        "DD/MM/YYYY HH:mm"
                      ) + "h"
                    }
                  />
                )}
              </CardGeral>
            </>
          )}

          {atendimento.TipoAtendimentoId !== 2 && (
            <>
              <CardGeral title="Dados Adicionais">
                <FieldTextDefault
                  title="Complemento do Local"
                  subTitle={atendimento.ComplementoLocal}
                />

                <Textarea
                  readOnly
                  label="Observação geral para o atendimento"
                  name="obsAtendimento"
                  onChange={() => {}}
                  value={atendimento.ObservacaoParaAtendimento}
                />
              </CardGeral>
            </>
          )}
        </ListInfo>

        <Link
          to="/atendimentos"
          className="btn btn-danger"
          style={{ maxWidth: "320px" }}
        >
          Voltar
        </Link>
      </ContainerAtendimentos>
    </Container>
  );
};

export default ConsultarSimple;
