import React, { useState } from "react"
import BtnSearch from "../../../components/form/btnSearch/BtnSearch"
import { useAtendimento } from "../../../contexts/AtendimentoContext"
import { IMaquina } from "../../../types/Maquina"
import ModalMaquinas from "./modal/ModalMaquinas"

interface Props {
    readOnly?: boolean
}

const PesquisarMaquina: React.FC<Props> = ({
    readOnly = false
}) => {
    
    const [modal, setModal] = useState<boolean>(false)

    const {atendimento, setAtendimento} = useAtendimento()

    const onClickMaquina = (maquina:IMaquina) => {
        atendimento.CnpjCliente = maquina.CNPJCliente
        atendimento.CodigoCliente = maquina.CodigoCliente
        atendimento.CodigoLojaCliente = maquina.CodigoLojaCliente
        atendimento.CodigoModeloMaquina = maquina.CodigoModeloMaquina
        atendimento.DescricaoModeloMaquina = maquina.DescricaoModeloMaquina
        atendimento.FabricanteMaquina = maquina.FabricanteMaquina
        atendimento.NroContrato = maquina.NROContrato
        atendimento.NroSerieMaquina = maquina.NROSerie
        atendimento.NomeCliente = maquina.NomeCliente
        atendimento.TipoContrato = maquina.TipoContrato
        setAtendimento({...atendimento})
        setModal(false)
    }

    const onClickReset = () => {
        atendimento.CnpjCliente = ""
        atendimento.CodigoCliente = ""
        atendimento.CodigoLojaCliente = ""
        atendimento.CodigoModeloMaquina = ""
        atendimento.DescricaoModeloMaquina = ""
        atendimento.FabricanteMaquina = ""
        atendimento.NroContrato = ""
        atendimento.NroSerieMaquina = ""
        atendimento.NomeCliente = ""
        atendimento.TipoContrato = ""
        atendimento.AtendimentoEndereco = {}
        setAtendimento({...atendimento})
    }

    return (
    <>
    <div className="container-infos">

        <p className="title">Máquina</p>

        {!readOnly && 
        <BtnSearch 
            onReset={onClickReset} 
            onClick={() => setModal(true)} 
            label="Número de Série" 
            placeholder="Clique aqui para pesquisar" 
            value={atendimento.NroSerieMaquina}
        />
        }

        { atendimento.NroSerieMaquina && 
            <div className="list-infos">

                {readOnly && 
                <div className="info">
                    <span className="title">Número de Série</span>
                    <span className="value">{atendimento.NroSerieMaquina}</span>
                </div>
                }

                <div className="info">
                    <span className="title">Marca</span>
                    <span className="value">{atendimento.FabricanteMaquina}</span>
                </div>

                <div className="info">
                    <span className="title">Modelo</span>
                    <span className="value">{atendimento.DescricaoModeloMaquina}</span>
                </div>

                <div className="info">
                    <span className="title">Código do Cliente</span>
                    <span className="value">{atendimento.CodigoCliente}</span>
                </div>

                <div className="info">
                    <span className="title">Loja</span>
                    <span className="value">{atendimento.CodigoLojaCliente}</span>
                </div>

                <div className="info">
                    <span className="title">Cliente</span>
                    <span className="value">{atendimento.NomeCliente}</span>
                </div>

                <div className="info">
                    <span className="title">Número do Contrato</span>
                    <span className="value">{atendimento.NroContrato}</span>
                </div>

                <div className="info">
                    <span className="title">Tipo do Contrato</span>
                    <span className="value">{atendimento.TipoContrato}</span>
                </div>

            </div>
        }

    </div>

    <ModalMaquinas 
        active={modal}
        setActive={setModal}
        onClick={onClickMaquina}
    />
    </>
    )
}

export default PesquisarMaquina