import React from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from "react-router-dom";
import { useAuth } from "../hooks/Auth";


interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: any;
}

const PrivateRoutes: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{ pathname: isPrivate ? "/" : "/home", state: { from: location } }}
          />
        );
      }}
    />
  );
};

export default PrivateRoutes;