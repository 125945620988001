import React, { useEffect } from "react"
import { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import Btn from "../../components/form/btn/Btn"
import Checkbox from "../../components/form/checkbox/Checkbox"
import Input from "../../components/form/input/Input"
import Select from "../../components/form/select/Select"
import { useAuth } from "../../hooks/Auth"
import api from "../../services/api"
import Message from "../../services/message"
import { Container } from "../../styles/layout"
import { IPerfil } from "../../types/Perfil"

interface Props {
    match: any
}

const Editar: React.FC<Props> = (props) => {
    const history = useHistory<any>()
    const { user, getUser } = useAuth()
    const [perfis, setPerfis] = useState<IPerfil[]>([])
    const [DTO, setDTO] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        api.get(`/usuarios/${history.location.state.id}`).then((response) => {
            setDTO({...response.data.result})
        })

        api.get("/perfis").then((response) => {
            setPerfis([...response.data.result])
        })
    }, [props])

    const onChangeInput = (key:string, value:string|boolean) => {
        DTO[key] = value
        setDTO({...DTO})
    }

    const handleOnSuccess = async (msg: any) => {

        let bool = await Message.confirm({
            title: 'Sucesso!',
            text: msg,
            isCancel: false
        })

        if ( bool ) history.push('/usuarios')

    }

    const handleSave = async (e:React.FormEvent) => {
        e.preventDefault()
        try {
            let response:any = {}
            setLoading(true)
            if( DTO.Id ){
                response = await api.put(`/usuarios/${DTO.Id}`, DTO)
            } else {
                response = await api.post("/usuarios", DTO)

            }

            if(DTO.Id === user.Id) {
                getUser(DTO.Id)
            }

            setLoading(false)

            
            if( response.data.error === true ) throw response.data.message
            // Message.success(String(response.data.message))

            handleOnSuccess(String(response.data.message));
            // history.push("/usuarios")
            
        } catch(message: any){
            console.log('MESSAGE', message)

            Message.error(message.response.data.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <h1>Usuários | {DTO.Id ? "Editar" : "Adicionar"}</h1>

            <form onSubmit={handleSave}>

                <div className="content">
                    <h2 className="subtitle">Dados do Usuário</h2>

                    <Input placeholder="Digite aqui" label="Nome" name="Nome" value={DTO.Nome} onChange={onChangeInput} />

                    <Input placeholder="Digite aqui" label="Email" name="Email" value={DTO.Email} onChange={onChangeInput} />
                    
                    <Select label="Tipo" name="PerfilId" value={DTO.PerfilId} onChange={onChangeInput}>
                        <option value="">Selecione aqui</option>
                        {perfis.map((perfil, key) => (
                            <option value={perfil.Id} key={key}>{perfil.Nome}</option>
                        ))}
                    </Select>

                    {DTO.Id && <Checkbox label="Ativo" placeholder="Sim" name="Ativo" value={DTO.Ativo} onClick={onChangeInput} />}
                </div>

                <div className="actions">
                    <Btn className="btn btn-primary" text="Salvar" loading={loading} />
                    <Link to="/usuarios" className="btn btn-danger">Cancelar</Link>
                </div>

            </form>

        </Container>
    )
}

export default Editar