import React from "react"
import { useHistory } from "react-router-dom"
import {Container} from "./styles"

const NotFound: React.FC = () => {
    const history = useHistory()
    return (
        <Container>
            <button 
                style={{
                    padding: '16px 22px', 
                    borderRadius: '12px', 
                    border: 'none', 
                    backgroundColor: 'lightgreen', 
                    color: '#fff', 
                    fontWeight: 'bold', 
                    fontSize: '1.35rem'
                }}
                onClick={() => history.push('/')}
            >
                Voltar para login
            </button>
        </Container>
    )
}

export default NotFound