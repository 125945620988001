import React from "react"
import Input from "../../../components/form/input/Input"
import Textarea from "../../../components/form/textarea/Textarea"
import { useAtendimento } from "../../../contexts/AtendimentoContext"
import { IAtendimento } from "../../../types/Atendimento"
import { ContainerTipo } from "../styles"
import PesquisarEndereco from "./PesquisarEndereco"
import PesquisarMaquina from "./PesquisarMaquina"

interface Props {
    readOnly?: boolean;
    atendimento: IAtendimento;
    handleOnChange: (key: any, value: any) => void
}

const Desinstalacao: React.FC<Props> = ({
    readOnly = false,
    atendimento,
    handleOnChange
}) => {
    
    // const {atendimento, setAtendimento} = useAtendimento()

    // const onChangeInput = (key:string, value:any) => {
    //     let input:any = atendimento
    //     input[key] = value
    //     setAtendimento({...input})
    // }

    return (
    <ContainerTipo className={readOnly ? "readOnly": ""}>
        <PesquisarMaquina readOnly={readOnly} />

        {atendimento.NroContrato && <PesquisarEndereco readOnly={readOnly} />}

        {atendimento.AtendimentoEndereco?.NomeLocal &&
        <div className="container-infos">
            <p className="title">Dados Adicionais</p>

            <Input label="Complemento do Local *" placeholder="Digite aqui..."  name="ComplementoLocal" value={atendimento.ComplementoLocal} onChange={handleOnChange} readOnly={readOnly} />

            <Textarea label="Observação geral para o atendimento" placeholder="Digite aqui..." name="ObservacaoParaAtendimento" value={atendimento.ObservacaoParaAtendimento} onChange={handleOnChange} readOnly={readOnly} />

            <Input label="Nome do Solicitante *" placeholder="Digite aqui..." name="NomeSolicitante" value={atendimento.NomeSolicitante} onChange={handleOnChange} readOnly={readOnly} />
            <Input label="Email do Solicitante *" placeholder="Digite aqui..." name="EmailSolicitante" value={atendimento.EmailSolicitante} onChange={handleOnChange} readOnly={readOnly} />
            <Input label="Telefone do Solicitante *" placeholder="Digite aqui..." name="TelefoneSolicitante" value={atendimento.TelefoneSolicitante} onChange={handleOnChange} mask="phone" readOnly={readOnly} />
            <Input label="Setor do Solicitante *" placeholder="Digite aqui..." name="SetorSolicitante" value={atendimento.SetorSolicitante} onChange={handleOnChange} readOnly={readOnly} />

        </div>
        }
    </ContainerTipo>
    )
}

export default Desinstalacao