import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import api from "../../services/api"
import moment from "moment"
import { useAtendimento } from "../../contexts/AtendimentoContext"

//ICONS
import { IconAdd, IconFile, IconRemove } from "../../components/layout/Svgs"

//COMPONENTS
import { CardGeral } from "./components/Cards/CardGeral"
import FieldTextDefault from "./components/Cards/FieldTextDefault"
import Textarea from "../../components/form/textarea/Textarea"
import ModalDefault from "../../components/ModalDefault"
import Checkbox from "../../components/form/checkbox/Checkbox"
import { ListParts } from "./components/Cards/ListParts"

//STYLES
import { Container } from "../../styles/layout"
import { 
    ContainerAtendimentos, 
    CustomerService, 
    TabsService, 
    ListInfo,
    SectionPreviewImage,
    ContainerModalAnalise
} from "./styles"
import { Radio } from "../../components/form/__Radio/Radio"
import TextareaDefault from "../../components/form/TextareaDefault"
import message from "../../services/message"
import { checkAccessToken } from "../../utils/checkAccessToken"

const Consultar2: React.FC = () => {
    const {atendimento, setAtendimento} = useAtendimento();
    const [activeTab, setActiveTab] = useState('tabGeral');
    const [base64, setBase64] = useState('');
    const signatureImg = atendimento.fotos?.filter((obj) => obj.Titulo === "Assinatura")[0]?.CaminhoFoto;

    console.log('IMAGE =>', !!signatureImg)

    //--Modal
    const [modalPreview, setModalPreview] = useState(false);
    const [modalReturn, setModalReturn] = useState(false);
    const [modalAnalise, setModalAnalise] = useState(false);

    //--Radio
    const [radioFinalize, setRadioFinalize] = useState("Finalizar");
    //--Textarea
    const [textAreaObs, setTextAreaObs] = useState("");

    const history = useHistory<any>()
    const [attendenceHistoryId, setattendeinceHistoryId] = useState<any>({
      Observacao: '',
      DataHora: '',
    })

    const idPage = history.location.pathname.split('/', 3)[2]; 

    useEffect(() => {
      api.get(`atendimentos/${idPage}`).then(response => {
          setAtendimento({...response.data.result})
      })
    }, [setAtendimento])

    function handleToView(item: string) {
      setBase64(item)
      setModalPreview(!modalPreview)
    }

    function handleOnReturn(id: any) {
      setattendeinceHistoryId(atendimento.Historico?.filter((obj) => obj.AtendimentoHistoricoId === id)[0])
      setModalReturn(!modalReturn)
    }

    async function handleOnAnalise() {

      try {
        const newAttendece = atendimento
  
        if(radioFinalize === "Finalizar") {
          newAttendece.Status = "Finalizado"
          newAttendece.Historico = [
            {
              AtendimentoId: newAttendece.AtendimentoId,
              DataHora: moment().format('YYYY-MM-DD hh:mm:ss'),
              UsuarioId: newAttendece?.idUsuarioTecnico,
              StatusAtendimento: "Em Atendimento",
              Etapa: "ANÁLISE",
              Observacao: radioFinalize,
            },
            {
              AtendimentoId: newAttendece.AtendimentoId,
              DataHora: moment().format('YYYY-MM-DD hh:mm:ss'),
              UsuarioId: newAttendece?.idUsuarioTecnico,
              StatusAtendimento: "Em Atendimento",
              Etapa: "FINALIZAÇÂO",
              Observacao: textAreaObs,
          },
          ]
        }
  
        if(radioFinalize === "Retornar") {
          newAttendece.Status = "Retornado"
          newAttendece.StatusPecas = newAttendece.StatusPecas === "Solicitada" ? "Enviada" : newAttendece.StatusPecas
          newAttendece.Historico = [
            {
              AtendimentoId: newAttendece.AtendimentoId,
              DataHora: moment().format('YYYY-MM-DD hh:mm:ss'),
              UsuarioId: newAttendece?.idUsuarioTecnico,
              StatusAtendimento: "Retornado",
              Etapa: "ANÁLISE",
              Observacao: textAreaObs,
            },
          ]
        }
        
        const response = await api.put(`atendimentos/${idPage}`, newAttendece)
        
        if(response.data.error === true) {
          throw "Não foi possivel salvar o Atedimento! 😪"
        }

        setAtendimento(response.data.result);
        setTextAreaObs("");
        setModalAnalise(false);
        message.success(`${radioFinalize === "Finalizar" ? "O Atendimeto foi Finalizado!" : "O Atendimento foi Retornado ao técnico"}`);
        setRadioFinalize("Finalizar");

      } catch(error: any) {
          if(error === "Token Inválido" || error === "Token expirado.") {
            checkAccessToken("Sessão expirada! 😥");
        } else {
            message.error(error)
        }
      }
    }

    function handleOnCloseAnalise() {
      setRadioFinalize("Finalizar");
      setTextAreaObs("");
      setModalAnalise(false);
    }

    function orderArrayById(myArray: any) {
      const newHistory = myArray.Historico?.sort(
        (a: any, b: any) => a.AtendimentoHistoricoId - b.AtendimentoHistoricoId
      );

      return newHistory;
    }

    const filterHistori = orderArrayById(atendimento);

    return (
      <Container>
        <ContainerAtendimentos>
          <h1>Atendimento / Consultar Atendimento</h1>

          <TabsService>
            <ul className="nav-tab">
              <li
                className={activeTab === "tabGeral" ? "active" : ""}
                onClick={() => setActiveTab("tabGeral")}
              >
                <h2 className="tabNanAttendence" >Geral</h2>
              </li>
              <li
                className={activeTab === "tabComp" ? "active" : ""}
                onClick={() => setActiveTab("tabComp")}
              >
                <h2 className="tabNanAttendence" >Complemento</h2>
              </li>
              <li
                className={activeTab === "tabForm" ? "active" : ""}
                onClick={() => setActiveTab("tabForm")}
              >
                <h2 className="tabNanAttendence" >Formulário</h2>
              </li>
              <li
                className={activeTab === "tabAcomp" ? "active" : ""}
                onClick={() => setActiveTab("tabAcomp")}
              >
                <h2 className="tabNanAttendence" >Acompanhante</h2>
              </li>
              <li
                className={activeTab === "tabPic" ? "active" : ""}
                onClick={() => setActiveTab("tabPic")}
              >
                <h2 className="tabNanAttendence" >Fotos</h2>
              </li>
              <li
                className={activeTab === "tabHis" ? "active" : ""}
                onClick={() => setActiveTab("tabHis")}
              >
                <h2 className="tabNanAttendence" >Histórico</h2>
              </li>
            </ul>
          </TabsService>

          {activeTab !== "tabPic" && activeTab !== "tabHis" && (
            <CustomerService>
              <div className="headerStatusAttendence">
                <div className="code">
                  <span className="title">Código do Atendimento</span>
                  <span className="value">{atendimento.CodigoAtendimento}</span>
                </div>

                <div className="info">
                  <span className="title">Status</span>
                  <span className="value"> 
                      <span className={`statusBall ${atendimento.Status === 'Aberto' ? 'Aberto' : '' || atendimento.Status === 'Finalizado' ? "Finalizado" : '' || atendimento.Status === 'Em Atendimento' ? "EmAtendimento" : '' || atendimento.Status === 'Em Analise' ? "EmAnalise" : ''}`} />
                      {atendimento.Status}</span>
                </div>

                {((atendimento.Status === "Em Analise" || atendimento.MotivoAnalise === "Em Analise" || atendimento.MotivoAnalise === "Solicitação de Peças") && atendimento.Status !== "Finalizado") && (
                  <>
                    <div className="code">
                      <span className="title">Motivo</span>
                      <span className="value">{atendimento.MotivoAnalise}</span>
                    </div>
                  </>
                )}
              </div>

              { ((atendimento.Status === "Em Analise" || atendimento.MotivoAnalise === "Em Analise" || atendimento.MotivoAnalise === "Solicitação de Peças") && atendimento.Status !== "Finalizado") && (
                <>
                  <button
                    className="btn btn-featured"
                    onClick={() => setModalAnalise(!modalAnalise)}
                  >
                    Analisar
                  </button>
                </>
              )}
            </CustomerService>
          )}

          {activeTab === "tabGeral" && (
            <ListInfo>
              <CardGeral title="Dados do Atendimento">
                <FieldTextDefault
                  title="Técnico"
                  subTitle={atendimento.NomeTecnico}
                />

                <FieldTextDefault
                  title="Data e Hora de Abertura"
                  subTitle={moment(atendimento.DataHoraAbertura).utc().format('DD/MM/YYYY HH:mm') + "h"}
                />
              </CardGeral>

              <CardGeral>
                <FieldTextDefault
                  title="Tipo de Atendimento"
                  subTitle={atendimento.TipoAtendimento}
                />
              </CardGeral>

              <CardGeral title="Máquina">
                <FieldTextDefault
                  title="Número de Série"
                  subTitle={atendimento.NroSerieMaquina}
                />

                <FieldTextDefault
                  title="Marca"
                  subTitle={atendimento.FabricanteMaquina}
                />

                <FieldTextDefault
                  title="Modelo"
                  subTitle={atendimento.DescricaoModeloMaquina}
                />

                <FieldTextDefault
                  title="Código do Cliente"
                  subTitle={atendimento.CodigoCliente}
                />

                <FieldTextDefault
                  title="Loja"
                  subTitle={atendimento.CodigoLojaCliente}
                />

                <FieldTextDefault
                  title="Cliente"
                  subTitle={atendimento.NomeCliente}
                />

                <FieldTextDefault
                  title="Número do contrato"
                  subTitle={atendimento.NroContrato}
                />

                <FieldTextDefault
                  title="Tipo do Contrato"
                  subTitle={atendimento.TipoContrato}
                />
              </CardGeral>

              <CardGeral title="Endereço">
                <FieldTextDefault
                  title="Local / Endereço"
                  subTitle={atendimento.NomeLocal}
                />

                <FieldTextDefault
                  title="CEP"
                  subTitle={atendimento.AtendimentoEndereco?.CEP}
                />

                <FieldTextDefault
                  title="Logradouro"
                  subTitle={atendimento.AtendimentoEndereco?.Logradouro}
                />

                <FieldTextDefault
                  title="Número"
                  subTitle={atendimento.AtendimentoEndereco?.Numero}
                />

                <FieldTextDefault
                  title="Bairro"
                  subTitle={atendimento.AtendimentoEndereco?.Bairro}
                />

                <FieldTextDefault
                  title="Zona"
                  subTitle={atendimento.AtendimentoEndereco?.Zona}
                />

                <FieldTextDefault
                  title="UF"
                  subTitle={atendimento.AtendimentoEndereco?.UF}
                />

                <FieldTextDefault
                  title="Local / Endereço"
                  subTitle={atendimento.AtendimentoEndereco?.PontoReferencia}
                />

                <FieldTextDefault
                  title="Complemento do Local"
                  subTitle={atendimento.AtendimentoEndereco?.Complemento}
                />
              </CardGeral>

              <CardGeral title="Atendimento">
                <FieldTextDefault
                  title="Data de Início do Atendimento"
                  subTitle={moment(atendimento.DataHoraInicioAtendimento).isValid() ? moment(atendimento.DataHoraInicioAtendimento).utc().format('DD/MM/YYYY HH:mm') + "h" : ''}
                />

                <FieldTextDefault
                  title="Data de Término do Atendimento"
                  subTitle={moment(atendimento.DataHoraTerminoAtendimento).isValid() ? moment(atendimento.DataHoraTerminoAtendimento).utc().format('DD/MM/YYYY HH:mm') + "h" : ''}
                />

                <FieldTextDefault
                  title="Km Início Atendimento / Placa Veículo"
                  subTitle={atendimento.KMOdometroInicioAtendimento}
                />

                <FieldTextDefault
                  title="Km Término do Atendimento / Placa Veículo"
                  subTitle={atendimento.KMOdometroTerminoAtendimento}
                />

                <FieldTextDefault
                  title="Tempo de Atendimento"
                  subTitle={atendimento.TempoExecucao}
                />

                <FieldTextDefault
                  title="Tempo para Resolução do Problema"
                  subTitle={atendimento.TempoResolucaoProblema}
                />

                <FieldTextDefault
                  title="Distância de Deslocamento"
                  subTitle={atendimento.DistanciaKM}
                />

                <FieldTextDefault
                  title="Tempo de Deslocamento"
                  subTitle={atendimento.TempoDeslocamento}
                />
              </CardGeral>
            </ListInfo>
          )}

          {activeTab === "tabComp" && (
            <ListInfo>
                <>
                    {atendimento.TipoAtendimentoId === 1 && (
                        <CardGeral title="Dados para o Atendimento">
                            <Textarea
                                readOnly
                                label="Observação geral para o atendimento"
                                name="obsGeral"
                                onChange={() => {}}
                                value={atendimento.ObservacaoParaAtendimento}
                            />
                        </CardGeral>
                    )}

                    {atendimento.TipoAtendimentoId === 2 && (
                        <CardGeral title="Dados para o Atendimento">
                            <FieldTextDefault
                              title="Código Atendimento Anterior"
                              subTitle={atendimento.CodigoAtendimentoAnterior}
                            />

                            <Checkbox 
                              label="Retorno" 
                              placeholder="Sim" 
                              name="Retorno" 
                              value={atendimento.Retorno === '1' ? true : false} 
                              onClick={() => {}} 
                              readOnly={false}
                            />

                            <Textarea
                              readOnly
                              label="Observação geral para o atendimento"
                              name="obsGeral"
                              onChange={() => {}}
                              value={atendimento.ObservacaoParaAtendimento}
                            />
                            
                            <FieldTextDefault
                                title="Nome do Solicitante"
                                subTitle={atendimento.NomeSolicitante}
                            />
                                                        
                            <FieldTextDefault
                                title="Email do Solicitante"
                                subTitle={atendimento.EmailSolicitante}
                            />

                            <FieldTextDefault
                                title="Telefone Solicitante"
                                subTitle={atendimento.TelefoneSolicitante}
                            />

                            <FieldTextDefault
                                title="Setor do Solicitante"
                                subTitle={atendimento.SetorSolicitante}
                            />

                            <Textarea
                                readOnly
                                label="Detalhamento do Problema"
                                name="detailsProbleman"
                                onChange={() => {}}
                                value={atendimento.DetalheProblema}
                            />
                        </CardGeral>
                    )}

                    {atendimento.TipoAtendimentoId === 3 && (
                        <CardGeral title="Dados para o Atendimento">
                            <FieldTextDefault
                                title="Código Atendimento Anterior"
                                subTitle={atendimento.CodigoAtendimentoAnterior}
                            />

                            <Checkbox 
                                label="Retorno" 
                                placeholder="Sim" 
                                name="Retorno" 
                                value={atendimento.Retorno === '1' ? true : false} 
                                onClick={() => {}} 
                                readOnly={false}
                            />

                            <Textarea
                                readOnly
                                label="Observação geral para o atendimento"
                                name="obsGeral"
                                onChange={() => {}}
                                value={atendimento.ObservacaoParaAtendimento}
                            />
                            
                            <FieldTextDefault
                                title="Nome do Solicitante"
                                subTitle={atendimento.NomeSolicitante}
                            />
                                                        
                            <FieldTextDefault
                                title="Email do Solicitante"
                                subTitle={atendimento.EmailSolicitante}
                            />

                            <FieldTextDefault
                                title="Telefone Solicitante"
                                subTitle={atendimento.TelefoneSolicitante}
                            />

                            <FieldTextDefault
                                title="Setor do Solicitante"
                                subTitle={atendimento.SetorSolicitante}
                            />

                            <FieldTextDefault
                                title="Data do Agendamento"
                                subTitle={atendimento.DataAgendamento}
                            />

                            <FieldTextDefault
                                title="Hora Agendamento"
                                subTitle={atendimento.HoraAgendamento}
                            />
                        </CardGeral>
                    )}

                    {(atendimento.TipoAtendimentoId === 4 || atendimento.TipoAtendimentoId === 5) && (
                        <CardGeral title="Dados para o Atendimento">
                            <Textarea
                                readOnly
                                label="Observação"
                                name="obsGeral"
                                onChange={() => {}}
                                value={atendimento.ObservacaoParaAtendimento}
                            />

                            <FieldTextDefault
                                title="Nome do Solicitante"
                                subTitle={atendimento.NomeSolicitante}
                            />
                                                        
                            <FieldTextDefault
                                title="Email do Solicitante"
                                subTitle={atendimento.EmailSolicitante}
                            />

                            <FieldTextDefault
                                title="Telefone Solicitante"
                                subTitle={atendimento.TelefoneSolicitante}
                            />

                            <FieldTextDefault
                                title="Setor do Solicitante"
                                subTitle={atendimento.SetorSolicitante}
                            />

                            {atendimento.TipoAtendimentoId === 4 && (
                                <>
                                    <FieldTextDefault
                                        title="Rede Elétrica"
                                        subTitle={atendimento.RedeEletrica + "V"}
                                    />

                                    {/* <FieldTextDefault
                                        title="Software"
                                        subTitle={atendimento.Software}
                                    /> */}

                                    <FieldTextDefault
                                        title="Nº de Série do Estabilizador"
                                        subTitle={atendimento.NroSerieEstabilizador}
                                    />
                                </>
                            )}

                        </CardGeral>
                    )}

                    {atendimento.TipoAtendimentoId === 6 && (
                        <CardGeral title="Dados para o Atendimento">
                            <Textarea
                                readOnly
                                label="Observação geral para o atendimento"
                                name="obsGeral"
                                onChange={() => {}}
                                value={atendimento.ObservacaoParaAtendimento}
                            />

                            <FieldTextDefault
                                title="Categoria da Entrega"
                                subTitle={atendimento.CategoriaEntregaId}
                            />

                            <Checkbox 
                                label="Emergencial" 
                                placeholder="Sim" 
                                name="emergency" 
                                value={atendimento.Emergencial ? true : false} 
                                onClick={() => {}} 
                                readOnly={false}
                            />

                            <FieldTextDefault
                                title="Nome do Documento"
                                subTitle={atendimento.NomeDocumento}
                            />

                            <FieldTextDefault
                                title="Nº do Documento"
                                subTitle={atendimento.NroDocumento}
                            />
                            
                        </CardGeral>
                    )}

                    {atendimento.TipoAtendimentoId === 7 && (
                        <CardGeral title="Dados do Atendimento">
                            <Textarea
                                readOnly
                                label="Observação geral para o atendimento"
                                name="obsAmbiente"
                                onChange={() => {}}
                                value={atendimento.ObservacaoParaAtendimento}
                            />

                            <FieldTextDefault
                                title="Quem solicitou o retorno"
                                subTitle={atendimento.NomeSolicitante}
                            />

                            <Textarea
                                readOnly
                                label="Motivo"
                                name="motivo"
                                onChange={() => {}}
                                value={atendimento.Motivo}
                            />
                        </CardGeral>
                    )}
                </>
            </ListInfo>
          )}

          {activeTab === "tabForm" && (
            <ListInfo>
                <>
                    {atendimento.TipoAtendimentoId === 1 && (
                        <CardGeral title="Dados do Atendimento">
                            <FieldTextDefault
                                title="Tipo de Atendimento"
                                subTitle={atendimento.TipoAtendimento}
                            />

                            <FieldTextDefault
                                title="Contador Atual"
                                subTitle={atendimento.ContadorAtual}
                            />

                            <Textarea
                                readOnly
                                label="Comentário geral da Leitura"
                                name="obsGeral"
                                onChange={() => {}}
                                value={atendimento.ComentarioAtendimento}
                            />

                            <FieldTextDefault
                                title="Pendência?"
                                subTitle={atendimento.Pendencia === "true" ? 'Sim' : 'Não'}
                            />

                            <Textarea
                                readOnly
                                label="Descrição da Pendência"
                                name="descPendencia"
                                onChange={() => {}}
                                value={atendimento.DescricaoPendencia}
                            />
                        </CardGeral>
                    )}

                    {(atendimento.TipoAtendimentoId === 2 || atendimento.TipoAtendimentoId === 3) && (
                        <>
                            <CardGeral title="Dados do Atendimento">
                                <FieldTextDefault
                                    title="Tipo de Atendimento"
                                    subTitle={atendimento.TipoAtendimento}
                                />

                                {/* <FieldTextDefault
                                    title="Acesso liberado para atendimento remoto?"
                                    subTitle={atendimento.AcessoRemoto}
                                /> */}

                                <FieldTextDefault
                                    title="Fiação elétrica adequada ao equipamento?"
                                    subTitle={atendimento.FiacaoAdequada}
                                />

                                <FieldTextDefault
                                    title="Ambiente adequado"
                                    subTitle={atendimento.AmbienteAdequado === '1' ? 'Sim' : 'Não'}
                                />

                                <Textarea
                                    readOnly
                                    label="Observação Ambiente"
                                    name="obsAmbiente"
                                    onChange={() => {}}
                                    value={atendimento.ObservacaoAmbiente}
                                />
                            </CardGeral>

                            <CardGeral>
                                <FieldTextDefault
                                    title="Contado Inicial"
                                    subTitle={atendimento.ContadorInicial}
                                />
                                
                                <FieldTextDefault
                                    title="Contador Final"
                                    subTitle={atendimento.ContadorFinal}
                                />

                                {atendimento.TipoAtendimentoId === 3 && (
                                    <>
                                        <FieldTextDefault
                                            title="Roletes"
                                            subTitle={atendimento.Roletes === "1" ? 'Sim' : 'Não'}
                                        />

                                        <FieldTextDefault
                                            title="Cilindro de imagem / Cabeça de impressão"
                                            subTitle={atendimento.CilindroImagem}
                                        />

                                        <FieldTextDefault
                                            title="Fusão / Caixa de manutenção"
                                            subTitle={atendimento.CaixaManutencao}
                                        />

                                        <FieldTextDefault
                                            title="UR"
                                            subTitle={atendimento.UR}
                                        />

                                        <FieldTextDefault
                                            title="ADF"
                                            subTitle={atendimento.ADF}
                                        />

                                        <FieldTextDefault
                                            title="Limpeza geral da máquina"
                                            subTitle={atendimento.LimpezaMaquinas === "1" ? "Sim" : "Não"}
                                        />
                                    </>
                                )}

                                <Textarea
                                    readOnly
                                    label="Comentário do Atendimento"
                                    name="obsAmbiente"
                                    onChange={() => {}}
                                    value={atendimento.ComentarioAtendimento}
                                />
                            </CardGeral>

                            <CardGeral>
                                <ListParts
                                    dataList={atendimento?.pecas}
                                />

                                <Textarea
                                    readOnly
                                    label="Outras Peças"
                                    name="otherParts"
                                    onChange={() => {}}
                                    value={atendimento.OutrasPecas}
                                />
                            </CardGeral>

                            <CardGeral>

                                <Textarea
                                    readOnly
                                    label="Comentário após troca de Peças"
                                    name="commentParts"
                                    onChange={() => {}}
                                    value={atendimento.ComentarioTrocaPecas}
                                />

                                <FieldTextDefault
                                    title="Status do Equipamento"
                                    subTitle={atendimento.StatusEquipamento}
                                />
                                
                                <FieldTextDefault
                                    title="Pendência?"
                                    subTitle={atendimento.Pendencia === "true" ? 'Sim' : 'Não'}
                                />

                                <Textarea
                                    readOnly
                                    label="Descrição da Pendência"
                                    name="descPending"
                                    onChange={() => {}}
                                    value={atendimento.DescricaoPendencia}
                                />
                            </CardGeral>
                        </>
                    )}
                    
                    {atendimento.TipoAtendimentoId === 4 && (
                        <>
                            <CardGeral title="Dados do Atendimento">
                                <FieldTextDefault
                                    title="Tipo de Atendimento"
                                    subTitle={atendimento.TipoAtendimento}
                                />

                                <FieldTextDefault
                                    title="Fiação elétrica adequada ao equipamento?"
                                    subTitle={atendimento.FiacaoAdequada}
                                />

                                <FieldTextDefault
                                    title="Confirmação de rede Elétrica"
                                    subTitle={atendimento.RedeEletrica + "V"}
                                />

                                <FieldTextDefault
                                    title="Confirmação do número de série do estabilizador"
                                    subTitle={atendimento.NroSerieEstabilizador}
                                />

                                <FieldTextDefault
                                    title="Ambiente adequado"
                                    subTitle={atendimento.AmbienteAdequado === '1' ? 'SIM' : 'NÂO'}
                                />

                                <Textarea
                                    readOnly
                                    label="Observação Ambiente"
                                    name="obsAmbiente"
                                    onChange={() => {}}
                                    value={atendimento.ObservacaoAmbiente}
                                />
                            </CardGeral>

                            <CardGeral>
                                <FieldTextDefault
                                    title="Status Inicial do equipamento"
                                    subTitle={atendimento.StatusEquipamentoInicial}
                                />

                                <FieldTextDefault
                                    title="Contado Inicial"
                                    subTitle={atendimento.ContadorInicial}
                                />

                                <FieldTextDefault
                                    title="Contador Final"
                                    subTitle={atendimento.ContadorFinal}
                                />

                                <Textarea
                                    readOnly
                                    label="Comentário Instalação"
                                    name="commentsInstalacao"
                                    onChange={() => {}}
                                    value={atendimento.ComentarioInstalacao}
                                />
                            </CardGeral>

                            <CardGeral>
                                <FieldTextDefault
                                    title="Treinamento Cópia"
                                    subTitle={atendimento.TreinamentoCopia}
                                />

                                <FieldTextDefault
                                    title="Treinamento Scanner"
                                    subTitle={atendimento.TreinamentoScanner}
                                />

                                <FieldTextDefault
                                    title="Treinamento Impressão"
                                    subTitle={atendimento.TreinamentoImpressao}
                                />

                                <FieldTextDefault
                                    title="Treinamento troca Tonner"
                                    subTitle={atendimento.TreinamentoTrocaTonner}
                                />

                                <FieldTextDefault
                                    title="Treinamento página de Status"
                                    subTitle={atendimento.TreinamentoPaginaStatus}
                                />

                                <FieldTextDefault
                                    title="Treinamento Aplicação Mobile"
                                    subTitle={atendimento.TreinamentoAplicacaoMobile}
                                />

                                <FieldTextDefault
                                    title="Treinamento Atolamento"
                                    subTitle={atendimento.TreinamentoAtolamento}
                                />
                            </CardGeral>

                            <CardGeral>
                                <Textarea
                                    readOnly
                                    label="Comentário geral do treinamento"
                                    name="commentsGeralTreiner"
                                    onChange={() => {}}
                                    value={atendimento.ComentarioTreinamento}
                                />

                                <Textarea
                                    readOnly
                                    label="Comentário da instalação de Software"
                                    name="commentsGeralTreiner"
                                    onChange={() => {}}
                                    value={atendimento.ComentarioSoftware}
                                />

                                <FieldTextDefault
                                    title="Status do Equipamento"
                                    subTitle={atendimento.StatusEquipamento}
                                />

                                <FieldTextDefault
                                    title="Pendência?"
                                    subTitle={atendimento.Pendencia === "true" ? 'Sim' : 'Não'}
                                />

                                <Textarea
                                    readOnly
                                    label="Descrição da Pendência"
                                    name="descPending"
                                    onChange={() => {}}
                                    value={atendimento.DescricaoPendencia}
                                />
                            </CardGeral>
                        </>
                    )}

                    {atendimento.TipoAtendimentoId === 5 && (
                        <CardGeral title="Dados do Atendimento">
                            <FieldTextDefault
                                title="Tipo de Atendimento"
                                subTitle={atendimento.TipoAtendimento}
                            />

                            <FieldTextDefault
                                title="Status do Equipamento"
                                subTitle={atendimento.StatusEquipamento}
                            />

                            <Textarea
                                readOnly
                                label="Comentário Desinstalação"
                                name="obsAmbiente"
                                onChange={() => {}}
                                value={atendimento.ComentarioDesinstalacao}
                            />

                            <FieldTextDefault
                                title="Contador Final"
                                subTitle={atendimento.ContadorFinal}
                            />

                            <FieldTextDefault
                                title="Pendência?"
                                subTitle={atendimento.Pendencia === "true" ? 'Sim' : 'Não'}
                            />

                            <Textarea
                                readOnly
                                label="Descrição da Pendência"
                                name="descPending"
                                onChange={() => {}}
                                value={atendimento.DescricaoPendencia}
                            />
                        </CardGeral>
                    )}

                    {(atendimento.TipoAtendimentoId === 6 || atendimento.TipoAtendimentoId === 7) && (
                        <CardGeral title="Dados do Atendimento">
                            <FieldTextDefault
                                title="Tipo de Atendimento"
                                subTitle={atendimento.TipoAtendimento}
                            />

                            <Textarea
                                readOnly
                                label="Comentário geral da Entrega"
                                name="obsAmbiente"
                                onChange={() => {}}
                                value={atendimento.ComentarioEntrega}
                            />

                            <FieldTextDefault
                                title="Pendência?"
                                subTitle={atendimento.Pendencia === "true" ? 'Sim' : 'Não'}
                            />

                            <Textarea
                                readOnly
                                label="Descrição da Pendência"
                                name="descPending"
                                onChange={() => {}}
                                value={atendimento.DescricaoPendencia}
                            />
                        </CardGeral>
                    )}
                </>
            </ListInfo>
          )}

          {activeTab === "tabAcomp" && (
            <ListInfo>
              <CardGeral title="Dados do Cliente Acompanhante">
                <FieldTextDefault
                  title="Nome do Cliente Acompanhante"
                  subTitle={atendimento.NomeClienteAcompanhante}
                />

                <FieldTextDefault
                  title="Email do Cliente Acompanhante"
                  subTitle={atendimento.EmailClienteAcompanhante}
                  descriptionTwo={atendimento.EmailClienteAcompanhante2}
                />

                <FieldTextDefault
                  title="Telefone do Cliente Acompanhante"
                  subTitle={atendimento.TelefoneClienteAcompanhante}
                />

                <div className="sectionSignature">
                  <FieldTextDefault 
                    title='Assinatura do Cliente'
                  />
                  <div className="signatureImage">
                    {!!signatureImg && (
                      <img
                        src={`data:image/jpeg;base64,${signatureImg}`}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              
              </CardGeral>
            </ListInfo>
          )}

          {activeTab === "tabPic" && (
            <ListInfo width="100%">
              <table className="tableDefault">
                <thead>
                  <tr>
                    <th>Data / Hora</th>
                    <th>Título</th>
                    <th className="arqImage">Arquivo</th>
                  </tr>
                </thead>

                <tbody>
                  {atendimento.fotos?.map((row) => (
                    <tr key={row.AtendimentoFotoId}>
                      <td>
                        {moment(row.DataHora).utc().format("DD/MM/YYYY HH:mm")}
                      </td>
                      <td>{row.Titulo}</td>
                      <td className="arqImage">
                        <button
                          className="buttonArqImage"
                          onClick={() => handleToView(row.CaminhoFoto)}
                        >
                          <IconFile />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ListInfo>
          )}

          {activeTab === "tabHis" && (
            <ListInfo width="100%">
              <table className="tableDefaultHistory">
                <thead>
                  <tr>
                    <th>Data / Hora</th>
                    <th>Evento</th>
                    <th>Comentário</th>
                    <th>Status</th>
                    <th>Usuário</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {filterHistori?.map((row: any) => (
                    <tr key={row.AtendimentoHistoricoId}>
                      <td>
                        {moment(row.DataHora).utc().format("DD/MM/YYYY HH:mm")}
                      </td>
                      <td>{row.Etapa}</td>
                      <td>{row.Observacao}</td>
                      <td className="tableStatus">
                        <span className={`statusBallColor ${row.StatusAtendimento === 'Aberto' ? 'Aberto' : '' || row.StatusAtendimento === 'Em Atendimento' ? "EmAtendimento" : '' || row.StatusAtendimento === 'Em Analise' ? "EmAnalise" : ''}`} />
                        {row.StatusAtendimento}
                      </td>
                      <td>{atendimento.NomeTecnico}</td>
                      <td>
                        <button
                          className="buttonArqImage"
                          onClick={() => handleOnReturn(row.AtendimentoHistoricoId)}
                        >
                          <IconAdd color="#00A85A" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ListInfo>
          )}

          <div className="actions">
            <Link to="/atendimentos" className="btn btn-danger">
              Voltar
            </Link>
          </div>
        </ContainerAtendimentos>

        <ModalDefault
          id="modalDefault"
          onClose={() => setModalPreview(!modalPreview)}
          visible={modalPreview}
        >
          <SectionPreviewImage>
            <img
              className="imagePreviewOdo"
              src={`data:image/jpeg;base64,${base64}`}
              alt=""
            />

            <button
              className="buttonClosePreview"
              onClick={() => setModalPreview(!modalPreview)}
            >
              <IconRemove width="22px" height="22px" />
              Fechar modal
            </button>
          </SectionPreviewImage>
        </ModalDefault>

        <ModalDefault
          id="modalReturn"
          onClose={() => setModalReturn(!modalReturn)}
          visible={modalReturn}
        >
        <ListInfo width="700px">
          <CardGeral title="Retorno do Atendimento">
            <FieldTextDefault
              title="Data / Hora"
              subTitle={moment(attendenceHistoryId.DataHora).utc().format("DD/MM/YYYY HH:mm")}
            />

            <Textarea
              readOnly
              label="Comentário"
              name="returnComment"
              onChange={() => {}}
              value={attendenceHistoryId.Observacao ?? ''}
            />
          </CardGeral>

            <button
              className="buttonClosePreview"
              onClick={() => setModalReturn(!modalReturn)}
            >
              <IconRemove width="22px" height="22px" />
              Fechar modal
            </button>
        </ListInfo>
        </ModalDefault>

        <ModalDefault 
          id="ModalAnalise"
          onClose={handleOnCloseAnalise}
          visible={modalAnalise}
        >
          <ContainerModalAnalise>
            <div className="headerModalAnalise">
              <FieldTextDefault
                subTitle="Análise do Atendimento"
              />

              <div className="modalAnaliseInfos">
                <FieldTextDefault
                  title="Código do Atendimento"
                  subTitle={atendimento.CodigoAtendimento}
                />

                <div className="info">
                  <span className="title">Status</span>
                  <span className="value"> 
                      <span className={`statusBall ${atendimento.Status === 'Aberto' ? 'Aberto' : '' || atendimento.Status === 'Finalizado' ? "Finalizado" : '' || atendimento.Status === 'Em Atendimento' ? "EmAtendimento" : '' || atendimento.Status === 'Em Analise' ? "EmAnalise" : ''}`} />
                      {atendimento.Status}</span>
                </div>

                <FieldTextDefault
                  title="Motivo"
                  subTitle={atendimento.MotivoAnalise}
                />
              </div>
            </div>

            <div className="infoModalAnalise">
                <FieldTextDefault
                  title="Selecione"
                />
              <div className="sectionModalAnaliseRadio">
                <Radio 
                    onChange={(key) => setRadioFinalize(key)}
                    value={radioFinalize === "Finalizar" ? true : false}
                    name="Finalizar"
                    placeholder="Finalizar"
                />

                  <Radio 
                    onChange={(key) => setRadioFinalize(key)}
                    value={radioFinalize === "Retornar" ? true : false}
                    name="Retornar"
                    placeholder="Retornar"
                    isDisabled={atendimento.MotivoAnalise === "Solicitação de Peças" ? false : true}
                    readOnly={atendimento.MotivoAnalise === "Solicitação de Peças" ? false : true}
                  />

              </div>

              <TextareaDefault 
                label="Observação"
                placeholder="Observação..."
                value={textAreaObs}
                onChange={(event) => setTextAreaObs(event.target.value)}
              />

              <div className="sectionModalAnaliseFooter">
                <button 
                  type="button"
                  className="btn btn-primary"
                  onClick={handleOnAnalise}
                >
                  Sim
                </button>
                <button 
                  type="button"
                  className="btn btn-danger"
                  onClick={handleOnCloseAnalise}
                >
                  Não
                </button>
              </div>
            </div>

          </ContainerModalAnalise>
        </ModalDefault>

      </Container>
    );
}

export default Consultar2