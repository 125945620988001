import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderPonto = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1.5rem;

  .titlePonto {
    font-size: 1.5rem;
    color: #413F3F;
  }
`;

export const SectionFilterPonto = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  align-items: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1.5rem;
`;

export const SectionTablePonto = styled.div`
  display: flex;
`;

export const ContainerPonto = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 900px;

  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding-right: 6px;

  ::-webkit-scrollbar {
    display: none;
  }

  .buttonClosePreview {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      position: fixed;
      bottom: -40px;
      left: 40%;

      padding: 4px 12px;
      gap: 10px;

      line-height: 1rem;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      background-color: transparent;
      border: none;

      transition: all 0.35s;
      &:hover {
          transform: scale(1.1);
      }
    }

`;

export const SectionDetailsPonto = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #FFFF;
  border-radius: 5px;
  padding: 2rem 2.2rem;

  > h1 {
    font-size: 1.125rem;
    color: var(--title);
    border: none;
  }

  .fieldGroupPonto {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    .fieldPonto {
      display: flex;
      flex-direction: column;
      gap: 4px;

      >h3 {
        font-size: 14px;
        font-weight: 500;
        color: var(--font);
      }

      >h4 {
        font-size: 1rem;
        font-weight: 600;
        color: var(--primary);
      }
    }
  }
`;

export const SectionRegisterPonto = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #FFFF;
  border-radius: 5px;
  padding: 2rem 2.2rem;

  .headerModalPonto {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 20px;

    > h2 {
      margin-bottom: 4px;
    }
  }

  .dataPonto {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;

    .sectionImagePonto {

      > h3 {
        font-size: 14px;
        font-weight: 500;
        color: var(--font);
        margin-bottom: 12px;
      }

      display: flex;
      flex-direction: column;
      border-radius: 8px;
      object-fit: cover;
      width: 100%;
      height: 100%;

      img {
        border-radius: 8px;
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }

    .sectionInfoPonto {

      .fieldGroupPontoHeader {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
        gap: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid #DBDBDB;
        padding-bottom: 1.5rem;

        .fieldPonto {
          >h3 {
            font-size: 14px;
            font-weight: 500;
            color: var(--font);
          }

          >h4 {
            font-size: 1rem;
            font-weight: 600;
            color: var(--title);
          }
        }
      }

      .fieldGroupPonto {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
        gap: 1.5rem;
        margin-bottom: 1.5rem;

        .fieldPonto {
          >h3 {
            font-size: 14px;
            font-weight: 500;
            color: var(--font);
          }

          >h4 {
            font-size: 1rem;
            font-weight: 600;
            color: var(--title);
          }
        }
      }
    }
  }
`;

